import { TNavMenu } from ".";
import { GiCardboardBoxClosed } from "react-icons/gi";

const products: TNavMenu = {
  id: "products",
  title: "Products",
  type: "collapse",
  url: "",
  icon: GiCardboardBoxClosed,
  children: [
    {
      id: "newListing",
      title: "New Listing",
      type: "item",
      url: "newListProducts",
      icon: null,
      children: [],
    },
    {
      id: "activeProducts",
      title: "Active Products",
      type: "item",
      url: "activeProducts",
      icon: null,
      children: [],
    },
    {
      id: "inactiveProducts",
      title: "Inactive Products",
      type: "item",
      url: "inactiveProducts",
      icon: null,
      children: [],
    },
  ],
};

export default products;
