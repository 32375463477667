import {useRoutes} from "react-router-dom";

// routes
import DashboardRoutes from "./DashboardRoutes";
import BdaRoutes from "./BdaRoutes";

const PortalRoutes = () => {
    return useRoutes([DashboardRoutes, BdaRoutes]);
};

export default PortalRoutes;
