import { Button, ButtonProps } from "@mantine/core";
import { IconType } from "react-icons";

type TProps = ButtonProps & {
  title: string;
  Icon: IconType;
  handleClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
};

const ActionButton = ({ title, Icon, handleClick, ...props }: TProps) => {
  return (
    <Button
      sx={{
        boxShadow: "0 10px 6px -6px rgba(0, 0, 0, 0.1)",
      }}
      radius="md"
      px="md"
      leftIcon={<Icon size={20} />}
      variant="subtle"
      onClick={handleClick}
      {...props}
    >
      {title}
    </Button>
  );
};

export default ActionButton;
