import dayjs from "dayjs";
import JsBarcode from "jsbarcode";

export const generateRandomString = (length: number = 15): string => {
  const alphabetDictionary =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  return new Array(length)
    .fill(0)
    .map(() =>
      alphabetDictionary.charAt(
        Math.floor(Math.random() * alphabetDictionary.length)
      )
    )
    .join("");
};

export const isOracleError = (err: string): boolean =>
  err.substring(0, 3) === "ORA";

export const isDateValue = (value: any): boolean => {
  if (Number(value) === 0 || Number(value)) return false;
  return dayjs(value).isValid();
};

export const getBarcodeImageElement = ({
  barcode,
  options,
}: {
  barcode: string;
  options?: JsBarcode.Options;
}) => {
  const img = document.createElement("img");
  JsBarcode(img, barcode, options);
  return img;
};

export const formatNumber = (num: number): string => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(3).replace(rx, "$1") + item.symbol
    : "0";
};
