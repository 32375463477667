import { useQuery } from "@tanstack/react-query";
import { getPurchaseOrderDivision } from "../queries/orders";
import { TPurchaseOrderDivision } from "../types/orders";

export const useGetAllDivisions = () => {
  return useQuery<TPurchaseOrderDivision[], Error>(
    ["orders", "purchaseOrderDivision"],
    () => getPurchaseOrderDivision(null),
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours,
      onError: (error) => {
        console.log(error);
      },
    }
  );
};
