import React, {useEffect,useState} from 'react';
import {
    Container,
    LoadingOverlay,
    Title,
} from "@mantine/core";
import {closeAllModals, openModal} from "@mantine/modals";
import {api} from "../../api";
import SimpleCustomAlert from "../../components/SimpleCustomAlert";
import BdaForm from "./BdaForm";
import dayjs from "dayjs";
import useAuthStore from "../../store/useAuthStore";
import { useQuery } from "@tanstack/react-query";
// interface RecentOrdersTableProps {
//     className?: string;
//     cryptoOrders: CryptoOrder[];
// }

import { getBdaDetails } from "../../queries/bda";
// import {getPurchaseOrderItemDetail} from "../../queries/orders";
// import {TPurchaseOrderItemDetail} from "../../types/orders";

const Bda = () => {
    const [isLoading, setIsLoading] = useState(false);
    const authStore = useAuthStore();
    // const dispatch = useDispatch();
  //  const {bdaState} = useSelector((state: any) => state.bdaState);
    const [bdaData, setBdaData] = useState([]);
    const [bdaTermsData, setBdaTermsData] = useState([]);
    const [bdaRebateData, setBdaRebateData] = useState([]);
    const [bdaRebateSlabData, setBdaRebateSlabData] = useState([]);

    const { refetch} = useQuery(
        ["bdaDetails"],
        () =>getBdaDetails(authStore.user?.SUPP_CODE),
        {
            enabled: false,
            onSuccess(data) {
                console.log(data)
                setBdaData(data.bda);
                setBdaTermsData(data.bdaRebateTermsDetails);
                setBdaRebateData(data.bdaRebateDetails);
                setBdaRebateSlabData(data.bdaRebateSlabDetails);
            },
            onError(err) {
                console.log("error : ", err);
            },
        }
    );

    useEffect(() => {
        if (bdaData) refetch();
    }, [bdaData,refetch]);

  //  console.log(bdaDetails)

    // const getBda = async () => {
    //     try {
    //         const payload = { P_Supp_Code: authStore.user?.SUPP_CODE };
    //         const response = await api.post("bda/details", undefined, payload);
    //         if (response.status) {
    //             setBdaData(response.data);
    //         }
    //     } catch (error: any) {
    //         // console.log(error);
    //     }
    // };
    //
    //
    //
    //
    // useEffect(() => {
    //
    //
    //     getBda()
    //
    // });

  // console.log(bdaDetails)

    const handleFormSubmit = async (
        values: any,
    ) => {
        try {
            setIsLoading(true);
            let formValues = {...values};

            formValues.year = dayjs(new Date()).format("YY");
            formValues.date_from = dayjs(formValues.date_from).format("DD-MMM-YY");
            formValues.date_to = dayjs(formValues.date_to).format("DD-MMM-YY");
            formValues.supp_code = authStore.user?.SUPP_CODE
            formValues.created_user = authStore.user?.SUPP_NAME


          //  formValues.progressive_rebate = formValues.progressive_rebate.concat( formValues.delete_progressive_rebate);


          // console.log(formValues.progressive_rebate)

            if (formValues.cost_of_good_perc > 0 && formValues.cost_goods=='PRP') {

                formValues.cost_goods = formValues.cost_of_good_perc + '% PRP'
            }

          //  console.log(formValues)

            const formData = new FormData();
            formData.append("formValues", JSON.stringify(formValues));

            // console.log(formData)

            const response = await api.post("bda/insert", undefined, formData);

            if (response.success) {
                openModal({
                    // title: "Success",
                    centered: true,
                    children: (
                        <SimpleCustomAlert
                            type="success"
                            title="Insert Completed."
                            handleClose={() => closeAllModals()}
                        />
                    ),
                });
             //   dispatch(setBdaState("true"));

            } else throw new Error(response.message);
            setIsLoading(false);
        } catch (error: any) {
            openModal({
                // title: "Failed",
                centered: true,
                children: (
                    <SimpleCustomAlert
                        type="error"
                        title={error.message}
                        handleClose={() => closeAllModals()}
                    />
                ),
            });
            console.log(error);
            setIsLoading(false);
        }
    };


    return (
        <>
            <LoadingOverlay
                visible={isLoading}
                loaderProps={{size: "xl", variant: "bars"}}
            />
            {/*{*/}
            {/*if(bdaState)*/}

            {/*<Container size={1000} px={0}>*/}
            {/*<Title*/}
            {/*order={1}*/}
            {/*mt="60px"*/}
            {/*sx={(theme) => ({textAlign: 'center'})}*/}
            {/*>*/}
            {/*BDA Setup*/}
            {/*</Title>*/}

            {/*<BdaForm*/}
            {/*handleFormSubmit={handleFormSubmit}*/}

            {/*/>*/}
            {/*</Container>*/}
            {/*: <BdaReview/>}*/}

            {(() => {
                // if (bdaState === "true") {
                //     console.log(bdaState)
                //     console.log(55555555555)
                //     return (
                //         <BdaReview/>
                //
                //     )
                //     // } else if (otherCase) {
                //     //     return (
                //     //         <div>otherCase</div>
                //     //     )
                // } else {
                 //    console.log(bdaState)
                 //    console.log(6565)
                 // //  console.log(bdaDatabda)
                    return (
                        <Container size={1000} px={0}>
                            <Title
                                order={1}
                                mt="60px"
                                sx={(theme) => ({textAlign: 'center'})}
                            >
                                BDA Setup
                            </Title>

                            <BdaForm  bdaDetails={bdaData} bdaTermsData={bdaTermsData}
                                      bdaRebateData={bdaRebateData} bdaRebateSlabData={bdaRebateSlabData}
                                      handleFormSubmit={handleFormSubmit}
                            />
                        </Container>
                    )
                //}
            })()}
        </>
    );
};

export default Bda;
