import { useState } from "react";
import { Box, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { closeAllModals, openModal } from "@mantine/modals";
import { RiFileExcel2Fill } from "react-icons/ri";
import FilesDropZone from "../../../components/FilesDropZone";
import SimpleCustomAlert from "../../../components/SimpleCustomAlert";
import ActionButton from "../../../components/ActionButton";

const NewProductsRequest = () => {
  const [productsReqFile, setProductsReqFile] = useState<File | null>(null);
  console.log(productsReqFile)
  return (
    <>
      <Group position="apart" my="xl">
        <Title order={1}>New products request</Title>
        <ActionButton
          title="Download form"
          Icon={RiFileExcel2Fill}
          handleClick={() => {}}
        />
      </Group>
      <Group spacing="xl" pb="xl" position="apart" align="start">
        <Box sx={{ width: "35rem" }}>
          <FilesDropZone
            loading={false}
            multiple={false}
            onDrop={(files) => setProductsReqFile(files[0])}
            onReject={(files) =>
              openModal({
                centered: true,
                children: (
                  <SimpleCustomAlert
                    type="error"
                    title="Upload failed"
                    message={files[0].errors[0].message}
                    handleClose={() => closeAllModals()}
                  />
                ),
              })
            }
          />
        </Box>
        <Paper withBorder radius="md" p="md" sx={{ maxWidth: "21.5rem" }}>
          <Stack spacing="sm">
            <Text size="lg" weight={600}>
              FAQs
            </Text>
            <Text size="sm">How do I update stocks for my products?</Text>
            <Text size="sm">How do I know the status of my stock updates?</Text>
            <Text size="sm">
              How do I correct the file if the stock updates fails?
            </Text>
            <Text size="sm">How do I view and manage my entire catalogue?</Text>
          </Stack>
        </Paper>
      </Group>
    </>
  );
};

export default NewProductsRequest;
