import { Avatar,  Group, Paper, Stack, Text } from "@mantine/core";
import FFCLogo from "../../assets/images/logos/ffc-short-logo.png";
import {
  FaPhoneAlt,
  FaEnvelope,
  FaLinkedin,
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <Group
      position="apart"
      sx={(theme) => ({
        width: "100%",
        backgroundColor: theme.colors.ffcBrand[6],
        color: theme.other.textLight,
        padding: "3rem 4rem",
        "@media (min-width: 1400px)": {
          padding: "4rem 6rem",
        },
      })}
    >
      <Stack align="flex-start">
        <Paper p="sm" sx={{ borderRadius: "50%" }}>
          <Avatar radius="xl" size="lg" src={FFCLogo} />
        </Paper>

        <Text size="lg">
          Copyright &#169; {new Date().getFullYear()} Family Food Centre
        </Text>
      </Stack>
      <Stack>
        <Group spacing="sm">
          <FaPhoneAlt size={25} />
          <Text size="lg">41 41 34 56</Text>
        </Group>
        <Group spacing="sm">
          <FaEnvelope size={25} />
          <Text size="lg">hello@family.qa</Text>
        </Group>
        <Group>
          <FaLinkedin size={25} />
          <FaFacebookSquare size={25} />
          <FaInstagram size={25} />
          <FaTwitterSquare size={25} />
        </Group>
      </Stack>
      <Stack>
        <Text size="lg">About the company</Text>
        <Text color="dimmed" size="sm" sx={{ maxWidth: "23rem" }}>
          Qatar's oldest and most favorite supermarket | Since 1978 Al Nasr | Al
          Kheesa | Old Airport Road | Al Rayyan | Family Mart Serving the realm
          of #Qatar since 1978.
        </Text>
      </Stack>
    </Group>
  );
};

export default Footer;
