import { Box } from "@mantine/core";
import { Outlet } from "react-router-dom";
import Footer from "../../components/Footer";
import TopNavbar from "../../components/NavigationBar/TopNavbar";

const RegisterLayout = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        position: "relative",
      }}
    >
      <TopNavbar />
      <Outlet />
      <Footer />
    </Box>
  );
};
export default RegisterLayout;
