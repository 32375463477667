import { api } from "../api";
import { TAccountType, TBank, TCompanyType, TCurrency, TOption } from "../types/register";

type TResponse<T> = {
  status:boolean;
  data:T
}

export const getCompanyTypes = async () => {
  const response: TResponse<TCompanyType[]> = await api.get("supplier/companyType");
  const options = response.data.reduce((acc, compType) => [...acc, { label: compType.COMTYPE_DESC, value: compType.COMTYPE_CODE }], [] as TOption[])
  return options;
};

export const getBanks = async () => {
  const response: TResponse<TBank[]> = await api.get("supplier/banks");
  const options = response.data.reduce((acc, bank) => [...acc, { label: bank.BANK_NAME, value: bank.BANK_CODE }], [] as TOption[])
  return options;
};

export const getAccountTypes = async () => {
  const response: TResponse<TAccountType[]> = await api.get("supplier/accountType");
  const options = response.data.reduce((acc, accType) => [...acc, { label: accType.BATYPE_NAME, value: accType.BATYPE_CODE }], [] as TOption[])
  return options;
};

export const getCurrency = async () => {
  const response: TResponse<TCurrency[]> = await api.get("supplier/currency");
  const options = response.data.reduce((acc, currency) => [...acc, { label: currency.CURR_UNIT_NAME, value: currency.CURR_CODE }], [] as TOption[])
  return options;
};