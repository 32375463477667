import { IconType } from "react-icons/lib";
import home from "./home";
import notifications from "./notifications";
import orders from "./orders";
import products from "./products";
import returns from "./returns";
import bda from "./bda";

export type TNavMenu = {
  id: string;
  title: string;
  type: "collapse" | "item";
  url: string;
  icon: IconType | null;
  children: TNavMenu[];
};

const NavMenuItems = (menuType: "main" | "footer") => {
  let mainNavMenuItems: TNavMenu[] = [
    home,
    products,
    orders,
    returns,
    notifications,
    bda,
  ];
  let footerNavMenuItems: TNavMenu[] = [];
  return menuType === "main"
    ? { items: mainNavMenuItems }
    : { items: footerNavMenuItems };
};

export default NavMenuItems;
