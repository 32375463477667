import { Group, Menu, Title } from "@mantine/core";
import { useState } from "react";
import ActionMenu from "../../components/ActionMenu";
import ReturnsTable from "./ReturnsTable";

const periodOptions = Array.from({ length: 10 }, (_, i) => 6 * (i + 1));

const Returns = () => {
  const [timePeriod, setTimePeriod] = useState(6);
  return (
    <>
      <Group position="apart" my="xl">
        <Title order={1}>Returns</Title>
        <ActionMenu menuLabel={`Last ${timePeriod} months`}>
          {periodOptions.map((period) => (
            <Menu.Item key={period} onClick={() => setTimePeriod(period)}>
              Last {period} months
            </Menu.Item>
          ))}
        </ActionMenu>
      </Group>
      <ReturnsTable timePeriod={timePeriod} />
    </>
  );
};

export default Returns;
