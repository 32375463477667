import { Button, ButtonProps, Menu } from "@mantine/core";

type TProps = ButtonProps & {
  menuLabel: string;
};

const ActionMenu = (props: TProps) => {
  const { children, menuLabel, ...others } = props;
  return (
    <Menu width={200} shadow="md" zIndex={500}>
      <Menu.Target>
        <Button
          sx={{
            boxShadow: "0 10px 6px -6px rgba(0, 0, 0, 0.1)",
          }}
          radius="md"
          px="md"
          variant="subtle"
          {...others}
        >
          {menuLabel}
        </Button>
      </Menu.Target>

      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  );
};

export default ActionMenu;
