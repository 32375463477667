import { Container, Group, Image, Title } from "@mantine/core";

const PopularBrands = () => {
  return (
    <Container fluid>
      <Title order={2} size="h1" my={50} align="center">
        Popular Brands
      </Title>
      <Group position="center" spacing={50} mb={60}>
        {[1, 2, 3, 4, 5].map((i) => (
          <Image
            key={i}
            width={200}
            height={200}
            src={`https://source.unsplash.com/random/20${i}x20${i}?fruit`}
            alt="Random unsplash image"
          />
        ))}
      </Group>
    </Container>
  );
};

export default PopularBrands;
