import {
  ActionIcon,
  Box,
  Divider,
  Group,
  Indicator,
  Text,
  Title,
} from "@mantine/core";
import { RiMore2Fill } from "react-icons/ri";

const Notifications = () => {
  return (
    <>
      <Group position="apart" my="xl">
        <Title order={1}>Notifications</Title>
      </Group>
      <Box>
        {[...Array(5)].map((_, idx) => (
          <Box key={idx}>
            <Indicator
              color={idx > 2 ? "gray" : undefined}
              ml="sm"
              position="middle-start"
            >
              <Group
                position="apart"
                py="lg"
                px="xl"
                sx={(theme) => ({
                  color: idx > 2 ? theme.colors.gray[5] : undefined,
                })}
              >
                <Box>
                  <Text size="xl">Lpo#2754416564 is created on 03-10-2022</Text>
                  <Text italic size="xs">
                    FFC Al Nasar
                  </Text>
                </Box>
                <ActionIcon>
                  <RiMore2Fill size={20} />
                </ActionIcon>
              </Group>
            </Indicator>
            <Divider />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default Notifications;
