import {
    Burger,
    Button,
    Group,
    Paper,
    Stack,
    Text,
    Transition,
} from "@mantine/core";
import {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import FFCLogo from "../../../assets/images/logos/Family-logo.png";
import useAuthStore from "../../../store/useAuthStore";
import {useQueryClient} from "@tanstack/react-query";

const TopNavbarBda = () => {
    const location = useLocation();
    const authStore = useAuthStore();
    const [isOpen, setOpen] = useState(false);
    const queryClient = useQueryClient();

    const handleLogout = () => {
        queryClient.removeQueries();
        authStore.logoutUser();
    };
    return (
        <Group
            px="2rem"
            position="apart"
            sx={(theme) => ({
                minHeight: "10vh",
                width: "100%",
                position: "relative",
                zIndex: 1,
                background: theme.colors.ffcBrand[6]
            })}
        >
            {/*<Paper withBorder shadow="md" p="sm" >*/}
            {/*<Avatar radius="xl" size="lg" src={FFCLogo} />*/}
            <a href="/" rel="noreferrer" style={{marginLeft: "10px"}}>
                <img
                    src={FFCLogo}
                    alt="FFC"
                    width="35%"
                    // style={{boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"}}
                />
            </a>
            {/*</Paper>*/}
            <Burger
                opened={isOpen}
                onClick={() => setOpen((prev) => !prev)}
                sx={(theme) => ({
                    [theme.fn.largerThan("sm")]: {
                        display: "none",
                    },
                })}
                size="sm"
            />

            <Group
                spacing="xs"
                sx={(theme) => ({
                    [theme.fn.smallerThan("sm")]: {
                        display: "none",
                    },
                })}
            >
                <Text color="#fff">Already a supplier?</Text>
                {!authStore.isAuth ? (
                    <Button component={Link} to="/login" radius="md" size="md">
                        Login
                    </Button>
                ) : (
                    <Button onClick={handleLogout} radius="md" size="md">
                        Logout
                    </Button>
                )}
            </Group>
            <Transition transition="pop-top-right" duration={200} mounted={isOpen}>
                {(styles) => (
                    <Paper
                        sx={(theme) => ({
                            position: "absolute",
                            top: "10vh",
                            left: 0,
                            right: 0,
                            zIndex: 0,
                            borderTopRightRadius: 0,
                            borderTopLeftRadius: 0,
                            borderTopWidth: 0,
                            overflow: "hidden",
                            padding: "1rem",

                            [theme.fn.largerThan("sm")]: {
                                display: "none",
                            },
                        })}
                        withBorder
                        style={styles}
                    >
                        <Stack>
                            <Button
                                fullWidth
                                component={Link}
                                to="/"
                                variant="subtle"
                                radius="md"
                                size="xl"
                                compact
                                onClick={() => setOpen(false)}
                                sx={{...(location.pathname !== "/" && {color: "#979797"})}}
                            >
                                Home
                            </Button>
                            <Button
                                fullWidth
                                component={Link}
                                to="/register"
                                variant="subtle"
                                radius="md"
                                size="xl"
                                compact
                                onClick={() => setOpen(false)}
                                sx={{
                                    ...(location.pathname !== "/register" && {
                                        color: "#979797",
                                    }),
                                }}
                            >
                                Register
                            </Button>
                            {!authStore.isAuth ? (
                                <Button
                                    fullWidth
                                    component={Link}
                                    to="/login"
                                    radius="md"
                                    size="md"

                                >
                                    Login
                                </Button>
                            ) : (
                                <Button
                                    fullWidth
                                    component={Link}
                                    to="/dashboard"
                                    radius="md"
                                    size="md"
                                >
                                    Portal
                                </Button>
                            )}
                        </Stack>
                    </Paper>
                )}
            </Transition>
        </Group>
    );
};

export default TopNavbarBda;
