import jwt_decode from "jwt-decode";

export const decodeToken = (token: string | null) => {
  try {
    if (token) {
      const decode = jwt_decode(token);
      if (decode) {
        return decode;
      } else {
        throw new Error("token decode error");
      }
    } else {
      throw new Error("invalid token");
    }
  } catch (error) {
    // console.log(error);
    return null;
  }
};
