import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  Paper,
  Pagination,
  Title,
  LoadingOverlay,
} from "@mantine/core";
import {
  RiArrowUpDownLine,
  RiArrowUpLine,
  RiArrowDownLine,
} from "react-icons/ri";
import { useQuery } from "@tanstack/react-query";
import { TThProps } from "../../types/common";
import { TReturnDocumentItem } from "../../types/returns";
import { getReturnDocItemDetail } from "../../queries/returns";
import DataSummary from "../../components/DataSummary";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },

  icon: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },
}));

function Th({ children, reversed, sorted, onSort }: TThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? RiArrowUpLine
      : RiArrowDownLine
    : RiArrowUpDownLine;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group spacing="xs">
          <Text weight={500} size="md">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: TReturnDocumentItem[], search: string) {
  if (search === "") return data;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.keys(data[0]).some((key) =>
      (item[key as keyof TReturnDocumentItem] + "")
        .toLowerCase()
        .includes(query)
    )
  );
}

function sortData(
  data: TReturnDocumentItem[],
  payload: {
    sortBy: keyof TReturnDocumentItem | null;
    reversed: boolean;
    search: string;
  }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[sortBy] + "").localeCompare(a[sortBy] + "", "en", {
          numeric: true,
        });
      }

      return (a[sortBy] + "").localeCompare(b[sortBy] + "", "en", {
        numeric: true,
      });
    }),
    payload.search
  );
}

const ReturnDocItemTable = () => {
  const [search] = useState("");
  const [sortedData, setSortedData] = useState<TReturnDocumentItem[]>([]);
  const [sortBy, setSortBy] = useState<keyof TReturnDocumentItem | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const { docId, division } = useParams();
  // const queryClient = useQueryClient();

  const { isLoading: isLoadingReturnDocItemDetail, data: returnDocItemDetail } =
    useQuery<TReturnDocumentItem[] | null, Error>(
      ["returns", "returnDocItemDetail", docId, division],
      () => getReturnDocItemDetail({ docId, division }),
      {
        onError: (error) => {
          console.log(error);
        },
      }
    );

  useEffect(() => {
    if (returnDocItemDetail) {
      setSortedData(
        sortData(returnDocItemDetail, {
          sortBy,
          reversed: reverseSortDirection,
          search,
        })
      );
    }
  }, [returnDocItemDetail, reverseSortDirection, search, sortBy]);

  const setSorting = (field: keyof TReturnDocumentItem) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(
      sortData(returnDocItemDetail ?? [], {
        sortBy: field,
        reversed,
        search,
      })
    );
  };

  const totalPages = Math.ceil(
    (returnDocItemDetail?.length ?? 0) / rowsPerPage
  );

  const rows = sortedData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row) => (
      <tr key={`${row.ITEM_CODE}-${row.BARCODE}`}>
        <td>
          <Text size="md">{row.BARCODE}</Text>
        </td>
        <td style={{ maxWidth: "22rem" }}>
          <Title order={5}>{row.ITEM_NAME}</Title>
          <Text color="dimmed">Item Code : {row.ITEM_CODE}</Text>
          <Text color="dimmed">Qty : {row.QTY}</Text>
        </td>
        <td>
          <Text size="md">{row.UOM}</Text>
        </td>
        <td>
          <Text size="md">{row.LOOSE}</Text>
        </td>
        <td>
          <Text size="md">{row.RATE.toFixed(2)}</Text>
        </td>
        <td>
          <Text size="md">{row.AMOUNT.toFixed(2)}</Text>
        </td>
      </tr>
    ));

  return (
    <>
      <Group position="apart" mb="md">
        <DataSummary
          summaryFields={[
            { label: "All", badgeText: returnDocItemDetail?.length ?? 0 },
          ]}
        />
      </Group>
      <Paper
        withBorder
        radius="md"
        my="sm"
        sx={{ minWidth: 700, position: "relative" }}
      >
        <LoadingOverlay visible={isLoadingReturnDocItemDetail} />
        <ScrollArea>
          <Paper sx={{ height: "38.8rem", width: "100%" }}>
            <Table
              horizontalSpacing="md"
              verticalSpacing="xs"
              striped
              sx={{ tableLayout: "auto" }}
            >
              <thead>
                <tr>
                  <Th
                    sorted={sortBy === "BARCODE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("BARCODE")}
                  >
                    Barcode
                  </Th>
                  <Th
                    sorted={sortBy === "ITEM_NAME"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("ITEM_NAME")}
                  >
                    Product(s)
                  </Th>
                  <Th
                    sorted={sortBy === "UOM"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("UOM")}
                  >
                    UOM
                  </Th>
                  <Th
                    sorted={sortBy === "LOOSE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("LOOSE")}
                  >
                    Loose
                  </Th>
                  <Th
                    sorted={sortBy === "RATE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("RATE")}
                  >
                    Rate
                  </Th>
                  <Th
                    sorted={sortBy === "AMOUNT"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("AMOUNT")}
                  >
                    Amount
                  </Th>
                </tr>
              </thead>
              <tbody>
                {rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td colSpan={11}>
                      <Text weight={500} align="center" color="red">
                        Nothing found
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Paper>
        </ScrollArea>
      </Paper>
      <Pagination
        disabled={!returnDocItemDetail?.length}
        position="right"
        page={page + 1}
        onChange={(page: number) => setPage(page - 1)}
        withEdges
        total={totalPages}
      />
    </>
  );
};

export default ReturnDocItemTable;
