import {
  UnstyledButton,
  Group,
  Avatar,
  Text,
  Box,
  useMantineTheme,
} from "@mantine/core";
import { Link, useLocation } from "react-router-dom";

type TProps = {
  userName: string | undefined;
  userCode: string | undefined;
};

const UserHeader = (props: TProps) => {
  const { userName, userCode } = props;
  const theme = useMantineTheme();
  const location = useLocation();

  return (
    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        marginBottom: theme.spacing.md,
      }}
    >
      <UnstyledButton
        sx={{
          display: "block",
          width: "100%",
          padding: theme.spacing.xs,
          borderRadius: theme.radius.xl,

          "&:hover": {
            backgroundColor: theme.colors[theme.primaryColor][5],
          },
          ...(location.pathname === "/dashboard/companyDetails" && {
            backgroundColor: theme.colors[theme.primaryColor][5],
          }),
        }}
        component={Link}
        to="/dashboard/companyDetails"
      >
        <Group>
          <Avatar size="lg" src="" radius="xl">
            {userName?.substring(0, 1)}
          </Avatar>
          <Box sx={{ flex: 1 }}>
            <Text size="md" weight={500} color={theme.colors.gray[0]}>
              {userName ?? ""}
            </Text>
            <Text color="dimmed" size="xs">
              {userCode ?? ""}
              {/* registered on 10-Jun-2020 */}
            </Text>
          </Box>
        </Group>
      </UnstyledButton>
    </Box>
  );
};

export default UserHeader;
