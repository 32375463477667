import { useEffect, useState } from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  Paper,
  Pagination,
  Badge,
  Title,
  Select,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import {
  RiArrowUpDownLine,
  RiArrowUpLine,
  RiArrowDownLine,
} from "react-icons/ri";
import dayjs from "dayjs";
import { TThProps } from "../../../types/common";
import { TProduct, TProductsData } from "../../../types/products";
import { useQuery } from "@tanstack/react-query";
import { getProductsData } from "../../../queries/products";
import DataSummary, { TSummaryFields } from "../../../components/DataSummary";
import { useGetAllDivisions } from "../../../hooks/useQueryHooks";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.md}px ${theme.spacing.lg}px`,

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },

  icon: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },
}));

function Th({ children, reversed, sorted, onSort }: TThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? RiArrowUpLine
      : RiArrowDownLine
    : RiArrowUpDownLine;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group spacing="xs">
          <Text weight={500} size="md">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: TProduct[], search: string) {
  if (search === "") return data;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.keys(data[0]).some((key) =>
      (item[key as keyof TProduct] + "").toLowerCase().includes(query)
    )
  );
}

function sortData(
  data: TProduct[],
  payload: { sortBy: keyof TProduct | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[sortBy] + "").localeCompare(a[sortBy] + "", "en", {
          numeric: true,
        });
      }

      return (a[sortBy] + "").localeCompare(b[sortBy] + "", "en", {
        numeric: true,
      });
    }),
    payload.search
  );
}

// // DUMMY DATA
// const data: TRowData[] = [...Array(15)].map((_, idx) => ({
//   created: new Date((new Date() as any) - Math.random() * 1e12).toJSON(),
//   orderDetail: `orderDetail ${Math.floor(Math.random() * 100)}`,
//   product: `product ${Math.floor(Math.random() * 100)}`,
//   image: `https://source.unsplash.com/random/20${idx}x20${idx}?fruit`,
//   status: "active",
//   unitPrice: `unitPrice ${Math.floor(Math.random() * 100)}`,
//   uom: `uom ${Math.floor(Math.random() * 100)}`,
//   stock: `stock ${Math.floor(Math.random() * 100)}`,
// }));
// //

const ProductsTable = ({
  productStatus,
}: {
  productStatus: "ACT" | "INACT" | "ALL";
}) => {
  const [selectedDivision, setSelectedDivision] = useState<string | null>(null);
  const [pODivOptions, setPODivOptions] = useState<
    { label: string; value: string }[]
  >([]);
  // const [search, setSearch] = useState("");
  const [search] = useState("");
  const [sortedData, setSortedData] = useState<TProduct[]>([]);
  const [sortBy, setSortBy] = useState<keyof TProduct | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const { isLoading: isLoadingPODivision, data: purchaseOrderDivision } =
    useGetAllDivisions();

  const { isLoading: isLoadingProductsData, data: productsData } = useQuery<
    TProductsData | null,
    Error
  >(
    ["products", "productsData", productStatus, selectedDivision],
    () => getProductsData({ flag: productStatus, selectedDivision }),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    if (purchaseOrderDivision && !selectedDivision) {
      const divisionOptions = purchaseOrderDivision.map((division) => ({
        label: division.DIVN_NAME,
        value: `${division.DIVN_CODE}_${division.DIVN_COMP_CODE}`,
      }));
      setPODivOptions(divisionOptions);
      setSelectedDivision(divisionOptions[0]?.value);
    }
  }, [purchaseOrderDivision, selectedDivision]);

  useEffect(() => {
    if (productsData) {
      setSortedData(
        sortData(productsData.products, {
          sortBy,
          reversed: reverseSortDirection,
          search,
        })
      );
    }
  }, [productsData, reverseSortDirection, search, sortBy]);

  const setSorting = (field: keyof TProduct) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(
      sortData(productsData?.products ?? [], {
        sortBy: field,
        reversed,
        search,
      })
    );
  };

  const totalPages = Math.ceil(
    (productsData?.products.length ?? 0) / rowsPerPage
  );

  const rows = sortedData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row) => (
      <tr key={`${row.ITEM_CODE}-${row.CREATED}`}>
        {/* <td>
          <Avatar radius="xl" size="xl" src={row.image}>
            <RiImageLine size={26} />
          </Avatar>
        </td> */}
        <td style={{ maxWidth: "22rem" }}>
          <Title order={5}>{row.ITEM_NAME}</Title>
          <Text color="dimmed">Category : {row.CAT_NAME}</Text>
          <Text color="dimmed">Brand : {row.BRAND_NAME}</Text>
          <Text color="dimmed">Code : {row.ITEM_CODE}</Text>
        </td>
        <td>
          <Text size="md" color="dimmed">
            {dayjs(row.CREATED).format("DD-MMM-YYYY")}
          </Text>
          <Text size="md">{dayjs(row.LAST_UPDATED).format("DD-MMM-YYYY")}</Text>
        </td>
        <td>
          <Text size="md">{row.PRICE}</Text>
        </td>
        <td>
          <Text size="md">{row.UOM}</Text>
        </td>
        <td>
          <Text size="md">{row.STOCK}</Text>
        </td>

        <td>
          {row.STATUS === "Active" ? (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="green"
              sx={{ width: "7.5rem" }}
            >
              Active
            </Badge>
          ) : row.STATUS === "Inactive" ? (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="red"
              sx={{ width: "7.5rem" }}
            >
              Inactive
            </Badge>
          ) : (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="dark"
              sx={{ width: "7.5rem" }}
            >
              {row.STATUS}
            </Badge>
          )}
        </td>
      </tr>
    ));

  const summaryFields: TSummaryFields[] = [
    {
      label: "All",
      badgeText: productsData?.countOfProducts[0].ALL_PRODUCTS ?? 0,
    },
    {
      label: "Active",
      badgeText: productsData?.countOfProducts[0].ACTIVE ?? 0,
      color: "green",
    },
    {
      label: "Inactive",
      badgeText: productsData?.countOfProducts[0].INACTIVE ?? 0,
      color: "red",
    },
    {
      label: "Out of Stock",
      badgeText: productsData?.countOfProducts[0].OUT_OF_STOCK ?? 0,
      color: "dark",
    },
  ];

  return (
    <>
      <Group position="apart" mb="md">
        <DataSummary summaryFields={summaryFields} />
        <Select
          zIndex={500}
          sx={{ width: "16rem" }}
          placeholder="Select division"
          value={selectedDivision}
          onChange={setSelectedDivision}
          data={pODivOptions ?? []}
          nothingFound="No divisions found"
          disabled={isLoadingPODivision}
          rightSection={isLoadingPODivision && <Loader size={18} />}
        />
      </Group>
      <Paper
        withBorder
        radius="md"
        my="sm"
        sx={{ minWidth: 700, position: "relative" }}
      >
        <LoadingOverlay visible={isLoadingProductsData} />
        <ScrollArea>
          <Paper sx={{ height: "38.8rem", width: "100%" }}>
            <Table
              horizontalSpacing="md"
              verticalSpacing="xs"
              striped
              sx={{ tableLayout: "auto" }}
            >
              <thead>
                <tr>
                  {/* <Th sorted={false} reversed={false} onSort={() => {}}>
                    Image
                  </Th> */}
                  <Th
                    sorted={sortBy === "ITEM_NAME"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("ITEM_NAME")}
                  >
                    Product
                  </Th>
                  <Th
                    sorted={sortBy === "LAST_UPDATED"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("LAST_UPDATED")}
                  >
                    Created/Last Updated
                  </Th>
                  <Th
                    sorted={sortBy === "PRICE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("PRICE")}
                  >
                    Unit Price
                  </Th>
                  <Th
                    sorted={sortBy === "UOM"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("UOM")}
                  >
                    Uom
                  </Th>
                  <Th
                    sorted={sortBy === "STOCK"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("STOCK")}
                  >
                    Stock
                  </Th>
                  <Th
                    sorted={sortBy === "STATUS"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("STATUS")}
                  >
                    Status
                  </Th>
                </tr>
              </thead>
              <tbody>
                {rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td colSpan={6}>
                      <Text weight={500} align="center" color="red">
                        Nothing found
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Paper>
        </ScrollArea>
      </Paper>
      <Pagination
        disabled={!productsData?.products.length}
        position="right"
        page={page + 1}
        onChange={(page: number) => setPage(page - 1)}
        withEdges
        total={totalPages}
      />
    </>
  );
};

export default ProductsTable;
