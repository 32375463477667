import React from "react";
import {Form, Formik, FieldArray} from "formik";
import {
    Button,
    Grid,
    Group,
    Title, Divider, Text, Paper,
    useMantineTheme,
} from "@mantine/core";
import TextInputField from "../../../../components/TextField/TextInputField";
// import {CAPCHA_SITE_KEY} from "../../../../config";
// import {BsUpload} from "react-icons/bs";
// import {AiFillPlusCircle} from "react-icons/ai";
// import {generateRandomString} from "../../../../helper/common";
// import SelectDropdown from "../../../../components/SelectDropdown";
import { TRegisterForm} from "../../../../types/register";
import RadioInput from "../../../../components/RadioInput";
import DatePickerField from "../../../../components/DatePickerField";
// import TextAreaField from "../../../../components/TextField/TextAreaField";
import {handleValidation} from "../../Validation";
// import {documentInfoValidation} from "../form";

type TProps = {

    handleFormSubmit: (
        values: TRegisterForm,
    ) => Promise<void>;

};

const BdaForm = ({
                     handleFormSubmit
                 }: TProps) => {
    const theme = useMantineTheme();
    // const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
    // const reCaptchaRef = useRef<ReCAPTCHA>(null);
    // const [isValid, setIsValid] = useState<Record<string, boolean>>({})
    // const [triggerValidateAll, setTriggerValidateAll] = useState(false)

    const removeFromList = (i: any, values: any, setValues: any) => {
        const progressive_rebate = [...values.progressive_rebate];
        progressive_rebate.splice(i, 1);
        setValues({...values, progressive_rebate});
    };

    const updateForm = (values: any, setValues: any) => {
        // update dynamic form
        const progressive_rebate: any[] = [...values.progressive_rebate];
        progressive_rebate.push({
            amount_from: "",
            amount_to: "",
            rebate_perc: "",
        });
        setValues({...values, progressive_rebate});
    };
    // const errorMessage = {
    //     color: "#d32f2f",
    //     position: "absolute",
    //     fontSize: "12px",
    //     marginTop: "5px"
    // };
    return (
        <Formik
            initialValues={{
                date_from: "",
                date_to: "",
                credit_period: "",
                cost_goods: "",
                domestic_promotion_count: "",
                exclusive_promotion_count: "",
                fc_marketing_support: "",
                stock_aging_retun_period: "",
                expiry_return_days: "",
                expiry_return_cost_perc: "",
                progressive_rebate: [
                    {
                        amount_from: "",
                        amount_to: "", // missing
                        rebate_perc: "",
                    }
                ],
                //missing in procedure
                rebate: "",
                first_invoice_discount: "",
                logistics_support: "",

                merch_pass: "",
                uniform_costs: "",
                display_support: "",
// merchandising and display support
                pay_mode: "",
                pay_interval: "",
                pay_count: "",
                amount: "",
                disc_perc: "",
                created_user: "",

            }}
            validationSchema={handleValidation}
            onSubmit={async (values: any) => {
                handleFormSubmit(values);
            }}
        >
            {(formik) => (
                <Form>
                    <Grid gutter="md">
                        <Grid.Col xs={12}>
                            <Title
                                order={3}
                                mt="md"
                            >
                                Annexure 1(Special Terms)
                            </Title>

                            <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]} sx={{width: "50px"}}/>
                        </Grid.Col>


                        <Grid.Col xs={12} md={4}>
                            {/*<TextInputField formik={formik} id="P_Name" label="Start Date Of the Agreement"/>*/}
                            <DatePickerField

                                variant="standard"
                                id="date_from"
                                formik={formik}
                                label="Start Date Of the Agreement"
                            />
                        </Grid.Col>


                        <Grid.Col xs={12} md={4}>
                            <DatePickerField

                                variant="standard"
                                id="date_to"
                                formik={formik}
                                label="End Date Of the Agreement"
                            />
                        </Grid.Col>

                        <Grid.Col xs={12} md={4}>
                            <TextInputField
                                formik={formik}
                                id="credit_period"
                                label="Credit Period"
                                defaultValue='5'
                            />
                            <Text size={'xs'}>(Calculated In Days)</Text>
                        </Grid.Col>
                        <Grid.Col xs={12} md={4}>
                            <TextInputField
                                formik={formik}
                                id="rebate"
                                label="Rebate"
                            />
                            <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                        </Grid.Col>

                        <Grid.Col xs={12} md={4}>
                            <TextInputField
                                formik={formik}
                                id="first_invoice_discount"
                                label="First Invoice Discount"
                            />
                            <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                        </Grid.Col>
                        <Grid.Col xs={12} md={4}>
                            <TextInputField
                                formik={formik}
                                id="fc_marketing_support"
                                label="Familyclub Marketing Support"
                            />
                            <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                        </Grid.Col>


                        <Grid.Col xs={12} md={8}>
                            <Grid gutter="md">
                                <Grid.Col xs={12} md={6}>
                                    <TextInputField
                                        formik={formik}
                                        id="expiry_return_days"
                                        label="Expiry Return Period Days"
                                    />
                                    <Text size={'xs'}>(Calculated In Days)</Text>
                                </Grid.Col>
                                <Grid.Col xs={12} md={6}>
                                    <TextInputField
                                        formik={formik}
                                        id="expiry_return_cost_perc"
                                        label="Expiry Return Period %"
                                    />
                                    <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                                </Grid.Col>

                            </Grid>
                        </Grid.Col>

                        <Grid.Col xs={12} md={4}>
                            <TextInputField
                                formik={formik}
                                id="stock_aging_retun_period"
                                label="Stock Aging Return Period"
                            />
                            <Text size={'xs'}>(Calculated In Days)</Text>
                        </Grid.Col>


                        <Grid.Col xs={12} md={12}>


                            <RadioInput
                                id="cost_goods"
                                formik={formik}
                                label="Cost Of goods on invoice"
                                radioList={[
                                    {
                                        label: (
                                            <TextInputField
                                                formik={formik}
                                                id="cost_of_good_perc"
                                                placeholder="% of Recommended Retail Price"
                                                // label="% "
                                            />
                                        ), value: "percentage"
                                    },
                                    {label: "Supplier Price List", value: "Supp_price_list"},
                                    {label: "Others", value: "OTH"},
                                ]}
                            />

                        </Grid.Col>

                    </Grid>

                    <Grid gutter="md">
                        <Grid.Col xs={12}>
                            <Grid gutter="md">
                                <Grid.Col xs={12} md={6}>
                                    <Title order={3} mt="md">
                                        Progressive Rebate Slabs
                                    </Title>
                                    <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                             sx={{width: "50px"}}/>
                                </Grid.Col>
                                <Grid.Col xs={12} md={6}
                                          sx={{display: "flex", justifyContent: "flex-end", alignItems: "flex-end"}}>
                                    {/* <ActionIcon size="lg" radius="lg" variant="transparent" mt="20px"
                                            onClick={(e) => updateForm(formik.values, formik.setValues)}>
                                            <AiFillPlusCircle size={34}
                                                color={theme.colors[theme.primaryColor][5]} />
                                        </ActionIcon> */}

                                    <Button color="green"
                                            onClick={(e) => updateForm(formik.values, formik.setValues)}>Add</Button>
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>

                        <Grid.Col xs={12}>
                            <Grid gutter="sm">
                                <FieldArray name="progressive_rebate">
                                    {() =>
                                        formik.values.progressive_rebate.map((item: any, i: number) => {
                                            return (
                                                <Grid.Col xs={12}>
                                                    <Paper withBorder p="xl" radius="lg">
                                                        <Grid gutter="md">
                                                            <Grid.Col xs={12} md={4}>
                                                                <Grid gutter="md">
                                                                    <Grid.Col xs={12} md={6}>
                                                                        <Text fw={700}
                                                                              mt={'25px'}>{`Slab ${i + 1}`}</Text>
                                                                    </Grid.Col>
                                                                    <Grid.Col xs={12} md={6}>
                                                                        <TextInputField formik={formik}
                                                                                        id={`progressive_rebate.${i}.amount_from`}
                                                                                        label="Amount"/>
                                                                        {/*<ErrorMessage component="div"*/}
                                                                        {/*name={`progressive_rebate.${i}.amount_from`}  style={errorMessage} />*/}
                                                                        <Text size={'xs'}>(QAR)</Text>
                                                                    </Grid.Col>
                                                                </Grid>
                                                            </Grid.Col>

                                                            <Grid.Col xs={12} md={4}>
                                                                <Grid gutter="md">
                                                                    {/*<Grid.Col xs={12} md={6}>*/}
                                                                    {/*<TextInputField formik={formik}*/}
                                                                    {/*id={`progressive_rebate.${i}.amount_to`}*/}
                                                                    {/*label="Amount To"/>*/}
                                                                    {/*<ErrorMessage component="div"*/}
                                                                    {/*name={`progressive_rebate.${i}.amount_to`}*/}
                                                                    {/*style={errorMessage}/>*/}
                                                                    {/*</Grid.Col>*/}
                                                                    <Grid.Col xs={12} md={6}>
                                                                        <TextInputField formik={formik}
                                                                                        id={`progressive_rebate.${i}.rebate_perc`}
                                                                                        label="Additional Rebate"/>
                                                                        {/*<ErrorMessage component="div"*/}
                                                                        {/*name={`progressive_rebate.${i}.rebate_perc`}*/}
                                                                        {/*style={errorMessage} />*/}
                                                                        <Text size={'xs'}>(%)</Text>
                                                                    </Grid.Col>

                                                                </Grid>
                                                            </Grid.Col>
                                                            <Grid.Col xs={12} md={4}>
                                                                <Grid gutter="md">
                                                                    <Grid.Col xs={12} md={6}>

                                                                    </Grid.Col>
                                                                    <Grid.Col xs={12} md={6}>
                                                                        {formik.values.progressive_rebate.length > 1 && (
                                                                            <Grid.Col xs={12} md={12} sx={{
                                                                                display: "flex",
                                                                                justifyContent: "flex-end",
                                                                                alignItems: "flex-end"
                                                                            }}>
                                                                                <Button color="red"
                                                                                        onClick={() => removeFromList(i, formik.values, formik.setValues)}>Remove</Button>
                                                                            </Grid.Col>
                                                                        )}
                                                                    </Grid.Col>

                                                                </Grid>
                                                            </Grid.Col>

                                                        </Grid>


                                                    </Paper>
                                                </Grid.Col>


                                            );
                                        })
                                    }
                                </FieldArray>

                            </Grid>
                        </Grid.Col>


                        {/*<Grid.Col xs={12} my="xs">*/}

                        {/*</Grid.Col>*/}
                    </Grid>
                    <Grid gutter="md">
                        <Grid.Col xs={12}>
                            <Title
                                order={3}
                                mt="md"
                            >
                                Merchandising & Display Support
                            </Title>

                            <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]} sx={{width: "50px"}}/>
                        </Grid.Col>

                        <Grid.Col xs={12} md={6}>
                            <Grid gutter="md">
                                <Grid.Col xs={12} md={6}>
                                    <TextInputField
                                        formik={formik}
                                        id="merch_pass"
                                        label="Merchandiser Pass"
                                    />
                                    <Text size={'xs'}>(1 pass valid for 1 year)</Text>
                                </Grid.Col>
                                <Grid.Col xs={12} md={6}>
                                    <TextInputField
                                        formik={formik}
                                        id="uniform_costs"
                                        label="Uniform Costs"
                                    />
                                    <Text size={'xs'}>(To be paid directly to the store)</Text>
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>

                        <Grid.Col xs={12} md={6}>
                            <Grid gutter="md">
                                <Grid.Col xs={12} md={6}>
                                    <TextInputField
                                        formik={formik}
                                        id="display_support"
                                        label="Central Display Support"
                                    />
                                    <Text size={'xs'}>(QAR)</Text>
                                </Grid.Col>
                                <Grid.Col xs={12} md={6}>
                                    {/*<TextInputField formik={formik} id="P_City" label="City"/>*/}
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>
                    </Grid>
                    <Grid gutter="md">


                        <Grid.Col xs={12}>
                            <Title
                                order={3}
                                mt="md"
                            >
                                Marketing Support
                            </Title>
                            <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]} sx={{width: "50px"}}/>
                        </Grid.Col>


                        <Grid.Col xs={12} md={6}>
                            <Grid gutter="md">
                                <Grid.Col xs={12} md={6}>
                                    <TextInputField
                                        formik={formik}
                                        id="domestic_promotion_count"
                                        label="Number of Domestic Promotions"
                                    />
                                    <Text size={'xs'}>(Per Year)</Text>
                                </Grid.Col>
                                <Grid.Col xs={12} md={6}>
                                    <TextInputField formik={formik} id="exclusive_promotion_count"
                                                    label="Number Of Exclusive Promotions"/>
                                    <Text size={'xs'}>(Per Year)</Text>
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>

                    </Grid>


                    <Grid gutter="md">


                        <Grid.Col xs={12}>
                            <Title
                                order={3}
                                mt="md"
                            >
                                Logistics Support
                            </Title>
                            <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]} sx={{width: "50px"}}/>
                        </Grid.Col>


                        <Grid.Col xs={12} md={6}>
                            <Grid gutter="md">
                                <Grid.Col xs={12} md={6}>
                                    <TextInputField
                                        formik={formik}
                                        id="logistics_support"
                                        label="Logistics & Warehousing Support"
                                    />
                                    <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                                </Grid.Col>
                                <Grid.Col xs={12} md={6}>
                                    {/*<TextInputField formik={formik} id="P_Ac_no" label="Number Of Exclusive Promotions"/>*/}
                                </Grid.Col>
                            </Grid>
                        </Grid.Col>

                    </Grid>

                    <Grid gutter="md">
                        <Grid.Col xs={12}>
                            <Grid gutter="md">
                                <Grid.Col xs={12} md={3}></Grid.Col>

                                <Grid.Col xs={12} md={6}>
                                    <Group position="apart">
                                        <Button
                                            size={'xl'}
                                            fullWidth
                                            sx={{background: '#54B948'}}
                                            // width="1rem"
                                            type="submit"
                                            mt="70px"
                                            mb="50px"
                                        >
                                            Agree & Continue
                                        </Button>
                                    </Group>
                                </Grid.Col>
                                <Grid.Col xs={12} md={3}></Grid.Col>
                            </Grid>
                        </Grid.Col>
                    </Grid>


                </Form>
            )}
        </Formik>
    );
};

export default BdaForm;
