import { Divider } from "@mantine/core";
import Overview from "./Overview";
import RecentOrders from "./RecentOrders";

const HomeDashboard = () => {
  return (
    <>
      <Overview />
      <Divider my="xl" />
      <RecentOrders />
    </>
  );
};

export default HomeDashboard;
