import {useState} from "react";
import {
    Container,
    LoadingOverlay,
    Title,
} from "@mantine/core";
import {closeAllModals, openModal} from "@mantine/modals";
import {api} from "../../api";
// import {InitialState} from "./form";
import SimpleCustomAlert from "../../components/SimpleCustomAlert";
import {TOption} from "../../types/register";
import dayjs from "dayjs";


import RegisterForm from "./RegisterForm";
import {useQuery} from "@tanstack/react-query";
import {getAccountTypes, getBanks, getCompanyTypes, getCurrency} from "../../queries/supplierRegister";

const Register = () => {
    // console.log(process.env.REACT_APP_IDLE_TIMEOUT)
    console.log(process.env.REACT_APP_CAPTCHA_SECRET)
    // console.log(444)
    const [isLoading, setIsLoading] = useState(false);

    const {isLoading: isCompanyTypeLoading, data: companyTypeOptions} = useQuery<TOption[], Error>(
        ["supplierRegister", "companyType"],
        () => getCompanyTypes(),
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );

    const {isLoading: isBankLoading, data: bankOptions} = useQuery<TOption[], Error>(
        ["supplierRegister", "banks"],
        () => getBanks(),
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );

    const {isLoading: isAccountTypeLoading, data: accountTypeOptions} = useQuery<TOption[], Error>(
        ["supplierRegister", "accountType"],
        () => getAccountTypes(),
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );

    const {isLoading: isCurrencyLoading, data: currencyOptions} = useQuery<TOption[], Error>(
        ["supplierRegister", "currency"],
        () => getCurrency(),
        {
            onError: (error) => {
                console.log(error);
            },
        }
    );

    const handleFormSubmit = async (
        values: any,
       captchaToken: string | null | undefined
    ) => {
        try {
            setIsLoading(true);

            console.log(values)
            let formValues = {...values};
            formValues.P_Flag = "INSERT";
            formValues.P_Status = "O";

            formValues.P_Crno_Exp_Dt = dayjs(values.P_Crno_Exp_Dt).format("DD-MMM-YYYY") ;
            formValues.P_Licno_Exp_Dt =dayjs(values.P_Licno_Exp_Dt).format("DD-MMM-YYYY")  ;

            // if (formValues.isFfcEmployee === "no") formValues.P_Supp_Code = "";
            const formData = new FormData();

         

           formData.append("captchaToken", captchaToken ? captchaToken : "");

            if (values.P_CR_FILE) formData.append("CR", values.P_CR_FILE);
            // else formValues.P_CR_NO = "";
            if (values.P_MTL_FILE) formData.append("MTL", values.P_MTL_FILE);
            else formValues.P_MTL_NO = "";
            if (values.P_CC_FILE) formData.append("CC", values.P_CC_FILE);
            else formValues.P_CC_NO = "";
            if (values.P_TC_FILE) formData.append("TC", values.P_TC_FILE);
            else formValues.P_TC_NO = "";
            if (values.P_SPID_FILE) formData.append("SPID", values.P_SPID_FILE);
            // else formValues.P_SPID_NO = "";

            formData.append("formValues", JSON.stringify(formValues));

           const response = await api.post("supplier/register", undefined, formData);
             // const response:any =[]
            if (response.success) {
                openModal({
                    // title: "Success",
                    centered: true,
                    children: (
                        <SimpleCustomAlert
                            type="success"
                            title="Registration Completed."
                            handleClose={() => closeAllModals()}
                        />
                    ),
                });
            } else throw new Error(response.message);
            setIsLoading(false);
        } catch (error: any) {
            openModal({
                // title: "Failed",
                centered: true,
                children: (
                    <SimpleCustomAlert
                        type="error"
                        title={error.message}
                        handleClose={() => closeAllModals()}
                    />
                ),
            });
            console.log(error);
            setIsLoading(false);
        }
    };

    return (
        <>
            <LoadingOverlay
                visible={isLoading}
                loaderProps={{size: "xl", variant: "bars"}}
            />
            {/*<Container py="xl" sx={{flexGrow: 1, width: "100%"}}>*/}
            <Container size={1500} px={0}>
                <Title
                    order={1}
                    mt="60px"
                    sx={(theme) => ({
                        textAlign: 'center',
                        // borderBottom: `10px solid ${theme.colors[theme.primaryColor][5]
                        //     }`,
                    })}
                >
                    Company Setup
                </Title>

                <RegisterForm
                    isCompanyTypeLoading={isCompanyTypeLoading}
                    companyTypeOptions={companyTypeOptions}
                    isBankLoading={isBankLoading}
                    bankOptions={bankOptions}
                    isAccountTypeLoading={isAccountTypeLoading}
                    accountTypeOptions={accountTypeOptions}
                    isCurrencyLoading={isCurrencyLoading}
                    currencyOptions={currencyOptions}
                    handleFormSubmit={handleFormSubmit}
                />

            </Container>
        </>
    );
};

export default Register;
