import { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  UnstyledButton,
  Group,
  Text,
  useMantineTheme,
  Collapse,
  Stack,
} from "@mantine/core";
import NavMenuItem from "../NavMenuItem";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import { TNavMenu } from "../../../../layout/dashboard/menu-items";

const NavMenuCollapse = ({
  menuItem,
  level,
}: {
  menuItem: TNavMenu;
  level: number;
}) => {
  const [isGroupOpened, setGroupOpened] = useState(false);
  const theme = useMantineTheme();
  const Icon = menuItem.icon;
  const location = useLocation();

  const isNavChildSelected = menuItem.children.some(
    (child) => `${child.url}` === location.pathname.split("/")[2]
  );

  const navItems = menuItem.children?.map((item) => {
    switch (item.type) {
      case "collapse":
        return (
          <NavMenuCollapse key={item.id} menuItem={item} level={level + 1} />
        );
      case "item":
        return <NavMenuItem key={item.id} menuItem={item} level={level + 1} />;
      default:
        return (
          <Text key={item.id} color="red">
            Menu Item Error
          </Text>
        );
    }
  });

  return (
    <>
      <UnstyledButton
        sx={{
          display: "block",
          width: "100%",
          padding: theme.spacing.md,
          borderRadius: theme.radius.xl,
          color: theme.white,
          paddingLeft: `${level * theme.spacing.sm}px`,
          backgroundColor: isNavChildSelected
            ? theme.colors.ffcBrand[5]
            : theme.colors.ffcBrand[7],
          "&:hover": {
            backgroundColor: theme.colors.ffcBrand[5],
          },
        }}
        onClick={() => setGroupOpened((prev) => !prev)}
      >
        <Group position="apart">
          <Group>
            {Icon && <Icon size={20} />}
            <div style={{ display: "inline", fontSize: 14 }}>
              {menuItem.title}
            </div>
          </Group>
          {isGroupOpened ? (
            <FaChevronUp color={theme.white} size={12} />
          ) : (
            <FaChevronDown color={theme.white} size={12} />
          )}
        </Group>
      </UnstyledButton>
      <Collapse
        in={isGroupOpened}
        transitionDuration={250}
        transitionTimingFunction="linear"
      >
        <Stack spacing="xs">{navItems}</Stack>
      </Collapse>
    </>
  );
};

export default NavMenuCollapse;
