import { api } from "../api";
import { TSupplierData } from "../types/dashboard";

export const getSupplierData = async ({
  division,
  timePeriod,
}: {
  division: string | null;
  timePeriod: number;
}) => {
  if (!division) return null;
  const [, P_Comp_Code] = division.split("_");
  const response: TSupplierData[] = await api.post("dashboard", undefined, {
    P_Comp_Code,
    P_Period: timePeriod,
  });
  return response[0];
};
