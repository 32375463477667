import { TNavMenu } from ".";
import { AiFillBell } from "react-icons/ai";

const notifications: TNavMenu = {
  id: "notifications",
  title: "Notifications",
  type: "item",
  url: "notifications",
  icon: AiFillBell,
  children: [],
};

export default notifications;
