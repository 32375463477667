import { TNavMenu } from ".";
import { TbTruckReturn } from "react-icons/tb";

const returns: TNavMenu = {
  id: "returns",
  title: "Returns",
  type: "item",
  url: "returns",
  icon: TbTruckReturn,
  children: [],
};

export default returns;
