import { Badge, DefaultMantineColor, Group, Text } from "@mantine/core";

export type TSummaryFields = {
  label: string;
  badgeText: string | number;
  color?: DefaultMantineColor | undefined;
};

const DataSummary = ({
  summaryFields,
}: {
  summaryFields: TSummaryFields[];
}) => {
  return (
    <Group spacing="xl">
      {summaryFields.map((field) => {
        return (
          <Group key={field.label} spacing="xs" align="center">
            <Text>{field.label}</Text>
            <Badge
              sx={(theme) => ({
                backgroundColor:
                  theme.colors[field.color ?? theme.primaryColor][6],
              })}
              variant="filled"
              radius="sm"
              size="lg"
            >
              {field.badgeText}
            </Badge>
          </Group>
        );
      })}
    </Group>
  );
};

export default DataSummary;
