import { Link, useLocation } from "react-router-dom";
import { UnstyledButton, Group, useMantineTheme } from "@mantine/core";
import { TNavMenu } from "../../../../layout/dashboard/menu-items";

const NavMenuItem = ({
  menuItem,
  level,
}: {
  menuItem: TNavMenu;
  level: number;
}) => {
  const theme = useMantineTheme();
  const Icon = menuItem.icon;
  const location = useLocation();

  return (
    <UnstyledButton
      component={Link}
      to={`/dashboard/${menuItem.url}`}
      sx={{
        display: "block",
        width: "100%",
        padding: theme.spacing.md,
        borderRadius: theme.radius.xl,
        color: theme.white,
        paddingLeft: `${level * theme.spacing.sm}px`,
        backgroundColor:
          location.pathname.split("/")[2] === `${menuItem.url}` ||
          (!location.pathname.split("/")[2] && !menuItem.url)
            ? theme.colors.ffcBrand[5]
            : theme.colors.ffcBrand[7],
        "&:hover": {
          backgroundColor: theme.colors.ffcBrand[5],
        },
        "&:active": {
          backgroundColor: theme.colors.ffcBrand[6],
        },
      }}
    >
      <Group>
        {Icon && <Icon size={20} color={theme.white} />}

        <div style={{ display: "inline", fontSize: 14 }}>{menuItem.title}</div>
      </Group>
    </UnstyledButton>
  );
};

export default NavMenuItem;
