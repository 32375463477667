import { api } from "../api";
import { TReturnDocument, TReturnDocumentItem } from "../types/returns";

export const getReturnDocumentList = async ({
  selectedDivision,
  timePeriod,
}: {
  selectedDivision: string | null;
  timePeriod: number;
}) => {
  if (!selectedDivision) return null;
  const [P_Divn_Code, P_Comp_Code] = selectedDivision.split("_");
  const response: TReturnDocument[] = await api.post(
    "return/docList",
    undefined,
    {
      P_Comp_Code,
      P_Divn_Code,
      P_Period: timePeriod,
    }
  );
  return response;
};

export const getReturnDocItemDetail = async ({
  division,
  docId,
}: {
  division: string | undefined;
  docId: string | undefined;
}) => {
  if (!division || !docId) return null;
  const [P_Divn_Code, P_Comp_Code] = division.split("_");
  const response: TReturnDocumentItem[] = await api.post(
    "return/docItemList",
    undefined,
    {
      P_Comp_Code,
      P_Divn_Code,
      P_Doc_Id: docId,
    }
  );
  return response;
};
