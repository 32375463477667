import { useEffect, useMemo, useState } from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  Paper,
  Pagination,
  Select,
  Button,
  Badge,
  Title,
  LoadingOverlay,
  Loader,
} from "@mantine/core";
import {
  RiArrowUpDownLine,
  RiArrowUpLine,
  RiArrowDownLine,
} from "react-icons/ri";
import dayjs from "dayjs";
import { TPendingPurchaseOrder } from "../../types/orders";
import DataSummary, { TSummaryFields } from "../../components/DataSummary";
import { useQuery } from "@tanstack/react-query";
import { getPendingPurchaseOrder } from "../../queries/orders";
import { Link, useSearchParams } from "react-router-dom";
import { useGetAllDivisions } from "../../hooks/useQueryHooks";
import { TThProps } from "../../types/common";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.md}px ${theme.spacing.lg}px`,

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },

  icon: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },
}));

function Th({ children, reversed, sorted, onSort }: TThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? RiArrowUpLine
      : RiArrowDownLine
    : RiArrowUpDownLine;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group spacing="xs">
          <Text weight={500} size="md">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: TPendingPurchaseOrder[], search: string) {
  if (search === "") return data;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.keys(data[0]).some((key) =>
      (item[key as keyof TPendingPurchaseOrder] + "")
        .toLowerCase()
        .includes(query)
    )
  );
}

function sortData(
  data: TPendingPurchaseOrder[],
  payload: {
    sortBy: keyof TPendingPurchaseOrder | null;
    reversed: boolean;
    search: string;
  }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[sortBy] + "").localeCompare(a[sortBy] + "", "en", {
          numeric: true,
        });
      }

      return (a[sortBy] + "").localeCompare(b[sortBy] + "", "en", {
        numeric: true,
      });
    }),
    payload.search
  );
}

// // DUMMY DATA
// const statusArray: ["pending", "shipped", "cancel"] = [
//   "pending",
//   "shipped",
//   "cancel",
// ];

// const data: TPendingPurchaseOrder[] = [...Array(15)].map((_, idx) => ({
//   created: new Date((new Date() as any) - Math.random() * 1e12).toJSON(),
//   orderDetail: `order ${Math.floor(Math.random() * 100)}`,
//   orderStatus: statusArray[Math.floor(Math.random() * statusArray.length)],
//   image: `https://source.unsplash.com/random/20${idx}x20${idx}?fruit`,
//   product: `product ${Math.floor(Math.random() * 100)}`,
// }));
// //

// const tableHeader = {
//   created: { name: "Created", isSortable: true },
//   orderDetail: { name: "Order Details", isSortable: true },
//   product: { name: "Product(s)", isSortable: true },
//   image: { name: "Image", isSortable: false },
//   orderStatus: { name: "Order Status", isSortable: true },
//   action: { name: "Action", isSortable: true },
// };

const RecentOrdersTable = () => {
  const [selectedDivision, setSelectedDivision] = useState<string | null>(null);
  const [pODivOptions, setPODivOptions] = useState<
    { label: string; value: string }[]
  >([]);
  // const [search, setSearch] = useState("");
  const [search] = useState("");
  const [sortedData, setSortedData] = useState<TPendingPurchaseOrder[]>([]);
  const [sortBy, setSortBy] = useState<keyof TPendingPurchaseOrder | null>(
    null
  );
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const [searchParams, setSearchParams] = useSearchParams();

  // const queryClient = useQueryClient();

  const { isLoading: isLoadingPODivision, data: purchaseOrderDivision } =
    useGetAllDivisions();

  const { isLoading: isLoadingPendingPO, data: pendingPO } = useQuery<
    TPendingPurchaseOrder[] | null,
    Error
  >(
    ["orders", "pendingPurchaseOrder", selectedDivision],
    () => getPendingPurchaseOrder(selectedDivision),
    {
      // enabled: !!selectedDivision,
      // select: (data) => {
      //   console.log("select", data);
      //   return data;
      // },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    if (pendingPO) {
      setSortedData(
        sortData(pendingPO, {
          sortBy,
          reversed: reverseSortDirection,
          search,
        })
      );
    }
  }, [pendingPO, reverseSortDirection, search, sortBy]);

  useEffect(() => {
    if (purchaseOrderDivision && !selectedDivision) {
      const divisionOptions = purchaseOrderDivision.map((division) => ({
        label: division.DIVN_NAME,
        value: `${division.DIVN_CODE}_${division.DIVN_COMP_CODE}`,
      }));
      setPODivOptions(divisionOptions);

      // To access division value for other components
      const division = searchParams.get("division");
      if (division) {
        setSelectedDivision(division);
      } else {
        setSelectedDivision(divisionOptions[0]?.value);
        setSearchParams(
          { division: divisionOptions[0]?.value },
          { replace: true }
        );
      }
    }
  }, [purchaseOrderDivision, searchParams, selectedDivision, setSearchParams]);

  // useEffect(() => {
  //   const division = searchParams.get("division");
  //   if (!selectedDivision && division) {
  //     setSelectedDivision(division);
  //   }
  // }, [searchParams, selectedDivision]);

  const setSorting = (field: keyof TPendingPurchaseOrder) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(
      sortData(pendingPO ?? [], { sortBy: field, reversed, search })
    );
  };

  // const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const { value } = event.currentTarget;
  //   setSearch(value);
  //   setSortedData(
  //     sortData(data, { sortBy, reversed: reverseSortDirection, search: value })
  //   );
  // };

  // // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const totalPages = Math.ceil((pendingPO?.length ?? 0) / rowsPerPage);

  const rows = sortedData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row) => (
      <tr key={`${row.POH_ID}-${row.POH_NO}`}>
        <td>
          <Text size="md">{dayjs(row.POH_DT).format("DD-MMM-YYYY")}</Text>
          {/* <Text color="dimmed">{dayjs(row.created).format("hh:mm A")}</Text> */}
        </td>
        <td>
          <Title order={5}>{row.POH_NO}</Title>
          <Text color="dimmed">Payment Method : {row.PAY_MODE}</Text>
          {/* <Text color="dimmed">Total Qty : 220</Text> */}
          <Text color="dimmed">Remarks : {row.POH_REMARKS}</Text>
        </td>
        <td>
          <Text size="md">{row.TXN_CODE}</Text>
        </td>
        <td>
          <Text size="md">{row.TXN_NAME}</Text>
        </td>
        <td>
          <Text size="md">{row.TXN_TYPE}</Text>
        </td>
        {/* <td style={{ maxWidth: "22rem" }}>
          <Title order={5}>
            THAI Choice Young Coconut Juice with Pulp Strips {row.product}
          </Title>
          <Text color="dimmed">Product SKU : 102464</Text>
          <Text color="dimmed">Qty : 10</Text>
        </td>
        <td>
          <Avatar radius="xl" size="xl" src={row.image} >
            <RiImageLine size={26} />
          </Avatar>
        </td> */}
        <td>
          {row.PO_STATUS === "Pending" ? (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="blue"
              sx={{ width: "7rem" }}
            >
              Pending
            </Badge>
          ) : (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="gray"
              sx={{ width: "7rem" }}
            >
              Not Pending
            </Badge>
          )}
        </td>
        <td>
          <Button
            variant="outline"
            component={Link}
            to={`/dashboard/orders/${selectedDivision}/${row.POH_ID}/${row.TXN_TYPE}`}
          >
            View
          </Button>
        </td>
      </tr>
    ));

  const statusSummary:
    | Record<TPendingPurchaseOrder["PO_STATUS"], number>
    | undefined = useMemo(
    () =>
      pendingPO?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.PO_STATUS]: acc[curr.PO_STATUS] + 1,
        }),
        {
          Pending: 0,
        }
      ),
    [pendingPO]
  );

  const summaryFields: TSummaryFields[] = [
    { label: "All orders", badgeText: pendingPO?.length ?? 0 },
    // {
    //   label: "Shipped",
    //   badgeText: statusSummary?.shipped ?? 0,
    //   color: "green",
    // },
    { label: "Pending", badgeText: statusSummary?.Pending ?? 0, color: "blue" },
    // { label: "Cancel", badgeText: statusSummary?.cancel ?? 0, color: "red" },
  ];

  return (
    <>
      {/* <TextInput
        placeholder="Search by any field"
        icon={<RiSearchLine size={14} />}
        value={search}
        onChange={handleSearchChange}
      /> */}
      <Group position="apart" mb="md">
        <DataSummary summaryFields={summaryFields} />
        <Select
          zIndex={500}
          sx={{ width: "16rem" }}
          placeholder="Select division"
          value={selectedDivision}
          onChange={(value) => {
            setSelectedDivision(value);
            if (value) {
              setSearchParams({ division: value }, { replace: true });
            }
          }}
          data={pODivOptions ?? []}
          nothingFound="No divisions found"
          disabled={isLoadingPODivision}
          rightSection={isLoadingPODivision && <Loader size={18} />}
        />
      </Group>
      <Paper
        withBorder
        radius="md"
        my="sm"
        sx={{ minWidth: 700, position: "relative" }}
      >
        <LoadingOverlay visible={isLoadingPendingPO} />
        <ScrollArea>
          <Paper sx={{ height: "38.8rem", width: "100%" }}>
            <Table
              horizontalSpacing="md"
              verticalSpacing="xs"
              striped
              sx={{ tableLayout: "auto" }}
            >
              <thead>
                <tr>
                  <Th
                    sorted={sortBy === "POH_DT"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("POH_DT")}
                  >
                    Created At
                  </Th>
                  <Th
                    sorted={sortBy === "POH_NO"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("POH_NO")}
                  >
                    Order Details
                  </Th>
                  <Th
                    sorted={sortBy === "TXN_CODE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("TXN_CODE")}
                  >
                    Trans.Code
                  </Th>
                  <Th
                    sorted={sortBy === "TXN_NAME"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("TXN_NAME")}
                  >
                    Trans.Name
                  </Th>
                  <Th
                    sorted={sortBy === "TXN_TYPE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("TXN_TYPE")}
                  >
                    Trans.Type
                  </Th>
                  {/* <Th
                    sorted={sortBy === "product"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("product")}
                  >
                    Product(s)
                  </Th>
                  <Th sorted={false} reversed={false} onSort={() => {}}>
                    Images
                  </Th> */}
                  <Th
                    sorted={sortBy === "PO_STATUS"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("PO_STATUS")}
                  >
                    Order Status
                  </Th>
                  <Th sorted={false} reversed={false} onSort={() => {}}>
                    Action
                  </Th>
                </tr>
              </thead>
              <tbody>
                {rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <Text weight={500} align="center" color="red">
                        Nothing found
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Paper>
        </ScrollArea>
      </Paper>
      <Pagination
        disabled={!pendingPO?.length}
        position="right"
        page={page + 1}
        onChange={(page: number) => setPage(page - 1)}
        withEdges
        total={totalPages}
      />
    </>
  );
};

export default RecentOrdersTable;
