import { TNavMenu } from ".";
import { AiFillHome } from "react-icons/ai";

const home: TNavMenu = {
  id: "home",
  title: "Home",
  type: "item",
  url: "",
  icon: AiFillHome,
  children: [],
};

export default home;
