import {Box, Grid, Text, Title,Button} from "@mantine/core";
import SupplierImage from "../../assets/images/home-bg.jpg";
import {Link} from "react-router-dom";

const BdaReview = () => {
    return (


        <Box
            sx={(theme) => ({
                background: `url(${SupplierImage}) no-repeat fixed center`,
                minHeight: "75vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                color: theme.other.textLight,
            })}
        >
            <Grid
                mb={'235px'}
                sx={{
                    width: "100%",
                    padding: "5.6rem",
                    "@media (min-width: 1500px)": {
                        padding: "8.8rem",
                    },
                }}
            >
                <Grid.Col  md={3} ></Grid.Col>
                <Grid.Col  md={6}  >
                    <Title
                        order={1}
                        sx={(theme) => ({
                            fontSize: "3rem",
                            color: theme.other.textLight,
                            letterSpacing: "2px",
                            textAlign:'center'
                        })}
                    >
                    Under Review
                    </Title>
                    <Text mt="xl" sx={(theme) => ({textAlign:'center' })}>
                    Dear Valued Supplier,
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book. It has survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of Letraset
                        sheets containing Lorem Ipsum passages, and more recently with desktop publishing
                        software like Aldus PageMaker including versions of Lorem Ipsum.
                    </Text>

                    <Button
                        component={Link}   to="/bda"
                        sx={(theme) => ({marginTop:"25px",marginLeft:"270px",align:'center',justifyContent:"center"})}
                    >
                       View & Edit BDA
                    </Button>
                </Grid.Col>
                <Grid.Col  md={3}></Grid.Col>
            </Grid>
        </Box>
    );
};

export default BdaReview;
