import React, {useState} from "react";
import {Container, Title, Button, Box, LoadingOverlay} from "@mantine/core";
import BdaView from "./View/BdaView";
import {Link, useLocation} from "react-router-dom";
import {FaDownload} from "react-icons/fa";
import {api} from "../../../api";

// interface RecentOrdersTableProps {
//     className?: string;
//     cryptoOrders: CryptoOrder[];
// }

const BdaViewDashboard = () => {
    const [isLoading, setIsLoading] = useState(false);
    // const [activeStep, setActiveStep] = useState(0);
    // const authStore = useAuthStore();
    // const dispatch = useDispatch();
    // const {bdaState} = useSelector((state: any) => state.bdaState);
    const location = useLocation();


    const handleDownload = async () => {
        setIsLoading(true)
        const response = await api.get("bda/Generate_bda",  {P_Supp_Code:'RCA357'});
        if(response.status === true){
            setIsLoading(false)
            const downloadLink = response.data;
            const fileName: any = downloadLink.split("/").pop();
            fetch(downloadLink).then((resp) => {
                resp.blob().then((blob) => {
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                });
            });
            return;
        }

    }

    return (
        <>
            <LoadingOverlay
            visible={isLoading}
            loaderProps={{size: "xl", variant: "bars"}}
            />

            {/*<Group position="apart" my="xl">*/}

            <Container size={1000} px={0}>


                <Title
                    order={1}
                    mt="60px"
                    sx={(theme) => ({textAlign: 'left'})}
                >
                    BDA
                </Title>
                <Box style={{display: 'flex', justifyContent: 'flex-end'}}>

                    <Button
                        component={Link}
                        to="/dashboard/bda/new"
                        variant="subtle"
                        radius="md"
                        size="lg"
                        compact
                        sx={{
                            //  display: 'flex', justifyContent: 'flex-end',
                            ...(location.pathname !== "/" && {color: "#000"})
                        }}
                    >
                        Create New BDA
                    </Button>
                    {/*</Group>*/}

                    <Button
                        variant="subtle"
                        radius="md"
                        onClick={handleDownload}
                        size="lg"
                        compact
                        sx={{color: "#000"}}
                    >
                        <FaDownload/>
                    </Button>
                </Box>

                <BdaView/>
            </Container>

        </>
    );
};

export default BdaViewDashboard;
