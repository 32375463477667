export const initialValues = {
    P_Supp_Name: "",
    P_Supp_Address1: "",
    P_Supp_Address2: "",
    P_Tel1: "",
    P_Tel2: "",
    P_User_Code: "",
    P_Pwd: "",
    P_Area_Code: "", P_City: "", P_Country: "",
    P_Email_Id: "",
    P_Spons_Name: "",
    P_Branch_Name: "", P_Bank_Iban: "", P_Swift_Code: "",
    P_Currency: "", P_Ac_No: "", P_Bank_Code: "", P_Bank_Name: "", P_Inter_Bank: "", P_Bank_Address1: "", P_Bank_Address2: "", P_Bank_Address3: "",
    P_Pay_Mode: "", P_Cr_Days: "",
    P_Cr_Limit: "", P_Cr_Terms: "", P_Oth_Terms: "",
    P_Crno_Exp_Dt: "",
    P_Lead_Days: "", P_Lic_No: "",
    P_Licno_Exp_Dt: "", P_Supp_Type: "",
    contacts: [
        {
            P_Representative: "", P_Rep_Desig: "", P_Tel_No: "", P_Mob_No: "", P_Fax_No: "", P_Email_Id: ""
        }
    ],
    P_MTL_NO: "", P_MTL_FILE: null,
    P_CC_NO: "", P_CC_FILE: null,
    P_TC_NO: "", P_TC_FILE: null,
    P_SPID_NO: "", P_SPID_FILE: null,
    P_CR_NO: "", P_CR_FILE: null,
};
