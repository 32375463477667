import { api } from "../api";
export const getBdaDetails = async (P_Supp_Code:any) => {
  const response: any = await api.post(
    "bda/details",
    undefined,
    {
      P_Supp_Code,
      // P_Divn_Code,
    }
  );
  return response.data;
};



