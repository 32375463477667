import { Group, Title } from "@mantine/core";
import ProductsTable from "../components/ProductsTable";

const ActiveProducts = () => {
  return (
    <>
      <Group position="apart" my="xl">
        <Title order={1}>Active products</Title>
      </Group>
      <ProductsTable productStatus="ACT" />
    </>
  );
};

export default ActiveProducts;
