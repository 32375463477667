import { Group, MantineTheme, UnstyledButton } from "@mantine/core";
import { IconType } from "react-icons/lib";

type TProps = {
  theme: MantineTheme;
  handleClick: () => void;
  Icon: IconType;
  label: string;
};

const MenuButton = ({ theme, handleClick, Icon, label }: TProps) => {
  return (
    <UnstyledButton
      sx={{
        display: "block",
        width: "100%",
        padding: theme.spacing.md,
        borderRadius: theme.radius.xl,
        color: theme.white,
        paddingLeft: theme.spacing.sm,

        "&:hover": {
          backgroundColor: theme.colors.ffcBrand[5],
        },
        "&:active": {
          backgroundColor: theme.colors.ffcBrand[6],
        },
      }}
      onClick={handleClick}
    >
      <Group>
        <Icon size={20} color={theme.white} />

        <div style={{ display: "inline", fontSize: 14 }}>{label}</div>
      </Group>
    </UnstyledButton>
  );
};

export default MenuButton;
