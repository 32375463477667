import HappySuppliers from "./HappySuppliers";
import PopularBrands from "./PopularBrands";
import WelcomeSection from "./WelcomeSection";

const LandingPage = () => {
  return (
    <>
      <WelcomeSection />
      <HappySuppliers />
      <PopularBrands />
    </>
  );
};

export default LandingPage;
