import * as Yup from "yup";

// const validUrlRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

export const handleValidation = Yup.object().shape({
    contacts: Yup.array().of(
        Yup.object().shape({
            P_Representative: Yup.string().required("Name is required"),
            //   P_Cont_Qid: Yup.string().required("Qatar ID is required"),
            P_Rep_Desig: Yup.string().required("Role is required"),
            P_Tel_No: Yup.number()
                .integer()
                .typeError("Enter a valid number")
                .required("Contact number is required"),
            P_Mob_No: Yup.number().integer().typeError("Enter a valid number"),
            P_Fax_No: Yup.number().integer().typeError("Enter a valid number"),
            P_Email_Id: Yup.string().email("Invalid email").required("Email is required"),
        })
    ),

    P_Supp_Name: Yup.string().required("Company name is required"),
    P_Supp_Address1: Yup.string().required("Company address is required"),
    P_Supp_Address2: Yup.string().required("Address line 2 is required"),
    P_User_Code: Yup.string().required("Username line 2 is required"),
   // P_Pwd: Yup.string().required("Password is required"),

    P_Pwd: Yup.string().required('Password is required'),
    P_PwdConfirmation: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),


    P_City: Yup.string().required("City line 2 is required"),
    P_Country: Yup.string().required("Country is required"),
    P_Tel1: Yup.number().integer().typeError("Enter a valid number").required("Contact number is required"),
    P_Tel2: Yup.number().integer().typeError("Enter a valid number"),
    P_Email_Id: Yup.string().email("Invalid email").required("Email is required"),
    P_Spons_Name: Yup.string().required("Sponsor Name is required"),
    P_Pay_Mode: Yup.string().required(" Pay Mode Name is required"),
    P_Crno_Exp_Dt: Yup.date().required(" Crno Exp_Dt date is required"),
    P_Licno_Exp_Dt: Yup.date().required("Licno Exp Dt date is required"),
    P_Cr_Days: Yup.number().integer().typeError("Enter a valid number").required("Cr Days is required"),
    P_Cr_Limit: Yup.number().integer().typeError("Enter a valid number").required("Cr Limit is required"),
    P_Lead_Days: Yup.number().integer().typeError("Enter a valid number").required("Lead_Days is required"),
    P_Ac_No: Yup.number().integer().typeError("Enter a valid number").required("Ac No is required"),
    P_Area_Code: Yup.string().required("Area Code is required"),
    P_Branch_Name: Yup.string().required("Branch Name is required"),
    P_Bank_Iban: Yup.string().required("Bank Iban is required"),
    P_Swift_Code: Yup.string().required("Swift Code is required"),
    P_Currency: Yup.string().required("Currency is required"),
    P_Bank_Code: Yup.string().required("Bank Code is required"),
    P_Inter_Bank: Yup.string().required("Inter_Bank is required"),
    P_Bank_Address1: Yup.string().required("Bank Address1 is required"),
    P_Bank_Address2: Yup.string().required("Bank_Address2 is required"),
    P_Bank_Address3: Yup.string().required("Bank_Address3 is required"),
    P_Bank_Name: Yup.string().required("Bank Name is required"),
    P_Lic_No: Yup.string().required(" Lic_No is required"),
    P_Cr_Terms: Yup.string().required("Cr_Terms is required"),
    P_Oth_Terms: Yup.string().required("Oth Terms is required"),
    P_Supp_Type: Yup.string().required("Supplier Type is required"),
    P_CR_FILE: Yup.mixed()
        .required("CR file required")
        .test("fileFormat", "only pdf is allowed", (value) =>
            value ? ["application/pdf"].includes(value.type) : true
        ),
    P_CC_FILE: Yup.mixed().test("fileFormat", "only pdf is allowed", (value) =>
        value ? ["application/pdf"].includes(value.type) : true
    ),
    P_TC_FILE: Yup.mixed().test("fileFormat", "only pdf is allowed", (value) =>
        value ? ["application/pdf"].includes(value.type) : true
    ),
    P_MTL_FILE: Yup.mixed().test("fileFormat", "only pdf is allowed", (value) =>
        value ? ["application/pdf"].includes(value.type) : true
    ),

    P_SPID_FILE: Yup.mixed().required("CR file required")
        .test("fileFormat", "only pdf is allowed", (value) =>
            value ? ["application/pdf"].includes(value.type) : true
        ),


    P_CC_NO: Yup.string().when("P_CC_FILE", {
        is: (val: any) => val !== null,
        then: Yup.string().required("Computer Card number is required"),
    }),
    P_TC_NO: Yup.string().when("P_TC_FILE", {
        is: (val: any) => val !== null,
        then: Yup.string().required("Tax Card number is required"),
    }),
    P_MTL_NO: Yup.string().when("P_MTL_FILE", {
        is: (val: any) => val !== null,
        then: Yup.string().required("Municipal Trade License number is required"),
    }),

    P_CR_NO: Yup.string().required("CR number is required"),
    P_SPID_NO: Yup.string().required("Sponsor Id is required"),
});
