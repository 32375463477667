import {BrowserRouter, Routes, Route} from "react-router-dom";
import {MantineProvider} from "@mantine/core";
import {NotificationsProvider} from "@mantine/notifications";
import {customMantineTheme} from "./themes";
import Login from "./pages/Login";
import LandingLayout from "./layout/landing";
import RegisterLayout from "./layout/register";
import LandingPage from "./pages/Landing";
import PortalRoutes from "./routes";
import Register from "./pages/Register";
import {ModalsProvider} from "@mantine/modals";
import ScrollToTop from "./components/ScrollToTop";
import {PrivateRoute} from "./routes/privateRoute";

const App = () => {
    return (
        <BrowserRouter>
            <MantineProvider
                withGlobalStyles
                withNormalizeCSS
                theme={customMantineTheme}
            >
                <NotificationsProvider position="top-right" zIndex={2077}>
                    <ModalsProvider
                        modalProps={{
                            withCloseButton: false,
                            closeOnEscape: false,
                            closeOnClickOutside: false,
                        }}
                    >
                        <ScrollToTop/>
                        <Routes>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/" element={<LandingLayout/>}>
                                <Route index element={<LandingPage/>}/>

                            </Route>

                            <Route path="/register" element={<RegisterLayout/>}>
                                <Route index element={<Register/>}/>
                            </Route>

                            {/*<Route path="/" element={<BdaLayout/>}>*/}
                                {/*<Route path="/bda" element={<Bda/>}/>*/}
                                {/*<Route path="/bda-review" element={<BdaReview/>}/>*/}
                            {/*</Route>*/}


                            <Route element={<PrivateRoute fallbackTo="/login"/>}>
                                <Route path="*" element={<PortalRoutes/>}/>
                            </Route>
                        </Routes>


                    </ModalsProvider>
                </NotificationsProvider>
            </MantineProvider>
        </BrowserRouter>
    );
};

export default App;
