import { Badge, Divider, Stack, Text, useMantineTheme } from "@mantine/core";
import { Dropzone, DropzoneProps, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { RiCloseFill, RiUploadCloud2Fill } from "react-icons/ri";

const FilesDropZone = (props: Partial<DropzoneProps>) => {
  const theme = useMantineTheme();
  return (
    <Dropzone
      onDrop={(files) => console.log(files[0])}
      onReject={(files) => console.log(files[0].errors[0].message)}
      // maxSize={3 * 1024 ** 2}
      accept={IMAGE_MIME_TYPE}
      {...props}
    >
      <Stack
        align="center"
        spacing="sm"
        style={{ minHeight: 220, pointerEvents: "none" }}
      >
        <Dropzone.Accept>
          <RiUploadCloud2Fill
            size={100}
            color={theme.colors[theme.primaryColor][6]}
          />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <RiCloseFill size={100} color={theme.colors.red[6]} />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <RiUploadCloud2Fill
            size={100}
            color={theme.colors[theme.primaryColor][6]}
          />
        </Dropzone.Idle>
        <Text size="xl" inline>
          Drag and drop
        </Text>
        <Divider sx={{ width: 100 }} label="or" labelPosition="center" />
        <Badge size="lg" radius="sm" variant="filled">
          Browse file
        </Badge>
      </Stack>
    </Dropzone>
  );
};

export default FilesDropZone;
