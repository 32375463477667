import {FormikProps} from "formik";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {TextField} from "@mui/material";
const DatePickerField = ({
                             id,
                             formik,
                             ...props
                         }: {
    id: string;
    formik: FormikProps<any>;
    [props: string]: any;
}) => {
    // const handleDateChange = (value: Date) => formik.setFieldValue(id, value);
    return (
        <ThemeProvider theme={createTheme({})}>
            <TextField
                type="date"
                // required
                fullWidth
                id={id}
               defaultValue=""
                variant="standard"
              //  onBlur={formik.handleBlur}
                // sx={{mt: 2, mb: 2}}
                onChange={formik.handleChange}
                autoFocus
                helperText={!!formik.touched[id] && formik.errors[id]}
                error={!!formik.touched[id] && !!formik.errors[id]}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    //max: new Date().toISOString().substring(0, 10)
                }}
                {...props}
            />
        </ThemeProvider>


    );
};

export default DatePickerField;
