import {
  Avatar,
  Divider,
  Grid,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { HiBriefcase, HiMail, HiPhone } from "react-icons/hi";
import useAuthStore from "../../store/useAuthStore";

// {
//       "SUPP_CODE": "RCB136",
//       "SUPP_NAME": "SUPPLIER2947",
//       "SUPP_ADDRESS1": "SUPPLIER ADDR12947",
//       "SUPP_ADDRESS2": "SUPPLIER ADDR22947",
//       "SUPP_TEL1": "55555",
//       "SUPP_EMAIL": "abc@abc",
//       "SUPP_PAY_MODE": "Credit",
//       "SUPP_CR_DAYS": 15,
//       "SUPP_CR_NO": "64756",
//       "SUPP_BARCODE": "6500011122239"
// }

const CompanyInformation = () => {
  const { user } = useAuthStore();

  const companyData = {
    "Company Name": "---",
    "Company ID": "---",
    "Company Reg.on": "---",
    "Company E-mail": "---",
  };
  const locationData = {
    "Address 1": user?.SUPP_ADDRESS1,
    "Address 2": user?.SUPP_ADDRESS2,
    // "Street Number": "-",
    // "Postal Code": "-",
    // "City/Village": "-",
  };

  return (
    <>
      <Group position="apart" my="xl">
        <Title order={1}>Company Information</Title>
      </Group>
      <Paper p={40} radius="md" withBorder sx={{ width: "100%" }}>
        <Group
          spacing={50}
          sx={(theme) => ({
            width: "100%",
            color: theme.colors[theme.primaryColor][6],
          })}
        >
          <Avatar
            sx={{
              width: 150,
              height: 150,
              borderRadius: "50%",
            }}
            alt="Profile Image"
            src=""
            size="xl"
          >
            {user?.SUPP_NAME?.substring(0, 1)}
          </Avatar>
          <Stack spacing="xs" sx={{ flex: 1 }}>
            <Text
              size="xl"
              weight={600}
              sx={(theme) => ({
                color: theme.colors[theme.primaryColor][6],
              })}
            >
              {user?.SUPP_NAME ?? ""}
            </Text>
            <Group>
              <HiMail size={24} />
              <Text
                size="md"
                sx={(theme) => ({
                  color: theme.colors[theme.primaryColor][6],
                })}
              >
                {user?.SUPP_EMAIL ?? ""}
              </Text>
            </Group>
            <Group>
              <HiPhone size={24} />
              <Text
                size="md"
                sx={(theme) => ({
                  color: theme.colors[theme.primaryColor][6],
                })}
              >
                {user?.SUPP_TEL1 ?? ""}
              </Text>
            </Group>
            <Group>
              <HiBriefcase size={24} />
              <Text
                size="md"
                sx={(theme) => ({
                  color: theme.colors[theme.primaryColor][6],
                })}
              >
                ---{/* {user?. ?? ""} */}
              </Text>
            </Group>
          </Stack>
        </Group>
        <Divider my="xl" />
        <Stack>
          <Title order={5} weight={600}>
            Company Details
          </Title>
          {Object.entries(companyData).map(([key, value]) => (
            <Grid key={key}>
              <Grid.Col xs={12} lg={4} xl={3}>
                <Text
                  size="lg"
                  sx={(theme) => ({
                    color: theme.colors[theme.primaryColor][3],
                  })}
                >
                  {key}
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} lg={8} xl={9}>
                <Text
                  size="lg"
                  sx={(theme) => ({
                    color: theme.colors[theme.primaryColor][6],
                  })}
                >
                  {value}
                </Text>
              </Grid.Col>
            </Grid>
          ))}

          <Title order={5} weight={600} mt="md">
            Location Details
          </Title>
          {Object.entries(locationData).map(([key, value]) => (
            <Grid key={key}>
              <Grid.Col xs={12} lg={4} xl={3}>
                <Text
                  size="lg"
                  sx={(theme) => ({
                    color: theme.colors[theme.primaryColor][3],
                  })}
                >
                  {key}
                </Text>
              </Grid.Col>
              <Grid.Col xs={12} lg={8} xl={9}>
                <Text
                  size="lg"
                  sx={(theme) => ({
                    color: theme.colors[theme.primaryColor][6],
                  })}
                >
                  {value}
                </Text>
              </Grid.Col>
            </Grid>
          ))}
        </Stack>
      </Paper>
    </>
  );
};

export default CompanyInformation;
