import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { POPrintItemDetails } from "../../../types/orders";

type TFooterData = {
  totAmtWords: string;
  totSubAmt: number;
  totDiscount: number;
  localNetAmt: number;
  netAmt: number;
  createdUser: string;
  createdTime: string;
};

const ffcBlue = "#25338e";
const dark = "#292C33";

const styles = StyleSheet.create({
  tableContainer: {
    // fontFamily: "Helvetica",
    fontSize: 8,
    flexDirection: "row",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: dark,
  },
  tableHeadContainer: {
    flexDirection: "row",
    backgroundColor: ffcBlue,
    alignItems: "center",
    minHeight: 30,
    textAlign: "left",
    flexGrow: 1,
    color: "#FEFEFE",
    paddingLeft: 3,
  },
  tableRowContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    minHeight: 24,
  },
  textRowCell: {
    textAlign: "left",
    padding: 3,
    borderColor: dark,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    justifyContent: "center",
  },
  numberRowCell: {
    textAlign: "right",
    padding: 3,
    borderColor: dark,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    justifyContent: "center",
  },
  slNoWidth: {
    width: "5%",
  },
  barcodeWidth: {
    width: "16%",
  },
  productWidth: {
    width: "12%",
  },
  descriptionWidth: {
    width: "29%",
  },
  unitWidth: {
    width: "5%",
  },
  qtyWidth: {
    width: "5%",
  },
  looseWidth: {
    width: "5%",
  },
  unitPriceWidth: {
    width: "5%",
  },
  discWidth: {
    width: "5%",
  },
  discPercentageWidth: {
    width: "5%",
  },
  amountWidth: {
    width: "8%",
  },
  largeColSpan: {
    width: "72%",
    padding: 8,
  },
  mediumColSpan: {
    width: "15%",
    padding: 5,
  },
  smallColSpan: {
    width: "13%",
    padding: 5,
  },
  boldText: {
    // fontFamily: "Helvetica-Bold",
    fontWeight: 700,
  },
  noBorderRight: {
    borderRightWidth: 0,
  },
});

const SectionItemDetailsTable = ({
  columns,
  rowData,
  footerData,
}: {
  footerData: TFooterData;
  rowData: POPrintItemDetails[];
  columns: Record<keyof POPrintItemDetails, string>;
}) => {
  return (
    <>
      <View style={styles.tableContainer}>
        <View style={styles.tableHeadContainer}>
          <Text style={styles.slNoWidth}>{columns.SLNO}</Text>
          <Text style={styles.barcodeWidth}>{columns.POI_PLU_CODE}</Text>
          <Text style={styles.productWidth}>{columns.ITEM_CODE}</Text>
          <Text style={styles.descriptionWidth}>{columns.ITEM_NAME}</Text>
          <Text style={styles.unitWidth}>{columns.POI_UOM_CODE}</Text>
          <Text style={styles.qtyWidth}>{columns.POI_QTY}</Text>
          <Text style={styles.looseWidth}>{columns.POI_LOOSE}</Text>
          <Text style={styles.unitPriceWidth}>{columns.POI_RATE}</Text>
          <Text style={styles.discWidth}>{columns.POI_DISC_AMT}</Text>
          <Text style={styles.discPercentageWidth}>
            {columns.POI_DISC_PERC.split(".").join(". ")}
          </Text>
          <Text style={styles.amountWidth}>{columns.AMOUNT}</Text>
        </View>
        {rowData.map((row) => (
          <View style={styles.tableRowContainer} key={row.SLNO}>
            <View style={[styles.numberRowCell, styles.slNoWidth]}>
              <Text>{row.SLNO}</Text>
            </View>
            <View style={[styles.numberRowCell, styles.barcodeWidth]}>
              <Text>{row.POI_PLU_CODE}</Text>
            </View>
            <View style={[styles.numberRowCell, styles.productWidth]}>
              <Text>{row.ITEM_CODE}</Text>
            </View>
            <View style={[styles.textRowCell, styles.descriptionWidth]}>
              <Text>{row.ITEM_NAME}</Text>
            </View>
            <View style={[styles.textRowCell, styles.unitWidth]}>
              <Text>{row.POI_UOM_CODE}</Text>
            </View>
            <View style={[styles.numberRowCell, styles.qtyWidth]}>
              <Text>{row.POI_QTY}</Text>
            </View>
            <View style={[styles.numberRowCell, styles.looseWidth]}>
              <Text>{row.POI_LOOSE}</Text>
            </View>
            <View style={[styles.numberRowCell, styles.unitPriceWidth]}>
              <Text>{row.POI_RATE}</Text>
            </View>
            <View style={[styles.numberRowCell, styles.discWidth]}>
              <Text>{row.POI_DISC_AMT}</Text>
            </View>
            <View style={[styles.numberRowCell, styles.discPercentageWidth]}>
              <Text>{row.POI_DISC_PERC}</Text>
            </View>
            <View
              style={[
                styles.numberRowCell,
                styles.amountWidth,
                styles.noBorderRight,
              ]}
            >
              <Text>{row.AMOUNT}</Text>
            </View>
          </View>
        ))}
        <View style={styles.tableRowContainer}>
          <View style={[styles.textRowCell, styles.largeColSpan]}>
            <Text>Total Amount in Words : {footerData.totAmtWords}</Text>
          </View>
          <View
            style={[styles.textRowCell, styles.mediumColSpan, styles.boldText]}
          >
            <Text>Sub Total</Text>
          </View>
          <View
            style={[
              styles.numberRowCell,
              styles.smallColSpan,
              styles.boldText,
              styles.noBorderRight,
            ]}
          >
            <Text>{footerData.totSubAmt.toFixed(2)}</Text>
          </View>
        </View>
        <View style={styles.tableRowContainer}>
          <View style={[styles.textRowCell, styles.largeColSpan]}></View>
          <View
            style={[styles.textRowCell, styles.mediumColSpan, styles.boldText]}
          >
            <Text>Total Discount</Text>
          </View>
          <View
            style={[
              styles.numberRowCell,
              styles.smallColSpan,
              styles.boldText,
              styles.noBorderRight,
            ]}
          >
            <Text>{footerData.totDiscount.toFixed(2)}</Text>
          </View>
        </View>
        <View style={styles.tableRowContainer}>
          <View style={[styles.textRowCell, styles.largeColSpan]}></View>
          <View
            style={[styles.textRowCell, styles.mediumColSpan, styles.boldText]}
          >
            <Text>Local Net Amount</Text>
          </View>
          <View
            style={[
              styles.numberRowCell,
              styles.smallColSpan,
              styles.boldText,
              styles.noBorderRight,
            ]}
          >
            <Text>{footerData.localNetAmt.toFixed(2)}</Text>
          </View>
        </View>
        <View style={styles.tableRowContainer}>
          <View style={[styles.textRowCell, styles.largeColSpan]}>
            <Text>
              Prepared by : {footerData.createdUser} on {footerData.createdTime}
            </Text>
          </View>
          <View
            style={[styles.textRowCell, styles.mediumColSpan, styles.boldText]}
          >
            <Text>Net Amount</Text>
          </View>
          <View
            style={[
              styles.numberRowCell,
              styles.smallColSpan,
              styles.boldText,
              styles.noBorderRight,
            ]}
          >
            <Text>{footerData.netAmt.toFixed(2)}</Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default SectionItemDetailsTable;
