import {
  Button,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from "@mantine/core";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";

type TProps = {
  type: "success" | "error";
  title: string;
  message?: string;
  handleClose: () => void;
};

const SimpleCustomAlert = ({ type, title, message, handleClose }: TProps) => {
  const theme = useMantineTheme();

  return (
    <Stack align="center" py="md" px="xl">
      <Group spacing="sm">
        {type === "success" ? (
          <FaRegCheckCircle color={theme.other.success} size={40} />
        ) : (
          <FaRegTimesCircle color={theme.other.danger} size={40} />
        )}
        <Title order={3}>{title}</Title>
        <Text color={theme.colors[theme.primaryColor][6]}>{message}</Text>
      </Group>
      <Button variant="light" onClick={handleClose} mt="md">
        Ok
      </Button>
    </Stack>
  );
};

export default SimpleCustomAlert;
