import { Text, View, StyleSheet } from "@react-pdf/renderer";
import dayjs from "dayjs";
import useAuthStore from "../../../store/useAuthStore";

const ffcBlue = "#25338e";

const styles = StyleSheet.create({
  flexBetweenContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexColumnContainer: {
    flexDirection: "column",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tableHeadContainer: {
    flexDirection: "row",
    backgroundColor: ffcBlue,
    alignItems: "center",
    minHeight: 24,
    textAlign: "left",
    flexGrow: 1,
    color: "#FEFEFE",
    paddingLeft: 8,
    width: "100%",
  },
  tableRowContainer: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 24,
  },
});

const SectionFooter = ({
  createdUser,
  createdTime,
}: {
  createdUser: string;
  createdTime: string;
}) => {
  const authStore = useAuthStore();
  return (
    <>
      {/* Section store timing */}
      <View style={[styles.tableContainer, { marginTop: 10 }]}>
        <View style={styles.tableHeadContainer}>
          <Text>Central Store Timing :</Text>
        </View>
        <View style={[styles.tableRowContainer, { paddingLeft: 8 }]}>
          <View
            style={[
              styles.flexColumnContainer,
              { marginTop: 5, lineHeight: 1.8 },
            ]}
          >
            <Text>8.30 AM to 3.00 PM</Text>
          </View>
        </View>
      </View>

      {/* Section terms and conditions */}
      <View style={[styles.tableContainer, { marginTop: 10 }]}>
        <View style={styles.tableHeadContainer}>
          <Text>Terms and Conditions</Text>
        </View>
        <View style={[styles.tableRowContainer, { paddingLeft: 8 }]}>
          <View
            style={[
              styles.flexColumnContainer,
              { marginTop: 5, lineHeight: 1.8 },
            ]}
          >
            <Text>
              1.Failure to supply on or before this date invalidates PO,
              deliveries with expired PO's will not be accepted
            </Text>
            <Text>2.All item should be supplied with barcodes.</Text>
            <Text>
              3.Only Items approved by Government Authority for sale in
              supermarkets should be supplied.
            </Text>
            <Text>
              4.Goods either damaged/near expiry/slow moving will have to be
              taken back by supplier without any contestation.
            </Text>
            <Text>5.No deliverries on saturday, except Fresh Items</Text>
          </View>
        </View>
      </View>
      <View
        style={[
          styles.flexColumnContainer,
          {
            justifyContent: "center",
            alignItems: "center",
            marginTop: 15,
            lineHeight: 2,
          },
        ]}
      >
        <Text>
          Printed By {authStore.user?.SUPP_NAME} On{" "}
          {dayjs().format("DD-MM-YY hh:mm a")}
        </Text>
        <Text style={{ color: "#09C00F" }}>
          **Computer Generated Copy Signature Not Required**
        </Text>
      </View>
    </>
  );
};

export default SectionFooter;
