import { Page, Document, StyleSheet, Text, Font } from "@react-pdf/renderer";
import SectionApprovalStatus from "./SectionApprovalStatus";
import SectionFooter from "./SectionFooter";
import SectionHeader from "./SectionHeader";
import SectionItemDetailsTable from "./SectionItemDetailsTable";
import DejaVuSans from "../../../assets/fonts/DejaVuSans/DejaVuSans.ttf";
import DejaVuSansBold from "../../../assets/fonts/DejaVuSans/DejaVuSans-Bold.ttf";
import { TPurchaseOrderPrintDetails } from "../../../types/orders";

Font.register({
  family: "DejaVuSans",
  fonts: [{ src: DejaVuSans }, { src: DejaVuSansBold, fontWeight: 700 }],
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "DejaVuSans",
    fontSize: 8,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 15,
    paddingRight: 15,
    lineHeight: 1.2,
    flexDirection: "column",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 9,
    bottom: 25,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "#292C33",
  },
});

const OrderPrintPdf = ({
  printDetails,
}: {
  printDetails: TPurchaseOrderPrintDetails;
}) => {
  const orderHeader = printDetails.P_Header[0];
  const columnDetails = printDetails.P_Dtl_Heading[0];
  const rowData = printDetails.P_Detail;
  const {
    AMOUNT_WORDS,
    SUB_AMOUNT,
    TOT_DISC_AMT,
    NET_AMOUNT,
    LOCAL_NET_AMOUNT,
    POH_CR_TIME,
    POH_CR_USER,
  } = orderHeader;
  const footerData = {
    totAmtWords: AMOUNT_WORDS,
    totSubAmt: SUB_AMOUNT,
    totDiscount: TOT_DISC_AMT,
    localNetAmt: LOCAL_NET_AMOUNT,
    netAmt: NET_AMOUNT,
    createdUser: POH_CR_USER,
    createdTime: POH_CR_TIME,
  };
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <SectionHeader data={orderHeader} />
        <SectionItemDetailsTable
          columns={columnDetails}
          rowData={rowData}
          footerData={footerData}
        />
        <SectionApprovalStatus />
        <SectionFooter createdUser={POH_CR_USER} createdTime={POH_CR_TIME} />
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default OrderPrintPdf;
