import { useEffect, useMemo, useState } from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  Paper,
  Pagination,
  Badge,
  Avatar,
  Title,
  LoadingOverlay,
} from "@mantine/core";
import {
  RiArrowUpDownLine,
  RiArrowUpLine,
  RiArrowDownLine,
  RiImageLine,
} from "react-icons/ri";
import { TPurchaseOrderItemDetail } from "../../types/orders";
import DataSummary, { TSummaryFields } from "../../components/DataSummary";
import { useQuery } from "@tanstack/react-query";
import { getPurchaseOrderItemDetail } from "../../queries/orders";
import { useParams } from "react-router-dom";
import { TThProps } from "../../types/common";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },

  icon: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },
}));

function Th({ children, reversed, sorted, onSort }: TThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? RiArrowUpLine
      : RiArrowDownLine
    : RiArrowUpDownLine;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group spacing="xs">
          <Text weight={500} size="md">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: TPurchaseOrderItemDetail[], search: string) {
  if (search === "") return data;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.keys(data[0]).some((key) =>
      (item[key as keyof TPurchaseOrderItemDetail] + "")
        .toLowerCase()
        .includes(query)
    )
  );
}

function sortData(
  data: TPurchaseOrderItemDetail[],
  payload: {
    sortBy: keyof TPurchaseOrderItemDetail | null;
    reversed: boolean;
    search: string;
  }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[sortBy] + "").localeCompare(a[sortBy] + "", "en", {
          numeric: true,
        });
      }

      return (a[sortBy] + "").localeCompare(b[sortBy] + "", "en", {
        numeric: true,
      });
    }),
    payload.search
  );
}

// // DUMMY DATA
// const statusArray: ["pending", "shipped", "cancel"] = [
//   "pending",
//   "shipped",
//   "cancel",
// ];

// const data: TPurchaseOrderItemDetail[] = [...Array(15)].map((_, idx) => ({
//   created: new Date((new Date() as any) - Math.random() * 1e12).toJSON(),
//   orderDetail: `order ${Math.floor(Math.random() * 100)}`,
//   orderStatus: statusArray[Math.floor(Math.random() * statusArray.length)],
//   image: `https://source.unsplash.com/random/20${idx}x20${idx}?fruit`,
//   product: `product ${Math.floor(Math.random() * 100)}`,
// }));
// //

// const tableHeader = {
//   created: { name: "Created", isSortable: true },
//   orderDetail: { name: "Order Details", isSortable: true },
//   product: { name: "Product(s)", isSortable: true },
//   image: { name: "Image", isSortable: false },
//   orderStatus: { name: "Order Status", isSortable: true },
//   action: { name: "Action", isSortable: true },
// };

const OrderDetailTable = () => {
  const [search] = useState("");
  const [sortedData, setSortedData] = useState<TPurchaseOrderItemDetail[]>([]);
  const [sortBy, setSortBy] = useState<keyof TPurchaseOrderItemDetail | null>(
    null
  );
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const { pohId, division } = useParams();
  // const queryClient = useQueryClient();

  const { isLoading: isLoadingPOItemDetail, data: purchaseOrderItemDetail } =
    useQuery<TPurchaseOrderItemDetail[], Error>(
      ["orders", "purchaseOrderItemDetail", pohId, division],
      () => getPurchaseOrderItemDetail({ pohId, division }),
      {
        onError: (error) => {
          console.log(error);
        },
      }
    );

  useEffect(() => {
    if (purchaseOrderItemDetail) {
      setSortedData(
        sortData(purchaseOrderItemDetail, {
          sortBy,
          reversed: reverseSortDirection,
          search,
        })
      );
    }
  }, [purchaseOrderItemDetail, reverseSortDirection, search, sortBy]);

  const setSorting = (field: keyof TPurchaseOrderItemDetail) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(
      sortData(purchaseOrderItemDetail ?? [], {
        sortBy: field,
        reversed,
        search,
      })
    );
  };

  const totalPages = Math.ceil(
    (purchaseOrderItemDetail?.length ?? 0) / rowsPerPage
  );

  const rows = sortedData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row) => (
      <tr key={`${row.POI_ID}`}>
        <td>
          <Avatar radius="xl" size="xl" src={row.IMAGE_URL}>
            <RiImageLine size={26} />
          </Avatar>
        </td>
        <td style={{ maxWidth: "22rem" }}>
          <Title order={5}>{row.ITEM_NAME}</Title>
          <Text color="dimmed">Item Code : {row.POI_ITEM_CODE}</Text>
          <Text color="dimmed">Qty : {row.POI_QTY}</Text>
        </td>
        <td>
          <Text size="md">{row.POI_UOM_CODE}</Text>
        </td>
        <td>
          <Text size="md">{row.POI_LOOSE}</Text>
        </td>
        <td>
          <Text size="md">{row.POI_FOC_QTY}</Text>
        </td>
        <td>
          <Text size="md">{row.POI_FOC_LOOSE}</Text>
        </td>
        <td>
          <Text size="md">{row.POI_RATE}</Text>
        </td>
        <td>
          <Text size="md">{row.POI_DISC_PERC}</Text>
        </td>
        <td>
          <Text size="md">{row.POI_DISC_AMT}</Text>
        </td>
        <td>
          <Text size="md">{row.NETT_AMT}</Text>
        </td>
        <td>
          {row.PO_ITEM_STATUS === "Pending" ? (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="blue"
              sx={{ width: "6rem" }}
            >
              Pending
            </Badge>
          ) : row.PO_ITEM_STATUS === "Shipped" ? (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="green"
              sx={{ width: "6rem" }}
            >
              Shipped
            </Badge>
          ) : (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="red"
              sx={{ width: "6rem" }}
            >
              Cancel
            </Badge>
          )}
        </td>
      </tr>
    ));

  const statusSummary:
    | Record<TPurchaseOrderItemDetail["PO_ITEM_STATUS"], number>
    | undefined = useMemo(
    () =>
      purchaseOrderItemDetail?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.PO_ITEM_STATUS]: acc[curr.PO_ITEM_STATUS] + 1,
        }),
        {
          Shipped: 0,
          Pending: 0,
          Cancel: 0,
        }
      ),
    [purchaseOrderItemDetail]
  );

  const summaryFields: TSummaryFields[] = [
    { label: "All", badgeText: purchaseOrderItemDetail?.length ?? 0 },
    {
      label: "Shipped",
      badgeText: statusSummary?.Shipped ?? 0,
      color: "green",
    },
    { label: "Pending", badgeText: statusSummary?.Pending ?? 0, color: "blue" },
    { label: "Cancel", badgeText: statusSummary?.Cancel ?? 0, color: "red" },
  ];

  return (
    <>
      <Group position="apart" mb="md">
        <DataSummary summaryFields={summaryFields} />
      </Group>
      <Paper
        withBorder
        radius="md"
        my="sm"
        sx={{ minWidth: 700, position: "relative" }}
      >
        <LoadingOverlay visible={isLoadingPOItemDetail} />
        <ScrollArea>
          <Paper sx={{ height: "38.8rem", width: "100%" }}>
            <Table
              horizontalSpacing="md"
              verticalSpacing="xs"
              striped
              sx={{ tableLayout: "auto" }}
            >
              <thead>
                <tr>
                  <Th sorted={false} reversed={false} onSort={() => {}}>
                    Image
                  </Th>
                  <Th
                    sorted={sortBy === "ITEM_NAME"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("ITEM_NAME")}
                  >
                    Product(s)
                  </Th>
                  <Th
                    sorted={sortBy === "POI_UOM_CODE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("POI_UOM_CODE")}
                  >
                    UOM
                  </Th>
                  <Th
                    sorted={sortBy === "POI_LOOSE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("POI_LOOSE")}
                  >
                    No.of Pieces
                  </Th>
                  <Th
                    sorted={sortBy === "POI_FOC_QTY"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("POI_FOC_QTY")}
                  >
                    FOC Qty
                  </Th>
                  <Th
                    sorted={sortBy === "POI_FOC_LOOSE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("POI_FOC_LOOSE")}
                  >
                    FOC loose
                  </Th>

                  <Th
                    sorted={sortBy === "POI_RATE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("POI_RATE")}
                  >
                    Rate
                  </Th>
                  <Th
                    sorted={sortBy === "POI_DISC_PERC"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("POI_DISC_PERC")}
                  >
                    Discount(%)
                  </Th>
                  <Th
                    sorted={sortBy === "POI_DISC_AMT"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("POI_DISC_AMT")}
                  >
                    Discount Amt
                  </Th>
                  <Th
                    sorted={sortBy === "NETT_AMT"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("NETT_AMT")}
                  >
                    Total
                  </Th>
                  <Th
                    sorted={sortBy === "PO_ITEM_STATUS"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("PO_ITEM_STATUS")}
                  >
                    Status
                  </Th>
                </tr>
              </thead>
              <tbody>
                {rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td colSpan={11}>
                      <Text weight={500} align="center" color="red">
                        Nothing found
                      </Text>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Paper>
        </ScrollArea>
      </Paper>
      <Pagination
        disabled={!purchaseOrderItemDetail?.length}
        position="right"
        page={page + 1}
        onChange={(page: number) => setPage(page - 1)}
        withEdges
        total={totalPages}
      />
    </>
  );
};

export default OrderDetailTable;
