import { Group, Title } from "@mantine/core";
import ProductsTable from "../components/ProductsTable";

const InactiveProducts = () => {
  return (
    <>
      <Group position="apart" my="xl">
        <Title order={1}>Inactive products</Title>
        {/* <ActionButton
          title="Download"
          Icon={RiFileExcel2Fill}
          handleClick={() => {}}
        /> */}
      </Group>
      <ProductsTable productStatus="INACT" />
    </>
  );
};

export default InactiveProducts;
