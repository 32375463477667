import { Formik, Form, FieldArray, ErrorMessage } from "formik";
import { handleValidation } from "./Validation";
import { initialValues } from "./Constant";
import React, { useRef, useState } from "react";
import TextInputField from "../../components/TextField/TextInputField";
import ReCAPTCHA from "react-google-recaptcha";
import { BsUpload } from "react-icons/bs";
import SelectDropdown from "../../components/SelectDropdown";
import DatePickerField from "../../components/DatePickerField";
import {
    Button,
    FileInput,
    Grid,
    Group,
    Title,  Divider, Paper,
    useMantineTheme,
} from "@mantine/core";
// import DocumentInfo from "./DocumentInfo";

type TProps = {

    isBankLoading: boolean;
    bankOptions: any | undefined;
    isCompanyTypeLoading: boolean;
    companyTypeOptions: any | undefined,
    isAccountTypeLoading: boolean;
    accountTypeOptions: any | undefined;
    isCurrencyLoading: boolean;
    currencyOptions: any | undefined;
    handleFormSubmit: (
        values: any,
         captchaToken: string | null | undefined
    ) => Promise<void>;


};

const RegisterForm = ({
    isBankLoading,
    bankOptions, isCompanyTypeLoading, companyTypeOptions,
    isAccountTypeLoading,
    accountTypeOptions,
    isCurrencyLoading,
    currencyOptions, handleFormSubmit
}: TProps) => {
    const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);



    const reCaptchaRef = useRef<ReCAPTCHA>(null);

    const handleCaptchaReset = () => {
        setIsCaptchaVerified(false);
        reCaptchaRef?.current?.reset();
    };


    const removeFromList = (i: any, values: any, setValues: any) => {
        const contacts = [...values.contacts];
        contacts.splice(i, 1);
        setValues({ ...values, contacts });
    };

    const updateForm = (values: any, setValues: any) => {
        // update dynamic form
        const contacts: any = [...values.contacts];
        contacts.push({
            P_Representative: "", P_Rep_Desig: "", P_Tel_No: "", P_Mob_No: "", P_Fax_No: "", P_Email_Id: ""
        });
        setValues({ ...values, contacts });
    };

    const errorMessage:any = {
        color: "#d32f2f",
        position: "absolute",
        fontSize: "12px",
        marginTop: "5px"
    };
    const theme = useMantineTheme();
    return (
        <div className="App">
            <Formik
                validationSchema={handleValidation}
                initialValues={initialValues}
                //     onSubmit={handleSubmit}
                onSubmit={async (values: any) => {

                    const captchaToken = await reCaptchaRef?.current?.getValue();
                    handleFormSubmit(values,captchaToken);
                }}
            >
                {(formik) => (
                    <Form>
                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Title order={3} mt="md"  >
                                    Business Information
                                </Title>
                                <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                    sx={{ width: "50px" }} />
                            </Grid.Col>


                            <Grid.Col xs={12} md={4}>
                                <TextInputField formik={formik} id="P_Supp_Name" label="Company Name" />
                            </Grid.Col>


                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Tel1" label="Telephone1" />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Tel2" label="Telephone2" />
                                    </Grid.Col>
                                </Grid>

                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Email_Id" label="Email" />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Spons_Name" label="Sponsor Name" />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_User_Code" label="Username" />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Pwd" label="Password" />
                                    </Grid.Col>
                                </Grid>

                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_PwdConfirmation" label="Confirm Password" />
                                    </Grid.Col>


                                    <Grid.Col xs={12} md={6}>
                                        <SelectDropdown formik={formik} id="P_Pay_Mode" label="Paymode"
                                            options={[{ 'value': 'C', 'label': 'Cash' }, { 'value': 'R', 'label': 'Credit' }]}
                                        />

                                    </Grid.Col>

                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <DatePickerField
                                            variant="standard"
                                            id="P_Crno_Exp_Dt"
                                            formik={formik}
                                            label="Cr Exp Date"
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Lic_No" label="Lic No" />
                                    </Grid.Col>

                                </Grid>
                            </Grid.Col>


                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <DatePickerField

                                            variant="standard"
                                            id="P_Licno_Exp_Dt"
                                            formik={formik}
                                            label="Licno Exp Dt"
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Supp_Type" label="Supp Type" />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                        </Grid>
                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Title
                                    order={3}
                                    mt="md"
                                >
                                    Address
                                </Title>

                                <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                    sx={{ width: "50px" }} />
                            </Grid.Col>

                            <Grid.Col xs={12} md={6}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            formik={formik}
                                            id="P_Supp_Address1"
                                            label="Company Address"
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            formik={formik}
                                            id="P_Supp_Address2"
                                            label="Address Line 2"
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={6}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={4}>
                                        <TextInputField
                                            formik={formik}
                                            id="P_City"
                                            label="City"
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={4}>
                                        <TextInputField formik={formik} id="P_Area_Code" label="Area" />
                                    </Grid.Col>

                                    <Grid.Col xs={12} md={4}>
                                        <TextInputField formik={formik} id="P_Country" label="Country" />
                                    </Grid.Col>

                                </Grid>
                            </Grid.Col>
                        </Grid>
                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Title
                                    order={3}
                                    mt="md"
                                >
                                    Bank Information
                                </Title>
                                <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                    sx={{ width: "50px" }} />
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Branch_Name" label="Branch" />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <SelectDropdown formik={formik} id="P_Currency" label="Currency"
                                            options={currencyOptions ? currencyOptions : []}
                                            isLoading={isCurrencyLoading} />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <SelectDropdown
                                            formik={formik}
                                            id="P_Bank_Code"
                                            label="Bank Code"
                                            options={bankOptions ? bankOptions : []}
                                        //isLoading={isBankLoading}
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Bank_Name" label="Bank Name" />

                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Ac_No" label="Account No." />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Bank_Iban" label="IBAN No." />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            formik={formik}
                                            id="P_Swift_Code"
                                            label="Swift Code"
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Inter_Bank" label="Inter Bank" />

                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Bank_Address1" label="Bank_Address1" />


                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Bank_Address2" label="Bank_Address2" />


                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    {/*<Grid.Col xs={12} md={6}>*/}
                                    {/*<TextInputField formik={formik} id="P_Bank_Address2" label="Bank_Address2"/>*/}


                                    {/*</Grid.Col>*/}
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Bank_Address3" label="Bank_Address3" />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                        </Grid>



                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <Title order={3} mt="md" >
                                            Contact Information
                                        </Title>
                                        <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                            sx={{ width: "50px" }} />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                        {/* <ActionIcon size="lg" radius="lg" variant="transparent" mt="20px"
                                            onClick={(e) => updateForm(formik.values, formik.setValues)}>
                                            <AiFillPlusCircle size={34}
                                                color={theme.colors[theme.primaryColor][5]} />
                                        </ActionIcon> */}

<Button  color="green"  onClick={(e) => updateForm(formik.values, formik.setValues)}>Add</Button>
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12}>
                                <Grid gutter="sm">
                                    <FieldArray name="contacts">
                                        {() =>
                                            formik.values.contacts.map((item, i) => {
                                                return (
                                                    <Grid.Col xs={12}>
                                                        <Paper withBorder p="xl" radius="lg">
                                                            <Grid gutter="md">
                                                                <Grid.Col xs={12} md={4}>
                                                                    <Grid gutter="md">
                                                                        <Grid.Col xs={12} md={6}>
                                                                            <TextInputField formik={formik}
                                                                                id={`contacts.${i}.P_Representative`}
                                                                                label="Name" />

                                                                            <ErrorMessage
                                                                                name={`contacts.${i}.P_Representative`}
                                                                                render={msg => <div
                                                                                    style={errorMessage}>{msg}</div>}/>
                                                                         
                                                                        </Grid.Col>
                                                                        <Grid.Col xs={12} md={6}>
                                                                            <TextInputField formik={formik}
                                                                                id={`contacts.${i}.P_Rep_Desig`}
                                                                                label="Role" />
                                                                            <ErrorMessage
                                                                                name={`contacts.${i}.P_Rep_Desig`}
                                                                                render={msg => <div
                                                                                    style={errorMessage}>{msg}</div>}/>
                                                                        </Grid.Col>
                                                                    </Grid>
                                                                </Grid.Col>

                                                                <Grid.Col xs={12} md={4}>
                                                                    <Grid gutter="md">
                                                                        <Grid.Col xs={12} md={6}>
                                                                            <TextInputField formik={formik}
                                                                                id={`contacts.${i}.P_Mob_No`}
                                                                                label="Mobile Number" />
                                                                            <ErrorMessage
                                                                                name={`contacts.${i}.P_Mob_No`}
                                                                                render={msg => <div
                                                                                    style={errorMessage}>{msg}</div>}/>
                                                                        </Grid.Col>
                                                                        <Grid.Col xs={12} md={6}>
                                                                            <TextInputField formik={formik}
                                                                                id={`contacts.${i}.P_Tel_No`}
                                                                                label="Telephone Number" />
                                                                            <ErrorMessage
                                                                                name={`contacts.${i}.P_Tel_No`}
                                                                                render={msg => <div
                                                                                    style={errorMessage}>{msg}</div>}/>
                                                                        </Grid.Col>      
                                                                    </Grid>
                                                                </Grid.Col>


                                                                <Grid.Col xs={12} md={4}>
                                                                    <Grid gutter="md">
                                                                        <Grid.Col xs={12} md={6}>
                                                                            <TextInputField formik={formik}
                                                                                id={`contacts.${i}.P_Email_Id`}
                                                                                label="Email" />
                                                                            <ErrorMessage
                                                                                name={`contacts.${i}.P_Email_Id`}
                                                                                render={msg => <div
                                                                                    style={errorMessage}>{msg}</div>}/>
                                                                        </Grid.Col>
                                                                        <Grid.Col xs={12} md={6}>
                                                                            <TextInputField formik={formik}
                                                                                id={`contacts.${i}.P_Fax_No`}
                                                                                label="Fax" />
                                                                            <ErrorMessage
                                                                                name={`contacts.${i}.P_Fax_No`}
                                                                                render={msg => <div
                                                                                    style={errorMessage}>{msg}</div>}/>
                                                                        </Grid.Col>
                                                                    </Grid>
                                                                </Grid.Col>
                                                            </Grid>
                                                            {formik.values.contacts.length > 1 && (
                                                                <Grid.Col xs={12} md={12} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end" }}>
                                                                    <Button color="red"  onClick={() => removeFromList(i, formik.values, formik.setValues)}>Remove</Button>
                                                                </Grid.Col>
                                                            )}

                                                        </Paper>
                                                    </Grid.Col>


                                                );
                                            })
                                        }
                                    </FieldArray>

                                </Grid>
                            </Grid.Col>

                        </Grid>


                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Title
                                    order={2}
                                    mt="md"
                                >
                                    Upload Documents
                                </Title>
                                <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]} sx={{ width: "50px" }} />
                            </Grid.Col>


                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_CR_NO" label="CR Number" />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <FileInput
                                            label="CR"
                                            placeholder="Upload File"
                                            value={formik.values.P_CR_FILE}
                                            onChange={(file) => formik.setFieldValue("P_CR_FILE", file)}
                                            icon={
                                                <BsUpload size={16} color={theme.colors[theme.primaryColor][6]} />
                                            }
                                            error={
                                                !!(formik.touched["P_CR_FILE"] && formik.errors["P_CR_FILE"]) &&
                                                formik.errors["P_CR_FILE"]
                                            }
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>


                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            formik={formik}
                                            id="P_MTL_NO"
                                            label="Municipal Trade License Number"
                                        />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <FileInput
                                            label="Municipal Trade License"
                                            placeholder="Upload File"
                                            value={formik.values.P_MTL_FILE}
                                            onChange={(file) => formik.setFieldValue("P_MTL_FILE", file)}
                                            icon={
                                                <BsUpload size={16} color={theme.colors[theme.primaryColor][6]}
                                                />
                                            }
                                            error={!!(formik.touched["P_MTL_FILE"] && formik.errors["P_MTL_FILE"]) &&
                                                formik.errors["P_MTL_FILE"]
                                            }
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_CC_NO" label="Computer Card Number" />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <FileInput
                                            label="Computer Card"
                                            placeholder="Upload File"
                                            value={formik.values.P_CC_FILE}
                                            onChange={(file) => formik.setFieldValue("P_CC_FILE", file)}
                                            icon={
                                                <BsUpload
                                                    size={16}
                                                    color={theme.colors[theme.primaryColor][6]}
                                                />
                                            }
                                            error={
                                                !!(formik.touched["P_CC_FILE"] && formik.errors["P_CC_FILE"]) &&
                                                formik.errors["P_CC_FILE"]
                                            }
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_TC_NO" label="Tax Card Number" />
                                    </Grid.Col>

                                    <Grid.Col xs={12} md={6}>
                                        <FileInput
                                            label="Tax Card"
                                            placeholder="Upload File"
                                            value={formik.values.P_TC_FILE}
                                            onChange={(file) => formik.setFieldValue("P_TC_FILE", file)}
                                            icon={
                                                <BsUpload
                                                    size={16}
                                                    color={theme.colors[theme.primaryColor][6]}
                                                />
                                            }
                                            error={
                                                !!(formik.touched["P_TC_FILE"] && formik.errors["P_TC_FILE"]) &&
                                                formik.errors["P_TC_FILE"]
                                            }
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_SPID_NO" label="Sponsor ID" />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <FileInput

                                            label="Sponsor ID"
                                            // placeholder="Upload File"
                                            value={formik.values.P_SPID_FILE}
                                            onChange={(file) => formik.setFieldValue("P_SPID_FILE", file)}
                                            icon={<BsUpload size={16}
                                            // color={theme.colors[theme.primaryColor][6]}
                                            />
                                            }
                                            error={
                                                !!(formik.touched["P_SPID_FILE"] && formik.errors["P_SPID_FILE"]) &&
                                                formik.errors["P_SPID_FILE"]
                                            }
                                        />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                        </Grid>
                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Title order={3} mt="md">
                                    Basic Terms of the Agreement
                                </Title>
                                <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                    sx={{ width: "50px" }} />
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Cr_Limit" label="Cr Limit" />

                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Cr_Days" label="Cr Days" />
                                    </Grid.Col>
                                </Grid>

                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Lead_Days" label="Lead Days" />
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Cr_Terms" label="Cr Terms" />
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>


                            <Grid.Col xs={12} md={4}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField formik={formik} id="P_Oth_Terms" label="Oth Terms" />
                                    </Grid.Col>

                                </Grid>
                            </Grid.Col>
                        </Grid>

                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Group position="center" my="md">
                                <ReCAPTCHA
                                    ref={reCaptchaRef}
                                      sitekey={process.env.REACT_APP_CAPTCHA_SECRET ?process.env.REACT_APP_CAPTCHA_SECRET:''}
                                  //  sitekey='6LfCN_keAAAAAIA4kGPg7CI1hb3fsFKRGrwOs2gs'
                                   // sitekey='6LfeKkQfAAAAAAkCq3AfHCO7KcA-eFeI3SVvnySO'
                                    onExpired={handleCaptchaReset}
                                    onChange={() => {
                                        setIsCaptchaVerified(true);

                                    }}
                                />
                                </Group>
                            </Grid.Col>


                            <Grid.Col xs={12}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={3}></Grid.Col>

                                    <Grid.Col xs={12} md={6}>
                                        <Group position="apart">
                                            <Button
                                                fullWidth
                                                sx={{ background: '#54B948' }}
                                                // width="1rem"
                                                disabled={!isCaptchaVerified}
                                                type="submit"
                                                mt="5"
                                                mb="30px"
                                            >
                                                Register
                                            </Button>
                                        </Group>
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={3}></Grid.Col>
                                </Grid>
                            </Grid.Col>

                        </Grid>

                    </Form>
                )}
            </Formik>
        </div>
    );
}
export default RegisterForm;
