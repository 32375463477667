import {useState} from "react";
import {
    Container,
    LoadingOverlay,
    Title,
} from "@mantine/core";
import {closeAllModals, openModal} from "@mantine/modals";
import {api} from "../../../api";
import SimpleCustomAlert from "../../../components/SimpleCustomAlert";
import BdaForm from "./Create/BdaForm";
import dayjs from "dayjs";
import useAuthStore from "../../../store/useAuthStore";
import BdaReview from "../BdaReview";

import { useSelector} from "react-redux";

// interface RecentOrdersTableProps {
//     className?: string;
//     cryptoOrders: CryptoOrder[];
// }


const BdaCreate = () => {
    const [isLoading, setIsLoading] = useState(false);
    const authStore = useAuthStore();
    // const dispatch = useDispatch();
    const {bdaState} = useSelector((state: any) => state.bdaState);


    const handleFormSubmit = async (
        values: any,
    ) => {
        try {
            setIsLoading(true);
            let formValues = {...values};

            formValues.year = dayjs(new Date()).format("YY");
            formValues.date_from = dayjs(formValues.date_from).format("DD-MMM-YY");
            formValues.date_to = dayjs(formValues.date_to).format("DD-MMM-YY");
            formValues.supp_code = authStore.user?.SUPP_CODE
            formValues.created_user = authStore.user?.SUPP_NAME

            if (formValues.cost_of_good_perc > 0) {
                formValues.cost_of_good = formValues.cost_of_good_perc + '% of Recommended Retail Price'
            }

            const formData = new FormData();

            formData.append("formValues", JSON.stringify(formValues));
            const response = await api.post("bda/insert", undefined, formData);

            if (response.success) {
                openModal({
                    // title: "Success",
                    centered: true,
                    children: (
                        <SimpleCustomAlert
                            type="success"
                            title="Insert Completed."
                            handleClose={() => closeAllModals()}
                        />
                    ),
                });

               // dispatch(setBdaState("true"));

            } else throw new Error(response.message);
            setIsLoading(false);
        } catch (error: any) {
            openModal({
                // title: "Failed",
                centered: true,
                children: (
                    <SimpleCustomAlert
                        type="error"
                        title={error.message}
                        handleClose={() => closeAllModals()}
                    />
                ),
            });
            console.log(error);
            setIsLoading(false);
        }
    };

    return (
        <>


            <LoadingOverlay
                visible={isLoading}
                loaderProps={{size: "xl", variant: "bars"}}
            />
            {/*{*/}
            {/*if(bdaState)*/}

            {/*<Container size={1000} px={0}>*/}
            {/*<Title*/}
            {/*order={1}*/}
            {/*mt="60px"*/}
            {/*sx={(theme) => ({textAlign: 'center'})}*/}
            {/*>*/}
            {/*BDA Setup*/}
            {/*</Title>*/}

            {/*<BdaForm*/}
            {/*handleFormSubmit={handleFormSubmit}*/}

            {/*/>*/}
            {/*</Container>*/}
            {/*: <BdaReview/>}*/}

            {(() => {
                if (bdaState === "true") {
                    console.log(bdaState)
                    console.log(55555555555)
                    return (
                        <BdaReview/>

                    )
                    // } else if (otherCase) {
                    //     return (
                    //         <div>otherCase</div>
                    //     )
                } else {
                    console.log(bdaState)
                    console.log(6565)
                    return (
                        <Container size={1000} px={0}>
                            <Title
                                order={1}
                                mt="60px"
                                sx={(theme) => ({textAlign: 'center'})}
                            >
                                BDA Setup
                            </Title>

                            <BdaForm  handleFormSubmit={handleFormSubmit}/>
                        </Container>
                    )
                }
            })()}
        </>
    );
};

export default BdaCreate;
