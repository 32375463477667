import { ActionIcon, Group, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowBack } from "react-icons/md";
// import { BsFileEarmarkPdfFill } from "react-icons/bs";
// import { PDFDownloadLink } from "@react-pdf/renderer";
import ReturnDocItemTable from "./ReturnDocItemTable";

const ReturnDocItemView = () => {
  const navigate = useNavigate();
  // const { docId, division } = useParams();

  // const { data: ReturnDocPrintData } = useQuery<TReturnDocumentItemPrint[], Error>(
  //   ["returns", "docItemPrint", docId, division],
  //   () => getReturnDocItemPrint({ docId, division }),
  //   {
  //     enabled: ![docId, division].includes(undefined),
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   }
  // );

  return (
    <>
      <Group position="apart" my="xl">
        <Group position="left">
          <ActionIcon
            variant="filled"
            size="lg"
            radius="md"
            onClick={() => navigate(-1)}
          >
            <MdOutlineArrowBack size={34} />
          </ActionIcon>
          <Title order={1}>Return document detail</Title>
        </Group>
        {/* {!!ReturnDocPrintData && (
          <PDFDownloadLink
            document={<ReturnDocPrintPdf printDetails={ReturnDocPrintData} />}
            fileName={`Return_document_${docId}_${division}`}
          >
            {({ blob, url, loading, error }) => {
              // console.log({ blob, url, loading, error });
              return (
                <ActionButton
                  title="Download"
                  Icon={BsFileEarmarkPdfFill}
                  handleClick={() => {}}
                  loading={loading}
                />
              );
            }}
          </PDFDownloadLink>
        )} */}
      </Group>
      <ReturnDocItemTable />
    </>
  );
};

export default ReturnDocItemView;
