import DashboardLayout from "../layout/dashboard";
import Orders from "../pages/Orders";
import NewListing from "../pages/Products/NewListing";
import ActiveProducts from "../pages/Products/ActiveProducts";
import InactiveProducts from "../pages/Products/InactiveProducts";
import HomeDashboard from "../pages/HomeDashboard";
import Returns from "../pages/Returns";
import Notifications from "../pages/Notifications";
import CompanyInformation from "../pages/CompanyInformation";
import OrderDetail from "../pages/Orders/OrderDetails";
import ReturnDocItemView from "../pages/Returns/ReturnDocItemView";
import BdaView from "../pages/Bda/Dashboard";
import BdaCreate from "../pages/Bda/Dashboard/create";

const DashboardRoutes = {
    path: "/dashboard",
    element: <DashboardLayout/>,
    children: [
        {
            path: "",
            element: <HomeDashboard/>,
        },
        {
            path: "newListProducts",
            element: <NewListing/>,
        },
        {
            path: "activeProducts",
            element: <ActiveProducts/>,
        },
        {
            path: "inactiveProducts",
            element: <InactiveProducts/>,
        },
        {
            path: "orders",
            element: <Orders/>,
        },
        {
            path: "orders/:division/:pohId/:txnType",
            element: <OrderDetail/>,
        },
        {
            path: "returns",
            element: <Returns/>,
        },
        {
            path: "returns/:division/:docId",
            element: <ReturnDocItemView/>,
        },
        {
            path: "notifications",
            element: <Notifications/>,
        },
        {
            path: "companyDetails",
            element: <CompanyInformation/>,
        },
        {
            path: "bda/view",
            element: <BdaView/>,
        },
        {
            path: "bda/new",
            element: <BdaCreate/>,
        },
    ],
};

export default DashboardRoutes;
