import React from "react";
import {Form, Formik, FieldArray, ErrorMessage} from "formik";
import {
    Button,
    Grid,
    Group,
    Title, Divider, Text, Paper,
    useMantineTheme,
} from "@mantine/core";
import TextInputField from "../../components/TextField/TextInputField";
// import {CAPCHA_SITE_KEY} from "../../config";
// import {BsUpload} from "react-icons/bs";
// import {AiFillPlusCircle} from "react-icons/ai";
// import {generateRandomString} from "../../helper/common";
// import SelectDropdown from "../../components/SelectDropdown";
import {TRegisterForm} from "../../types/register";
import RadioInput from "../../components/RadioInput";
import DatePickerField from "../../components/DatePickerField";
// import TextAreaField from "../../components/TextField/TextAreaField";
import {handleValidation} from "./Validation";
// import {documentInfoValidation} from "../form";
// import dayjs from "dayjs";
// import {api} from "../../api";
// import useAuthStore from "../../store/useAuthStore";

type TProps = {
    bdaDetails: any,
    bdaTermsData: any,
    bdaRebateData: any,
    bdaRebateSlabData: any,
    handleFormSubmit: (
        values: TRegisterForm,
    ) => Promise<void>;
};

const BdaForm = ({bdaDetails, bdaTermsData, bdaRebateData, bdaRebateSlabData, handleFormSubmit}: TProps) => {
        const theme = useMantineTheme();
        const updateForm = (values: any, setValues: any) => {
            // update dynamic form
            const progressive_rebate: any[] = [...values.progressive_rebate];
            progressive_rebate.push({
                amount_from: "",
                amount_to: "",
                rebate_perc: "",
                rebate_slab_flag: ""
            });
            setValues({...values, progressive_rebate});
        };

        const errorMessage: any = {
            color: "#d32f2f",
            position: "absolute",
            fontSize: "12px",
            // marginTop: "5px"
        };
        let usersByLikes = []
        if (bdaRebateSlabData.length) {
            usersByLikes = bdaRebateSlabData.map((item: any) => {
                const container: any = {};
                container.rebate_id = item.SBDARS_ID;
                container.amount_from = item.SBDARS_AMT_FM;
                container.amount_to = item.SBDARS_AMT_TO;
                container.rebate_perc = item.SBDARS_REBATE_PERC;
                container.rebate_slab_flag = "";
                return container;
            })
        } else {
            usersByLikes = [{
                rebate_id: "",
                amount_from: "",
                amount_to: "",
                rebate_perc: "",
                rebate_slab_flag: ""
            }]
        }
        const deleteRebate: any[] = [];

        const removeFromList = (i: any, values: any, setValues: any) => {

            //     console.log(i)
            const progressive_rebate: any = [...values.progressive_rebate];

            deleteRebate.push({
                rebate_id: progressive_rebate[i].rebate_id,
                // amount_from:progressive_rebate[i].amount_from,
                // amount_to :progressive_rebate[i].amount_to,
                // rebate_perc :progressive_rebate[ i].rebate_perc,
                rebate_slab_flag: "D"
            })

            console.log(deleteRebate)

            //   progressive_rebate[i].rebate_slab_flag = 'D'
            progressive_rebate.splice(i, 1);
            bdaRebateSlabData.splice(i, 1);
            // console.log(progressive_rebate)

            setValues({...values, progressive_rebate});
        };

        return (
            <Formik
                initialValues={{
                    // date_from: dayjs(bdaDetails.SBDA_DT).format("DD-MMM-YY"),
                    bda_id: bdaDetails.SBDA_ID ? bdaDetails.SBDA_ID : '',
                    term_id: bdaTermsData.SBDAT_ID ? bdaTermsData.SBDAT_ID : '',
                    rebate_id: bdaRebateData.SBDARB_ID ? bdaRebateData.SBDARB_ID : '',
                    date_from: bdaTermsData.SBDAT_DT_FM ? bdaTermsData.SBDAT_DT_FM : '',
                    date_to: bdaTermsData.SBDAT_DT_TO ? bdaTermsData.SBDAT_DT_TO : '',
                    credit_period: bdaDetails.SBDA_CR_DAYS ? bdaDetails.SBDA_CR_DAYS : '',
                    cost_goods: bdaDetails.SBDA_COST_GOODS === 'PL' ? "PL" : bdaDetails.SBDA_COST_GOODS === 'OTH'
                        ? "OTH" : "PRP",
                    domestic_promotion_count: bdaDetails.SBDA_DPROM_COUNT ? bdaDetails.SBDA_DPROM_COUNT : '',
                    exclusive_promotion_count: bdaDetails.SBDA_EPROM_COUNT ? bdaDetails.SBDA_EPROM_COUNT : '',
                    fc_marketing_support: bdaDetails.SBDA_FC_SUPP_PERC ? bdaDetails.SBDA_FC_SUPP_PERC : '',
                    stock_aging_retun_period: bdaDetails.SBDA_AGED_STK_RTN_DAYS ? bdaDetails.SBDA_AGED_STK_RTN_DAYS : '',
                    expiry_return_days: bdaDetails.SBDA_EXP_RTN_DAYS ? bdaDetails.SBDA_EXP_RTN_DAYS : '',
                    expiry_return_cost_perc: bdaDetails.SBDA_EXP_RTN_COST_PERC ? bdaDetails.SBDA_EXP_RTN_COST_PERC : '',
                    progressive_rebate: usersByLikes,
                    delete_progressive_rebate: deleteRebate,
                    rebate: bdaRebateData.SBDARB_REBATE_PERC ? bdaRebateData.SBDARB_REBATE_PERC : '',
                    first_invoice_discount: bdaDetails.SBDA_FIRST_INV_DISC ? bdaDetails.SBDA_FIRST_INV_DISC : '',
                    logistics_support: bdaDetails.SBDA_LOGI_SUPP_PERC ? bdaDetails.SBDA_LOGI_SUPP_PERC : '',
                    merch_pass: bdaDetails.SBDA_MERCH_PASS ? bdaDetails.SBDA_MERCH_PASS : '',
                    uniform_costs: bdaDetails.SBDA_MERCH_UNIFORM_COST ? bdaDetails.SBDA_MERCH_UNIFORM_COST : '',
                    display_support: bdaDetails.SBDA_DISPLAY_SUPPORT ? bdaDetails.SBDA_DISPLAY_SUPPORT : '',
// merchandising and display support
                    pay_mode: "",
                    pay_interval: "",
                    pay_count: "",
                    amount: "",
                    disc_perc: "",
                    created_user: "",

                }}
                validationSchema={handleValidation}
                onSubmit={async (values: any) => {
                    handleFormSubmit(values);
                }}
                enableReinitialize={true}
            >
                {(formik) => (
                    <Form>
                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Title
                                    order={3}
                                    mt="md"
                                >
                                    Annexure 1(Special Terms)
                                </Title>

                                <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                         sx={{width: "50px"}}/>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <div>

                                    <input id="bda_id" name="bda_id" type="hidden"
                                           defaultValue={bdaDetails.SBDA_ID ? bdaDetails.SBDA_ID : ''}
                                           key={bdaDetails.SBDA_ID ? bdaDetails.SBDA_ID : ''}
                                    />
                                    <input id="term_id" name="term_id" type="hidden"
                                           defaultValue={bdaTermsData.SBDAT_ID ? bdaTermsData.SBDAT_ID : ''}
                                           key={bdaTermsData.SBDAT_ID ? bdaTermsData.SBDAT_ID : ''}
                                    />
                                    <input id="rebate_id" name="rebate_id" type="hidden"
                                           defaultValue={bdaRebateData.SBDARB_ID ? bdaRebateData.SBDARB_ID : ''}
                                           key={bdaRebateData.SBDARB_ID ? bdaRebateData.SBDARB_ID : ''}
                                    />

                                </div>
                                {/*<TextInputField formik={formik} id="P_Name" label="Start Date Of the Agreement"/>*/}
                                <DatePickerField
                                    // defaultValue={dayjs(bdaDetails['bda'].SBDA_DT).format("DD-MMM-YY")}
                                    //      defaultValue={bdaDetails['bda'].SBDA_DT ? new Date(bdaDetails['bda'].SBDA_DT).toISOString().split('T')[0] : ''}
                                    variant="standard"
                                    id="date_from"
                                    defaultValue={bdaTermsData.SBDAT_DT_FM ? new Date(bdaTermsData.SBDAT_DT_FM).toISOString().split('T')[0] : ''}
                                    key={bdaTermsData.SBDAT_DT_FM}
                                    formik={formik}
                                    label="Start Date Of the Agreement"
                                />
                            </Grid.Col>


                            <Grid.Col xs={12} md={4}>
                                <DatePickerField
                                    defaultValue={bdaTermsData.SBDAT_DT_TO ? new Date(bdaTermsData.SBDAT_DT_TO).toISOString().split('T')[0] : ''}
                                    key={bdaTermsData.SBDAT_DT_TO}
                                    variant="standard"
                                    id="date_to"
                                    formik={formik}
                                    label="End Date Of the Agreement"
                                />
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                {/*//  {console.log(bdaDetails["SBDA_CR_DAYS"])}*/}
                                <TextInputField
                                    formik={formik}
                                    defaultValue={bdaDetails.SBDA_CR_DAYS}
                                    key={bdaDetails.SBDA_CR_DAYS}
                                    id="credit_period"
                                    label="Credit Period"
                                />
                                <Text size={'xs'}>(Calculated In Days)</Text>
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>

                                <TextInputField
                                    formik={formik}
                                    defaultValue={bdaRebateData.SBDARB_REBATE_PERC}
                                    key={bdaRebateData.SBDARB_REBATE_PERC}
                                    id="rebate"
                                    label="Rebate"
                                />
                                <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <TextInputField
                                    formik={formik}
                                    defaultValue={bdaDetails.SBDA_FIRST_INV_DISC}
                                    key={bdaDetails.SBDA_FIRST_INV_DISC}
                                    id="first_invoice_discount"
                                    label="First Invoice Discount"
                                />
                                <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                            </Grid.Col>
                            <Grid.Col xs={12} md={4}>
                                <TextInputField
                                    formik={formik}
                                    defaultValue={bdaDetails.SBDA_FC_SUPP_PERC}
                                    key={bdaDetails.SBDA_FC_SUPP_PERC}
                                    id="fc_marketing_support"
                                    label="Familyclub Marketing Support"
                                />
                                <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                            </Grid.Col>

                            <Grid.Col xs={12} md={8}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            defaultValue={bdaDetails.SBDA_EXP_RTN_DAYS}
                                            key={bdaDetails.SBDA_EXP_RTN_DAYS}
                                            formik={formik}
                                            id="expiry_return_days"
                                            label="Expiry Return Period Days"
                                        />
                                        <Text size={'xs'}>(Calculated In Days)</Text>
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            defaultValue={bdaDetails.SBDA_EXP_RTN_COST_PERC}
                                            key={bdaDetails.SBDA_EXP_RTN_COST_PERC}
                                            formik={formik}
                                            id="expiry_return_cost_perc"
                                            label="Expiry Return Period %"
                                        />
                                        <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                                    </Grid.Col>

                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={4}>
                                <TextInputField
                                    defaultValue={bdaDetails.SBDA_AGED_STK_RTN_DAYS}
                                    key={bdaDetails.SBDA_AGED_STK_RTN_DAYS}
                                    formik={formik}
                                    id="stock_aging_retun_period"
                                    label="Stock Aging Return Period"
                                />
                                <Text size={'xs'}>(Calculated In Days)</Text>
                            </Grid.Col>


                            <Grid.Col xs={12} md={12}>
                                <Text size={'xs'}>Cost of Goods on Invoice</Text>
                                <RadioInput
                                    id="cost_goods"
                                    formik={formik}
                                    label="Cost Of goods on invoice"
                                    defaultValue={bdaDetails.SBDA_COST_GOODS === 'PL' ? "PL" : bdaDetails.SBDA_COST_GOODS === 'OTH'
                                        ? "OTH" : "PRP"}

                                    key={bdaDetails.SBDA_COST_GOODS === 'PL' ? "PL" : bdaDetails.SBDA_COST_GOODS === 'OTH'
                                        ? "OTH" : "PRP"}

                                    // defaultValue = "percentage"
                                    // key = "percentage"
                                    radioList={[
                                        {
                                            label: (
                                                <>
                                                    <TextInputField
                                                        defaultValue={bdaDetails.SBDA_COST_GOODS === 'PL' ? "" : bdaDetails.SBDA_COST_GOODS === 'OTH'
                                                            ? "" : bdaDetails.SBDA_COST_GOODS?.includes("%") ? bdaDetails.SBDA_COST_GOODS.split('%')[0] : ''}
                                                        key={bdaDetails.SBDA_COST_GOODS === 'PL' ? "" : bdaDetails.SBDA_COST_GOODS === 'OTH'
                                                            ? "" : bdaDetails.SBDA_COST_GOODS?.includes("%") ? bdaDetails.SBDA_COST_GOODS.split('%')[0] : ''}
                                                        formik={formik}
                                                        id="cost_of_good_perc"
                                                        //   placeholder="% of Recommended Retail Price"
                                                    />
                                                    <Text size={'xs'}>(% of Recommended Retail Price)</Text>
                                                </>
                                            ), value: "PRP",
                                        },
                                        {label: "Supplier Price List", value: "PL"},
                                        {label: "Others", value: "OTH"},
                                    ]}
                                />


                            </Grid.Col>

                        </Grid>

                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <Title order={3} mt="md">
                                            Progressive Rebate Slabs
                                        </Title>
                                        <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                                 sx={{width: "50px"}}/>
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}
                                              sx={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                  alignItems: "flex-end"
                                              }}>
                                        {/* <ActionIcon size="lg" radius="lg" variant="transparent" mt="20px"
                                            onClick={(e) => updateForm(formik.values, formik.setValues)}>
                                            <AiFillPlusCircle size={34}
                                                color={theme.colors[theme.primaryColor][5]} />
                                        </ActionIcon> */}

                                        <Button color="green"
                                                onClick={(e) => updateForm(formik.values, formik.setValues)}>Add</Button>

                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12}>
                                <Grid gutter="sm">
                                    <FieldArray name="progressive_rebate">
                                        {() =>
                                            formik.values.progressive_rebate.map((item: any, i: number) => {
                                                // bdaRebateSlabData.map((slab: any, j: number) => {

                                                // console.log(slab.SBDARS_AMT_FM)
                                                // console.log(bdaRebateSlabData[i].SBDARS_AMT_FM)
                                                return (
                                                    <Grid.Col xs={12}>
                                                        <Paper withBorder p="xl" radius="lg">
                                                            <Grid gutter="md">
                                                                <Grid.Col xs={12} md={4}>
                                                                    <Grid gutter="md">
                                                                        <Grid.Col xs={12} md={6}>
                                                                            <Text fw={700}
                                                                                  mt={'25px'}>{`Slab ${i + 1}`}</Text>
                                                                        </Grid.Col>
                                                                        <Grid.Col xs={12} md={6}>

                                                                            <input id={`progressive_rebate.${i}.rebate_id`}
                                                                                   name={`progressive_rebate.${i}.rebate_id`}
                                                                                   type="hidden"
                                                                                   defaultValue={bdaRebateSlabData[i] ? bdaRebateSlabData[i].SBDARS_ID : ''}
                                                                                   key={bdaRebateSlabData[i] ? bdaRebateSlabData[i].SBDARS_ID : ''}
                                                                            />


                                                                            <TextInputField
                                                                                defaultValue={bdaRebateSlabData[i] ? bdaRebateSlabData[i].SBDARS_AMT_FM : ''}
                                                                                key={bdaRebateSlabData[i] ? bdaRebateSlabData[i].SBDARS_AMT_FM : ''}

                                                                                formik={formik}
                                                                                id={`progressive_rebate.${i}.amount_from`}
                                                                                label="Amount"/>
                                                                            <Text size={'xs'}>(QAR)</Text>

                                                                            <ErrorMessage
                                                                                name={`progressive_rebate.${i}.amount_from`}
                                                                                render={msg => <div
                                                                                    style={errorMessage}>{msg}</div>}/>
                                                                        </Grid.Col>
                                                                    </Grid>
                                                                </Grid.Col>

                                                                <Grid.Col xs={12} md={4}>
                                                                    <Grid gutter="md">
                                                                        <Grid.Col xs={12} md={6}>
                                                                            <TextInputField
                                                                                defaultValue={bdaRebateSlabData[i] ? bdaRebateSlabData[i].SBDARS_AMT_TO : ''}
                                                                                key={bdaRebateSlabData[i] ? bdaRebateSlabData[i].SBDARS_AMT_TO : ''}
                                                                                formik={formik}
                                                                                id={`progressive_rebate.${i}.amount_to`}
                                                                                label="Amount To"/>
                                                                            <Text size={'xs'}>(QAR)</Text>
                                                                            <ErrorMessage
                                                                                name={`progressive_rebate.${i}.amount_to`}
                                                                                render={msg => <div
                                                                                    style={errorMessage}>{msg}</div>}/>

                                                                        </Grid.Col>
                                                                        <Grid.Col xs={12} md={6}>
                                                                            <TextInputField
                                                                                defaultValue={bdaRebateSlabData[i] ? bdaRebateSlabData[i].SBDARS_REBATE_PERC : ''}
                                                                                key={bdaRebateSlabData[i] ? bdaRebateSlabData[i].SBDARS_REBATE_PERC : ''}
                                                                                formik={formik}
                                                                                id={`progressive_rebate.${i}.rebate_perc`}
                                                                                label="Additional Rebate"/>
                                                                            <Text size={'xs'}>(%)</Text>
                                                                            <ErrorMessage
                                                                                name={`progressive_rebate.${i}.rebate_perc`}
                                                                                render={msg => <div
                                                                                    style={errorMessage}>{msg}</div>}/>

                                                                        </Grid.Col>

                                                                    </Grid>
                                                                </Grid.Col>
                                                                <Grid.Col xs={12} md={4}>
                                                                    <Grid gutter="md">
                                                                        <Grid.Col xs={12} md={6}>

                                                                        </Grid.Col>
                                                                        <Grid.Col xs={12} md={6}>
                                                                            {formik.values.progressive_rebate.length > 1 && (
                                                                                <Grid.Col xs={12} md={12} sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "flex-end",
                                                                                    alignItems: "flex-end"
                                                                                }}>
                                                                                    <Button color="red"
                                                                                            onClick={() => removeFromList(i, formik.values, formik.setValues)}>Remove</Button>
                                                                                </Grid.Col>
                                                                            )}
                                                                        </Grid.Col>
                                                                    </Grid>
                                                                </Grid.Col>
                                                            </Grid>
                                                        </Paper>
                                                    </Grid.Col>
                                                );
                                                //  })
                                            })
                                        }
                                    </FieldArray>

                                </Grid>
                            </Grid.Col>


                            {/*<Grid.Col xs={12} my="xs">*/}

                            {/*</Grid.Col>*/}
                        </Grid>
                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Title
                                    order={3}
                                    mt="md"
                                >
                                    Merchandising & Display Support
                                </Title>

                                <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                         sx={{width: "50px"}}/>
                            </Grid.Col>

                            <Grid.Col xs={12} md={6}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            defaultValue={bdaDetails.SBDA_MERCH_PASS}
                                            key={bdaDetails.SBDA_MERCH_PASS}
                                            formik={formik}
                                            id="merch_pass"
                                            label="Merchandiser Pass"
                                        />
                                        <Text size={'xs'}>(1 pass valid for 1 year)</Text>
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField

                                            defaultValue={bdaDetails.SBDA_MERCH_UNIFORM_COST}
                                            key={bdaDetails.SBDA_MERCH_UNIFORM_COST}
                                            formik={formik}
                                            id="uniform_costs"
                                            label="Uniform Costs"
                                        />
                                        <Text size={'xs'}>(To be paid directly to the store)</Text>
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                            <Grid.Col xs={12} md={6}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            defaultValue={bdaDetails.SBDA_DISPLAY_SUPPORT}
                                            key={bdaDetails.SBDA_DISPLAY_SUPPORT}
                                            formik={formik}
                                            id="display_support"
                                            label="Central Display Support"
                                        />
                                        <Text size={'xs'}>(QAR)</Text>
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        {/*<TextInputField formik={formik} id="P_City" label="City"/>*/}
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>
                        </Grid>
                        <Grid gutter="md">


                            <Grid.Col xs={12}>
                                <Title
                                    order={3}
                                    mt="md"
                                >
                                    Marketing Support
                                </Title>
                                <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                         sx={{width: "50px"}}/>
                            </Grid.Col>


                            <Grid.Col xs={12} md={6}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            defaultValue={bdaDetails.SBDA_DPROM_COUNT}
                                            key={bdaDetails.SBDA_DPROM_COUNT}
                                            formik={formik}
                                            id="domestic_promotion_count"
                                            label="Number of Domestic Promotions"
                                        />
                                        <Text size={'xs'}>(Per Year)</Text>
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField defaultValue={bdaDetails.SBDA_EPROM_COUNT}
                                                        key={bdaDetails.SBDA_EPROM_COUNT} formik={formik}
                                                        id="exclusive_promotion_count"
                                                        label="Number Of Exclusive Promotions"/>
                                        <Text size={'xs'}>(Per Year)</Text>
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                        </Grid>


                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Title
                                    order={3}
                                    mt="md"
                                >
                                    Logistics Support
                                </Title>
                                <Divider my="2" size={5} color={theme.colors[theme.primaryColor][5]}
                                         sx={{width: "50px"}}/>
                            </Grid.Col>

                            <Grid.Col xs={12} md={6}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={6}>
                                        <TextInputField
                                            defaultValue={bdaDetails.SBDA_LOGI_SUPP_PERC}
                                            key={bdaDetails.SBDA_LOGI_SUPP_PERC}
                                            formik={formik}
                                            id="logistics_support"
                                            label="Logistics & Warehousing Support"
                                        />
                                        <Text size={'xs'}>(Calculated as % of Invoice cost)</Text>
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={6}>
                                        {/*<TextInputField formik={formik} id="P_Ac_no" label="Number Of Exclusive Promotions"/>*/}
                                    </Grid.Col>
                                </Grid>
                            </Grid.Col>

                        </Grid>

                        <Grid gutter="md">
                            <Grid.Col xs={12}>
                                <Grid gutter="md">
                                    <Grid.Col xs={12} md={3}></Grid.Col>

                                    <Grid.Col xs={12} md={6}>
                                        <Group position="apart">
                                            <Button
                                                size={'xl'}
                                                fullWidth
                                                sx={{background: '#54B948'}}
                                                // width="1rem"
                                                type="submit"
                                                mt="70px"
                                                mb="50px"
                                            >
                                                Agree & Continue
                                            </Button>
                                        </Group>
                                    </Grid.Col>
                                    <Grid.Col xs={12} md={3}></Grid.Col>
                                </Grid>
                            </Grid.Col>
                        </Grid>


                    </Form>
                )}
            </Formik>
        );
    }
;

export default BdaForm;
