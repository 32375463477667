import {
  Box,
  DefaultMantineColor,
  Group,
  Paper,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconType } from "react-icons";

type TProps = {
  title: string;
  endTitle?: string;
  subTitle: string;
  Icon: IconType;
  color: DefaultMantineColor | undefined;
};

const OverviewCard = ({
  title,
  endTitle = "",
  subTitle,
  Icon,
  color,
}: TProps) => {
  return (
    <Paper px="xl" py={35} withBorder radius="lg">
      <Group position="apart">
        <Box sx={(theme) => ({ color: theme.colors[theme.primaryColor][6] })}>
          <Title>
            {title + " "}
            <Text component="span" size="sm" weight="normal">
              {endTitle}
            </Text>
          </Title>
          <Text>{subTitle}</Text>
        </Box>
        <ThemeIcon variant="light" radius="xl" size={75} color={color}>
          <Icon size={40} />
        </ThemeIcon>
      </Group>
    </Paper>
  );
};

export default OverviewCard;
