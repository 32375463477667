import { Group, Title } from "@mantine/core";
import ProductsTable from "../components/ProductsTable";

const PrevListedProducts = () => {
  return (
    <>
      <Group position="apart" my="xl">
        <Title order={1}>Previous listed products</Title>
      </Group>
      <ProductsTable productStatus="ALL" />
    </>
  );
};

export default PrevListedProducts;
