import {FormikProps} from "formik";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {TextField} from "@mui/material";
const TextInputField = ({
                            id,
                         //   defaultValue,
                            formik,
                            ...props
                        }: {
    id: string;
 //   defaultValue: any|null;
    formik: FormikProps<any>;
    [props: string]: any;
}) => {

  //  console.log(defaultValue)
    // const handleChange: any = (
    //     event: React.ChangeEvent<HTMLInputElement> | undefined
    // ) => formik.setFieldValue(id, event?.currentTarget.value);
    return (


        <ThemeProvider theme={createTheme({})}>
            <TextField
                // required
                fullWidth
                id={id}
             //   defaultValue={defaultValue}
                variant="standard"
               // onBlur={formik.handleBlur}
               // sx={{mt: 2, mb: 2}}
                onChange={formik.handleChange}
                autoFocus
                helperText={!!formik.touched[id] && formik.errors[id]}
                error={!!formik.touched[id] && !!formik.errors[id]}
                {...props}
            />
        </ThemeProvider>

    );
};

export default TextInputField;
