import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type bdaState = {
  bdaState: string;
};

const initialState: bdaState = {
  bdaState: ""
};


export const bdaStateSlice = createSlice({
  name: "bdaState",
  initialState,
  reducers: {
      setBdaState: (state, action: PayloadAction<string>) => {
      state.bdaState = action.payload;
    }
  }
});

export const {
    setBdaState
} = bdaStateSlice.actions;

export default bdaStateSlice.reducer;