import { Card, Container, Group, Image, Title } from "@mantine/core";

const HappySuppliers = () => {
  return (
    <Container size="xl">
      <Title order={2} size="h1" my={50} align="center">
        Meet some of our happy suppliers
      </Title>
      <Group position="center" spacing="xl" mb={50}>
        {[1, 2, 3, 4, 5, 6, 7, 8].map((i) => (
          <Card key={i} shadow="md" p={40} radius="md" withBorder>
            <Image
              width={200}
              height={200}
              src={`https://source.unsplash.com/random/20${i}x20${i}?fruit`}
              alt="Random unsplash image"
            />
          </Card>
        ))}
      </Group>
    </Container>
  );
};

export default HappySuppliers;
