import { TNavMenu } from ".";
import { FaClipboardList } from "react-icons/fa";

const orders: TNavMenu = {
  id: "orders",
  title: "Orders",
  type: "item",
  url: "orders",
  icon: FaClipboardList,
  children: [],
};

export default orders;
