import {Link,  useNavigate} from "react-router-dom";
import {
    Anchor,
    Box,
    Button,
    Image,
    Space,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import {Form, Formik} from "formik";
import * as Yup from "yup";
import {api} from "../../api";
import useAuthStore from "../../store/useAuthStore";
import TextInputField from "../../components/TextField/TextInputField";
import PasswordInputField from "../../components/TextField/PasswordInputField";
import LoginBg from "../../assets/images/login-bg.jpg";
import FFCLogo from "../../assets/images/logos/Family-logo.png";

type TSubmit = {
    username: string;
    password: string;
    submit: boolean | null;
};

const Login = () => {
    const navigate = useNavigate();
    const authStore = useAuthStore();

    const handleLogin = async (
        values: TSubmit,
        {setErrors, setSubmitting}: any
    ) => {
        try {
            const payload = {
                username: values.username,
                password: values.password,
            };
            const resp = await api.post("login", undefined, payload);

            if (!resp.status) throw new Error(resp.message);


            if (resp.data.bda === 'Y') {
// console.log(444)
                if (resp.data.bda_status === 'Approved') {
                    navigate("/dashboard");
                } else if (resp.data.bda_status === "Pending") {
                    console.log(3333)
                    navigate("/bda-review");
                }

            } else {
                navigate("/bda");
            }


            authStore.loginUser(resp.data.token);

            // if(resp.)


            setSubmitting(false);
        } catch (error: any) {
            setErrors({submit: error.message});
            setSubmitting(false);
        }
    };

    if (authStore.isAuth) {

        if (authStore.user?.BDA_STATUS === 'Y') {
            if (authStore.user?.APPROVAL_STATUS === 'A') {
                navigate("/dashboard");
            } else if (authStore.user?.APPROVAL_STATUS === 'P') {
                navigate("/bda-review");
            }

        } else {
            navigate("/bda");
        }


        //   return <Navigate to="/dashboards" replace={true}/>;
    }

    return (
        <Box sx={{height: "100vh", width: "100%", display: "flex"}}>
            <Box
                sx={(theme) => ({
                    // background: `linear-gradient(
                    //   rgba(26, 36, 102, 0.65),
                    //   rgba(26, 36, 102, 0.65)),
                    //   url(${LoginBg}) no-repeat center`,
                    background: `url(${LoginBg}) `,
                    backgroundSize: "cover",
                    width: "40%",
                    overflow: "hidden",
                    color: theme.other.textLight,
                    [`@media (max-width: ${theme.breakpoints.md}px)`]: {
                        width: 0,
                    },
                })}
            >
                <Stack p={50} align="flex-start">
                    {/*<Paper p="sm" sx={{ overflow: "hidden" }}>*/}
                    <Image width='70%' src={FFCLogo}/>
                    {/*</Paper>*/}
                    <Box mt="10rem" sx={{width: "100%"}}>
                        <Title
                            order={1}
                            sx={(theme) => ({
                                // fontSize: "3rem",
                                color: theme.other.textLight,
                                letterSpacing: "2px",
                            })}
                        >
                            Welcome To
                        </Title>
                        <Title
                            order={1}
                            sx={(theme) => ({
                                // fontSize: "3rem",
                                color: theme.other.textLight,
                                letterSpacing: "2px",
                            })}
                        >
                            Family Food Center
                        </Title>
                        <Space my="3rem"/>
                        <Text>
                            Qatar's oldest and most favorite supermarket | Since 1978 Al Nasr
                            | Al Kheesa | Old Airport Road | Al Rayyan | Family Mart Serving
                            the realm of #Qatar since 1978.
                        </Text>
                    </Box>
                </Stack>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                        submit: null,
                    }}
                    validationSchema={Yup.object().shape({
                        username: Yup.string().max(255).required("Username is required"),
                        password: Yup.string().max(255).required("Password is required"),
                    })}
                    onSubmit={handleLogin}
                >
                    {(formik) => (
                        <Form>
                            <Box
                                sx={{
                                    width: "25rem",
                                }}
                            >
                                <Title mb="2rem" order={1} align="center">
                                    Login
                                </Title>
                                <Title mb="2rem" order={3} align="center">
                                    We need you...
                                </Title>
                                <TextInputField defaultValue=""
                                                withAsterisk
                                                id="username"
                                                formik={formik}
                                                label="Username"
                                                size="md"
                                                radius="xl"
                                />
                                <PasswordInputField
                                    withAsterisk
                                    id="password"
                                    formik={formik}
                                    label="Password"
                                    mt="md"
                                    size="md"
                                    radius="xl"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    mt="xl"
                                    size="md"
                                    radius="xl"
                                    loading={formik.isSubmitting}
                                >
                                    Login
                                </Button>
                                <Text color="red">{formik.errors.submit}</Text>

                                <Text align="center" mt="md">
                                    Don&apos;t have an account?{" "}
                                    <Anchor component={Link} to="/register" weight={700}>
                                        Register
                                    </Anchor>
                                </Text>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};
export default Login;
