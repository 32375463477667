import { useEffect, useState } from "react";
import {
  createStyles,
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  Paper,
  Pagination,
  Select,
  Button,
  Loader,
  LoadingOverlay,
} from "@mantine/core";
import {
  RiArrowUpDownLine,
  RiArrowUpLine,
  RiArrowDownLine,
} from "react-icons/ri";
import dayjs from "dayjs";
import { TThProps } from "../../types/common";
import { useGetAllDivisions } from "../../hooks/useQueryHooks";
import { useQuery } from "@tanstack/react-query";
import { getReturnDocumentList } from "../../queries/returns";
import { TReturnDocument } from "../../types/returns";
import { Link, useSearchParams } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  th: {
    padding: "0 !important",
  },

  control: {
    width: "100%",
    padding: `${theme.spacing.md}px ${theme.spacing.lg}px`,

    "&:hover": {
      backgroundColor: theme.colors.gray[0],
    },
  },

  icon: {
    width: 20,
    height: 20,
    borderRadius: 20,
  },
}));

function Th({ children, reversed, sorted, onSort }: TThProps) {
  const { classes } = useStyles();
  const Icon = sorted
    ? reversed
      ? RiArrowUpLine
      : RiArrowDownLine
    : RiArrowUpDownLine;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group spacing="xs">
          <Text weight={500} size="md">
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon size={14} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: TReturnDocument[], search: string) {
  if (search === "") return data;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    Object.keys(data[0]).some((key) =>
      (item[key as keyof TReturnDocument] + "").toLowerCase().includes(query)
    )
  );
}

function sortData(
  data: TReturnDocument[],
  payload: {
    sortBy: keyof TReturnDocument | null;
    reversed: boolean;
    search: string;
  }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[sortBy] + "").localeCompare(a[sortBy] + "", "en", {
          numeric: true,
        });
      }

      return (a[sortBy] + "").localeCompare(b[sortBy] + "", "en", {
        numeric: true,
      });
    }),
    payload.search
  );
}

// // DUMMY DATA
// const statusArray: ["pending", "collected"] = ["pending", "collected"];

// const data: TReturnDocument[] = [...Array(15)].map((_, idx) => ({
//   created: new Date((new Date() as any) - Math.random() * 1e12).toJSON(),
//   returnDetail: `return ${Math.floor(Math.random() * 100)}`,
//   returnStatus: statusArray[Math.floor(Math.random() * statusArray.length)],
//   image: `https://source.unsplash.com/random/20${idx}x20${idx}?fruit`,
//   product: `product ${Math.floor(Math.random() * 100)}`,
// }));
// //

const ReturnsTable = ({ timePeriod }: { timePeriod: number }) => {
  const [selectedDivision, setSelectedDivision] = useState<string | null>(null);
  const [pODivOptions, setPODivOptions] = useState<
    { label: string; value: string }[]
  >([]);
  // const [search, setSearch] = useState("");
  const [search] = useState("");
  const [sortedData, setSortedData] = useState<TReturnDocument[]>([]);
  const [sortBy, setSortBy] = useState<keyof TReturnDocument | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);

  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading: isLoadingPODivision, data: purchaseOrderDivision } =
    useGetAllDivisions();

  const { isLoading: isLoadingReturnDocument, data: returnDocumentList } =
    useQuery<TReturnDocument[] | null, Error>(
      ["return", "returnDocList", timePeriod, selectedDivision],
      () => getReturnDocumentList({ timePeriod, selectedDivision }),
      {
        onError: (error) => {
          console.log(error);
        },
      }
    );

  useEffect(() => {
    if (purchaseOrderDivision && !selectedDivision) {
      const divisionOptions = purchaseOrderDivision.map((division) => ({
        label: division.DIVN_NAME,
        value: `${division.DIVN_CODE}_${division.DIVN_COMP_CODE}`,
      }));
      setPODivOptions(divisionOptions);

      // To access division value for other components
      const division = searchParams.get("division");
      if (division) {
        setSelectedDivision(division);
      } else {
        setSelectedDivision(divisionOptions[0]?.value);
        setSearchParams(
          { division: divisionOptions[0]?.value },
          { replace: true }
        );
      }
    }
  }, [purchaseOrderDivision, searchParams, selectedDivision, setSearchParams]);

  useEffect(() => {
    if (returnDocumentList) {
      setSortedData(
        sortData(returnDocumentList, {
          sortBy,
          reversed: reverseSortDirection,
          search,
        })
      );
    }
  }, [returnDocumentList, reverseSortDirection, search, sortBy]);

  const setSorting = (field: keyof TReturnDocument) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(
      sortData(returnDocumentList ?? [], { sortBy: field, reversed, search })
    );
  };

  const totalPages = Math.ceil((returnDocumentList?.length ?? 0) / rowsPerPage);

  const rows = sortedData
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    .map((row) => (
      <tr key={`${row.DOC_ID}-${row.DOCMENT_DATE}`}>
        <td>
          <Text size="md">{dayjs(row.DOCMENT_DATE).format("DD-MMM-YYYY")}</Text>
        </td>
        <td>
          <Text size="md">{row.DOC_NO}</Text>
        </td>
        <td>
          <Text size="md">{row.PRETH_DISC_AMT?.toFixed(2)}</Text>
        </td>
        <td>
          <Text size="md">{row.PRETH_DISC_PERC?.toFixed(2)}</Text>
        </td>
        <td>
          <Text size="md">{row.SUB_TOTAL?.toFixed(2)}</Text>
        </td>
        <td>
          <Text size="md">{row.NETT_AMT?.toFixed(2)}</Text>
        </td>
        {/* <td>
          <Title order={5}>{row.returnDetail}</Title>
          <Text color="dimmed">Payment Method : Bank</Text>
          <Text color="dimmed">Total Qty : 220</Text>
          <Text color="dimmed">Remarks : Promotion Items</Text>
        </td>
        <td style={{ maxWidth: "22rem" }}>
          <Title order={5}>
            THAI Choice Young Coconut Juice with Pulp Strips {row.product}
          </Title>
          <Text color="dimmed">Product SKU : 102464</Text>
          <Text color="dimmed">Qty : 10</Text>
        </td>
        <td>
          <Avatar radius="xl" size="xl" src={row.image}>
            <RiImageLine size={26} />
          </Avatar>
        </td>
        <td>
          {row.returnStatus === "pending" ? (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="blue"
              sx={{ width: "7rem" }}
            >
              Pending
            </Badge>
          ) : row.returnStatus === "collected" ? (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="green"
              sx={{ width: "7rem" }}
            >
              Collected
            </Badge>
          ) : (
            <Badge
              size="lg"
              radius="sm"
              variant="filled"
              color="red"
              sx={{ width: "7rem" }}
            >
              {row.returnStatus}
            </Badge>
          )}
        </td> */}
        <td>
          <Button
            variant="outline"
            component={Link}
            to={`/dashboard/returns/${selectedDivision}/${row.DOC_ID}`}
          >
            View
          </Button>
        </td>
      </tr>
    ));

  // const statusSummary: Record<TReturnDocument["returnStatus"], number> | undefined =
  //   useMemo(
  //     () =>
  //       returnDocumentList?.reduce(
  //         (acc, curr) => ({
  //           ...acc,
  //           [curr.returnStatus]: acc[curr.returnStatus] + 1,
  //         }),
  //         {
  //           collected: 0,
  //           pending: 0,
  //         }
  //       ),
  //     [returnDocumentList]
  //   );

  // const summaryFields: TSummaryFields[] = [
  //   { label: "All returns", badgeText: returnDocumentList?.length ?? 0 },
  //   {
  //     label: "Collected",
  //     badgeText: statusSummary?.collected ?? 0,
  //     color: "green",
  //   },
  //   { label: "Pending", badgeText: statusSummary?.pending ?? 0, color: "blue" },
  // ];

  return (
    <>
      {/* <TextInput
        placeholder="Search by any field"
        icon={<RiSearchLine size={14} />}
        value={search}
        onChange={handleSearchChange}
      /> */}
      <Group position="right" mb="md">
        {/* <DataSummary summaryFields={summaryFields} /> */}
        <Select
          zIndex={500}
          sx={{ width: "16rem" }}
          placeholder="Select division"
          value={selectedDivision}
          onChange={setSelectedDivision}
          data={pODivOptions ?? []}
          nothingFound="No divisions found"
          disabled={isLoadingPODivision}
          rightSection={isLoadingPODivision && <Loader size={18} />}
        />
      </Group>
      <Paper
        withBorder
        radius="md"
        my="sm"
        sx={{ minWidth: 700, position: "relative" }}
      >
        <LoadingOverlay visible={isLoadingReturnDocument} />
        <ScrollArea>
          <Paper sx={{ height: "38.8rem", width: "100%" }}>
            <Table
              horizontalSpacing="md"
              verticalSpacing="xs"
              striped
              sx={{ tableLayout: "auto" }}
            >
              <thead>
                <tr>
                  <Th
                    sorted={sortBy === "DOCMENT_DATE"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("DOCMENT_DATE")}
                  >
                    Date
                  </Th>
                  <Th
                    sorted={sortBy === "DOC_NO"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("DOC_NO")}
                  >
                    Doc.No
                  </Th>
                  <Th
                    sorted={sortBy === "PRETH_DISC_AMT"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("PRETH_DISC_AMT")}
                  >
                    Disc. Amt
                  </Th>
                  {/* <Th sorted={false} reversed={false} onSort={() => {}}>
                    Images
                  </Th> */}
                  <Th
                    sorted={sortBy === "PRETH_DISC_PERC"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("PRETH_DISC_PERC")}
                  >
                    Disc. (%)
                  </Th>
                  <Th
                    sorted={sortBy === "SUB_TOTAL"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("SUB_TOTAL")}
                  >
                    Sub Total
                  </Th>
                  <Th
                    sorted={sortBy === "NETT_AMT"}
                    reversed={reverseSortDirection}
                    onSort={() => setSorting("NETT_AMT")}
                  >
                    Net Amt
                  </Th>
                  <Th sorted={false} reversed={false} onSort={() => {}}>
                    Action
                  </Th>
                </tr>
              </thead>
              <tbody>
                {rows.length > 0 ? (
                  rows
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <Text weight={500} align="center" color="red">
                        Nothing found
                      </Text>
                    </td>
                  </tr>
                )}
                {/* {emptyRows > 0 && (
              <tr
                style={{
                  height: 20 * emptyRows,
                }}
              >
                <td colSpan={Object.keys(data[0]).length} />
              </tr>
            )} */}
              </tbody>
            </Table>
          </Paper>
        </ScrollArea>
      </Paper>
      <Pagination
        disabled={!returnDocumentList?.length}
        position="right"
        page={page + 1}
        onChange={(page: number) => setPage(page - 1)}
        withEdges
        total={totalPages}
      />
    </>
  );
};

export default ReturnsTable;
