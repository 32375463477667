import { Divider } from "@mantine/core";
import NewProductsRequest from "./NewProductsRequest";
import PrevListedProducts from "./PrevListedProducts";

const NewListing = () => {
  return (
    <>
      <NewProductsRequest />
      <Divider my="xl" />
      <PrevListedProducts />
    </>
  );
};

export default NewListing;
