import { Text, View, StyleSheet } from "@react-pdf/renderer";

const ffcBlue = "#25338e";
const dark = "#292C33";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    borderWidth: 1,
    borderColor: ffcBlue,
    marginTop: 5,
  },
  tableHeadContainer: {
    flexDirection: "row",
    backgroundColor: ffcBlue,
    alignItems: "center",
    minHeight: 24,
    textAlign: "center",
    flexGrow: 1,
    color: "#FEFEFE",
    paddingLeft: 8,
  },
  tableRowContainer: {
    flexDirection: "row",
    alignItems: "stretch",
    minHeight: 24,
  },
  textRowCell: {
    textAlign: "center",
    padding: 3,
    borderColor: dark,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    justifyContent: "center",
  },
  approvalWidth: {
    width: "25%",
  },
  statusWidth: {
    width: "25%",
  },
  dateWidth: {
    width: "50%",
  },
});

const SectionApprovalStatus = () => {
  return (
    <View style={styles.tableContainer}>
      <View style={styles.tableHeadContainer}>
        <Text style={styles.approvalWidth}>Approval</Text>
        <Text style={styles.statusWidth}>Status</Text>
        <Text style={styles.dateWidth}>Date</Text>
      </View>
      <View style={styles.tableRowContainer}>
        <View style={[styles.approvalWidth, styles.textRowCell]}>
          <Text>SIDHEEK</Text>
        </View>
        <View style={[styles.statusWidth, styles.textRowCell]}>
          <Text>Approved</Text>
        </View>
        <View
          style={[styles.dateWidth, styles.textRowCell, { borderRight: 0 }]}
        >
          <Text>
            01-06-20 10:14:03
            {/* {dayjs().format("DD-MM-YY")} */}
          </Text>
        </View>
      </View>
      <View style={styles.tableRowContainer}>
        <View style={[styles.approvalWidth, styles.textRowCell]}>
          <Text>UMMER</Text>
        </View>
        <View style={[styles.statusWidth, styles.textRowCell]}>
          <Text>Approved</Text>
        </View>
        <View
          style={[styles.dateWidth, styles.textRowCell, { borderRight: 0 }]}
        >
          <Text>
            01-06-20 14:41:19
            {/* {dayjs().format("DD-MM-YY")} */}
          </Text>
        </View>
      </View>
    </View>
  );
};

export default SectionApprovalStatus;
