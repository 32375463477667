import {Box, Grid, Text, Title} from "@mantine/core";
import SupplierImage from "../../assets/images/home-bg.jpg";

const WelcomeSection = () => {
    return (
        <Box
            sx={(theme) => ({
                background: `url(${SupplierImage}) no-repeat fixed center`,
                minHeight: "75vh",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                marginBottom: "2rem",
                color: theme.other.textLight,
            })}
        >
            <Grid
                sx={{
                    width: "100%",
                    padding: "5.6rem",
                    "@media (min-width: 1500px)": {
                        padding: "8.8rem",
                    },
                }}
            >
                <Grid.Col xs={12} md={8} lg={7} xl={5}>
                    <Title
                        order={1}
                        sx={(theme) => ({
                            fontSize: "3rem",
                            color: theme.other.textLight,
                            letterSpacing: "2px",
                        })}
                    >
                        Welcome To
                    </Title>
                    <Title
                        order={1}
                        sx={(theme) => ({
                            fontSize: "3rem",
                            color: theme.other.textLight,
                            letterSpacing: "2px",
                        })}
                    >
                        Family Food Center
                    </Title>
                    <Text mt="xl">
                        Qatar's oldest and most favorite supermarket | Since 1978 Al Nasr |
                        Al Kheesa | Old Airport Road | Al Rayyan | Family Mart Serving the
                        realm of #Qatar since 1978.
                    </Text>
                </Grid.Col>
            </Grid>
        </Box>
    );
};

export default WelcomeSection;
