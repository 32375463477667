import { PasswordInput } from "@mantine/core";
import { FormikProps } from "formik";

const PasswordInputField = ({
  id,
  formik,
  ...props
}: {
  id: string;
  formik: FormikProps<any>;
  [props: string]: any;
}) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement> | undefined
  ) => formik.setFieldValue(id, event?.currentTarget.value);
  return (
    <PasswordInput
      id={id}
      value={formik.values[id]}
      onChange={handleChange}
      onBlur={formik.handleBlur}
      error={!!(formik.touched[id] && formik.errors[id]) && formik.errors[id]}
      {...props}
    />
  );
};

export default PasswordInputField;
