import { api } from "../api";
import { TProductsData } from "../types/products";

export const getProductsData = async ({
  flag,
  selectedDivision,
}: {
  flag: "ALL" | "ACT" | "INACT";
  selectedDivision: string | null;
}) => {
  if (!selectedDivision) return null;
  const [P_Divn, P_Comp_Code] = selectedDivision.split("_");
  const response: TProductsData = await api.post("products", undefined, {
    P_Flag: flag,
    P_Comp_Code,
    P_Divn,
  });
  return response;
};
