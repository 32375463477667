import { Text } from "@mantine/core";
import NavMenuItems from "../../../../layout/dashboard/menu-items";
import NavMenuCollapse from "../NavMenuCollapse";
import NavMenuItem from "../NavMenuItem";

type TProps = { menuType: "main" | "footer" };

const NavMenuList = ({ menuType }: TProps) => {
  const navItems = NavMenuItems(menuType).items.map((item) => {
    switch (item.type) {
      case "collapse":
        return <NavMenuCollapse key={item.id} menuItem={item} level={1} />;
      case "item":
        return <NavMenuItem key={item.id} menuItem={item} level={1} />;
      default:
        return (
          <Text key={item.id} color="red">
            Menu Item Error
          </Text>
        );
    }
  });
  return <>{navItems}</>;
};

export default NavMenuList;
