import * as Yup from "yup";

export const handleValidation = Yup.object().shape({

    progressive_rebate: Yup.array().of(
        Yup.object().shape({
            amount_from: Yup.number().integer().typeError("Enter a valid number").required("Amount From is required"),
            amount_to: Yup.number().integer().typeError("Enter a valid number").required("Amount To is required"),
            rebate_perc: Yup.number().integer().typeError("Enter a valid number").required("Rebate Perc is required"),
        })
    ),
    date_from: Yup.date().required("Date from is required"),
    date_to: Yup.date().required("Date To is required"),
    credit_period: Yup.number().integer().typeError("Enter a valid number").required("Credit Period is required"),
  //  P_Cr_Limit: Yup.number().integer().typeError("Enter a valid number").required("Cr Limit is required"),
    domestic_promotion_count: Yup.number().integer().typeError("Enter a valid number").required("Domestic Promotion count is required"),
    exclusive_promotion_count: Yup.number().integer().typeError("Enter a valid number").required("Exclusive Promotion Count is required"),
    fc_marketing_support: Yup.number().integer().typeError("Enter a valid number").required("Fc Marketing Support is required"),
    stock_aging_retun_period: Yup.number().integer().typeError("Enter a valid number").required("Stock  Aging Return Period is required"),
    expiry_return_days: Yup.number().integer().typeError("Enter a valid number").required("Expiry Return Days is required"),
    expiry_return_cost_perc: Yup.number().integer().typeError("Enter a valid number").required("Expiry Return Cost Perc is required"),
    rebate: Yup.number().integer().typeError("Enter a valid number").required("Rebate is required"),
    first_invoice_discount: Yup.number().integer().typeError("Enter a valid number").required("First Invoice Discount is required"),
    logistics_support: Yup.number().integer().typeError("Enter a valid number").required("Logistics Support is required"),
    merch_pass: Yup.number().integer().typeError("Enter a valid number").required("Merch Pass is required"),
    uniform_costs: Yup.number().integer().typeError("Enter a valid number").required("Uniform Costs is required"),
    display_support: Yup.number().integer().typeError("Enter a valid number").required("Display Support is required"),
    cost_goods: Yup.string().required("Cost Of Goods is required"),

});
