import { ActionIcon, Group, Title } from "@mantine/core";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import ActionButton from "../../components/ActionButton";
import OrderDetailTable from "./OrderDetailsTable";
import OrderPrintPdf from "./OrderPrintPdf";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { useQuery } from "@tanstack/react-query";
import { TPurchaseOrderPrintDetails } from "../../types/orders";
import { getPurchaseOrderPrint } from "../../queries/orders";

const OrderDetail = () => {
  const navigate = useNavigate();
  const { pohId, division, txnType } = useParams();

  const { data: POPrintDetails } = useQuery<TPurchaseOrderPrintDetails, Error>(
    ["orders", "POPrintDetails", pohId, division, txnType],
    () => getPurchaseOrderPrint({ pohId, division, txnType }),
    {
      enabled: ![pohId, division, txnType].includes(undefined),
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return (
    <>
      <Group position="apart" my="xl">
        <Group position="left">
          <ActionIcon
            variant="filled"
            size="lg"
            radius="md"
            onClick={() => navigate(-1)}
          >
            <MdOutlineArrowBack size={34} />
          </ActionIcon>
          <Title order={1}>Order detail</Title>
        </Group>
        {!!POPrintDetails && (
          <PDFDownloadLink
            document={<OrderPrintPdf printDetails={POPrintDetails} />}
            fileName={`${txnType}_${division}_${pohId}`}
          >
            {({ blob, url, loading, error }) => {
              // console.log({ blob, url, loading, error });
              return (
                <ActionButton
                  title="Download"
                  Icon={BsFileEarmarkPdfFill}
                  handleClick={() => {}}
                  loading={loading}
                />
              );
            }}
          </PDFDownloadLink>
        )}
      </Group>
      <OrderDetailTable />
    </>
  );
};

export default OrderDetail;
