import { Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import Logo from "../../../assets/images/logos/ffc-full-logo.jpg";
import dayjs from "dayjs";
import { getBarcodeImageElement } from "../../../helper/common";
import { TPOPrintHeader } from "../../../types/orders";

const ffcBlue = "#25338e";
const dark = "#292C33";

const styles = StyleSheet.create({
  flexBetweenContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexColumnContainer: {
    flexDirection: "column",
  },
  logo: {
    width: 200,
    height: 70,
  },
  barcode: {
    width: 150,
    height: 50,
  },
  boldText: {
    // fontFamily: "Helvetica-Bold",
    fontSize: 9,
    fontWeight: 700,
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
  },
  tableHeadContainer: {
    flexDirection: "row",
    backgroundColor: ffcBlue,
    alignItems: "center",
    minHeight: 24,
    textAlign: "left",
    flexGrow: 1,
    color: "#FEFEFE",
    paddingLeft: 8,
  },
  tableRowContainer: {
    flexDirection: "row",
    alignItems: "center",
    minHeight: 24,
  },
  docNumberCol: {
    width: "60%",
  },
  dateCol: {
    width: "40%",
  },
  docNumberRow: {
    borderRightColor: dark,
    borderRightWidth: 1,
    textAlign: "left",
    paddingLeft: 8,
  },
  dateRow: {
    textAlign: "left",
    paddingLeft: 8,
  },
});

const SectionHeader = ({ data }: { data: TPOPrintHeader }) => {
  const barcode = getBarcodeImageElement({
    barcode: data.POH_BARCODE ?? "NO-BARCODE-FOUND",
    options: {
      height: 50,
      width: 1,
      fontSize: 15,
      textMargin: 1,
    },
  });
  return (
    <>
      <View style={[styles.flexBetweenContainer, { marginBottom: 30 }]}>
        <Image style={styles.logo} src={Logo} />
        <Image style={styles.barcode} src={barcode.src} />
      </View>
      <View style={styles.flexBetweenContainer}>
        {/* Left side - address */}
        <View style={styles.flexColumnContainer}>
          <Text style={[styles.boldText, { marginBottom: 10 }]}>
            {data.COMP_NAME}
          </Text>
          {data.COMP_ADD.split(",").map((address) => (
            <Text key={address} style={styles.boldText}>
              {address.trim()}
            </Text>
          ))}
        </View>
        {/* Right side - docNo and date table */}
        <View style={styles.flexColumnContainer}>
          <Text
            style={[styles.boldText, { marginBottom: 10, color: "#25338e" }]}
          >
            {data.TXN_NAME}
          </Text>
          <View
            style={[
              styles.tableContainer,
              { width: 180, borderWidth: 1, borderColor: ffcBlue },
            ]}
          >
            <View style={styles.tableHeadContainer}>
              <Text style={styles.docNumberCol}>DOC.NUMBER</Text>
              <Text style={styles.dateCol}>DATE</Text>
            </View>
            <View style={styles.tableRowContainer}>
              <Text style={[styles.docNumberRow, styles.docNumberCol]}>
                {data.POH_NO}
              </Text>
              <Text style={[styles.dateRow, styles.dateCol]}>
                {dayjs(data.POH_DT).format("DD-MMM-YY")}
              </Text>
            </View>
          </View>
        </View>
      </View>

      {/* Vendor and ShipTo details table */}
      <View style={[styles.tableContainer, { marginTop: 10 }]}>
        <View style={styles.tableHeadContainer}>
          <Text style={{ width: "60%" }}>VENDOR</Text>
          <Text style={{ width: "40%" }}>SHIP TO</Text>
        </View>
        <View style={[styles.tableRowContainer, { paddingLeft: 8 }]}>
          {/* Vendor details */}
          <View
            style={[
              styles.flexColumnContainer,
              { width: "60%", marginTop: 5, lineHeight: 1.8 },
            ]}
          >
            <Text>Code: {data.SUPP_CODE}</Text>
            <Text>Name: {data.SUPP_NAME}</Text>
            <Text>Address: {data.SUPP_ADDRESS}</Text>
            <Text>Email: {data.SUPP_EMAIL}</Text>
            <Text>Phone Number: {data.SUPP_TEL}</Text>
            <Text>Currency: {data.POH_CURR_CODE}</Text>
          </View>
          {/* ShipTo details */}
          <View
            style={[
              styles.flexColumnContainer,
              { width: "40%", marginTop: 5, lineHeight: 1.8 },
            ]}
          >
            <Text>{data.COMP_NAME}</Text>
            <Text>{data.DEL_LOCATION}</Text>
            <Text>Payment Mode: {data.SUPP_PAY_MODE}</Text>
            <Text>
              Delivery Date: {dayjs(data.POH_DEL_DT).format("DD-MMM-YY")}
            </Text>
            <Text>
              Valid Upto: {dayjs(data.POH_EXP_DT).format("DD-MMM-YY")}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default SectionHeader;
