import { Navigate, Outlet, RouteProps, useLocation } from "react-router-dom";
import useAuthStore from "../store/useAuthStore";

type PrivateRouteProps = RouteProps & {
  fallbackTo: string;
};

export const PrivateRoute = ({ fallbackTo }: PrivateRouteProps) => {
  const searchParams = useLocation().search;
  const authStore = useAuthStore();
  if (!authStore.isAuth) {
    return (
      <Navigate
        to={searchParams ? `${fallbackTo + searchParams}` : fallbackTo}
      />
    );
  }
  return <Outlet />;
};
