import { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  AppShell,
  Navbar,
  MediaQuery,
  Burger,
  useMantineTheme,
  ScrollArea,
  Stack,
  Container,
} from "@mantine/core";
import useAuthStore from "../../store/useAuthStore";
import { useQueryClient } from "@tanstack/react-query";
import { AiOutlineLogout } from "react-icons/ai";
import MenuButton from "../../components/NavigationBar/MenuButton";
import UserHeader from "../../components/NavigationBar/UserHeader";
import BrandLogo from "../../components/NavigationBar/BrandLogo";
import NavMenuList from "../../components/NavigationBar/SideNavbar/NavMenuList";

const DashboardLayout = () => {
  const theme = useMantineTheme();
  const [isNavMenuOpened, setNavMenuOpened] = useState(false);

  const authStore = useAuthStore();
  const queryClient = useQueryClient();

  const handleLogout = () => {
    queryClient.removeQueries();
    authStore.logoutUser();
  };

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      fixed
      navbar={
        <Navbar
          sx={(theme) => ({ backgroundColor: theme.colors.ffcBrand[7] })}
          p="md"
          hiddenBreakpoint="sm"
          hidden={!isNavMenuOpened}
          width={{ sm: 250, md: 300 }}
        >
          <Navbar.Section mt="xs">
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={isNavMenuOpened}
                onClick={() => setNavMenuOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mb="md"
              />
            </MediaQuery>
            <UserHeader
              userName={authStore.user?.SUPP_NAME}
              userCode={authStore.user?.SUPP_CODE}
            />
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <div style={{ marginBottom: "4rem" }} />
            </MediaQuery>
          </Navbar.Section>

          <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs">
            <Stack spacing="xs">
              <NavMenuList menuType="main" />
              <MenuButton
                label="Logout"
                handleClick={handleLogout}
                Icon={AiOutlineLogout}
                theme={theme}
              />
            </Stack>
          </Navbar.Section>

          <Navbar.Section>
            {/* <Divider my="sm" /> */}
            <NavMenuList menuType="footer" />
            <BrandLogo />
          </Navbar.Section>
        </Navbar>
      }
    >
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Burger
          opened={isNavMenuOpened}
          onClick={() => setNavMenuOpened((prev) => !prev)}
          size="sm"
          color={theme.colors.gray[6]}
          mb="md"
        />
      </MediaQuery>
      <Container fluid>
        <Outlet />
      </Container>
    </AppShell>
  );
};

export default DashboardLayout;
