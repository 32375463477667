import { api } from "../api";
import { TPendingPurchaseOrder, TPurchaseOrderDivision } from "../types/orders";

export const getPendingPurchaseOrder = async (
  divisionAndCompCode: string | null
) => {
  if (!divisionAndCompCode) return null;
  const [P_Divn_Code, P_Comp_Code] = divisionAndCompCode.split("_");
  const response: TPendingPurchaseOrder[] = await api.post(
    "orders/pendingPO",
    undefined,
    {
      P_Comp_Code,
      P_Divn_Code,
    }
  );
  return response;
};

export const getPurchaseOrderDivision = async (P_Comp_Code: string | null) => {
  const response: TPurchaseOrderDivision[] = await api.post(
    "orders/PODivision",
    undefined,
    { P_Comp_Code }
  );
  return response;
};

export const getPurchaseOrderItemDetail = async ({
  pohId,
  division,
}: {
  pohId: string | undefined;
  division: string | undefined;
}) => {
  if (!pohId || !division) return null;
  const [, P_Comp_Code] = division.split("_");
  const response = await api.post("orders/POItemDetail", undefined, {
    P_PoH_Id: pohId,
    P_Comp_Code,
  });
  return response;
};

export const getPurchaseOrderPrint = async ({
  pohId,
  division,
  txnType,
}: {
  pohId: string | undefined;
  division: string | undefined;
  txnType: string | undefined;
}) => {
  if (!pohId || !division || !txnType) return null;
  const [, P_Comp_Code] = division.split("_");
  const response = await api.post("orders/POPrint", undefined, {
    P_Comp_Code,
    P_Hid: pohId,
    P_Txn_Type: txnType,
  });
  return response;
};
