import {createTheme, ThemeProvider} from "@mui/material/styles";
import MenuItem from '@mui/material/MenuItem';
import {TextField} from "@mui/material";
type SelectItem = {
    value: string;
    label?: string;
    disabled?: boolean;
    [key: string]: any;
};

type TProps = {
    id: string;
    formik: any;
    label?: string;
    disabled?: boolean;
    isClearable?: boolean;
    isLoading?: boolean;
    options: (string | SelectItem)[] | null;
}

const SelectDropdown = ({
                            id,
                            formik,
                            options,
                            isClearable = true,
                            isLoading = false,
                            ...props
                        }: TProps) => {


    return (

        <ThemeProvider theme={createTheme({})}>
            <TextField
                // required
                fullWidth
                select
                id={id}
                name={id}
                variant="standard"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                autoFocus
                helperText={!!formik.touched[id] && formik.errors[id]}
                error={!!formik.touched[id] && !!formik.errors[id]}
                {...props}
            >
            {options ?options.map((type: any) => {
                return (
                    <MenuItem key={type.value} value={type.value}>
                        {type.label}
                    </MenuItem>
                );
            }) :[]}
        </TextField>

        </ThemeProvider>
    );
};

export default SelectDropdown;
