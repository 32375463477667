// import {Radio} from "@mantine/core";
import {FormikProps} from "formik";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {RadioGroup, FormControlLabel,Radio} from "@mui/material";

const RadioInput = ({
                        id,
                        formik,
                        radioList,
                        ...props
                    }: {
    id: string;
    formik: FormikProps<any>;
    radioList: { label: any; value: string }[];
    [props: string]: any;
}) => {
  //  const handleChange = (value: string) => formik.setFieldValue(id, value);
    return (


        <ThemeProvider theme={createTheme({})}>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label" row
                sx={{mt: 2, mb: 2}}
                name={id}
                value={formik.values[id]}
                onChange={formik.handleChange}
               // error={!!formik.touched[id] && !!formik.errors[id]}
                {...props}
            >
                {radioList.map((radio) => (

                    <FormControlLabel
                        key={radio.value} value={radio.value} label={radio.label}
                         onChange={() => formik.setFieldValue(id, radio.value)}
                                      control={<Radio/>} />

                ))}
            </RadioGroup>
        </ThemeProvider>

    );
};

export default RadioInput;
