import { useMemo, useState } from "react";
import {
  Box,
  DefaultMantineColor,
  Grid,
  Group,
  LoadingOverlay,
  Menu,
  Title,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { IconType } from "react-icons";
import { AiFillBell } from "react-icons/ai";
import { FaClipboardList, FaCreditCard } from "react-icons/fa";
import { GiCardboardBoxClosed, GiTakeMyMoney } from "react-icons/gi";
import { TbTruckReturn } from "react-icons/tb";
import ActionMenu from "../../components/ActionMenu";
import OverviewCard from "../../components/OverviewCard";
import { getSupplierData } from "../../queries/dashboard";
import { TSupplierData } from "../../types/dashboard";
import { useSearchParams } from "react-router-dom";
import { formatNumber } from "../../helper/common";

type TOverviewCardData = {
  title: string;
  endTitle?: string;
  subTitle: string;
  Icon: IconType;
  color: DefaultMantineColor | undefined;
};

const periodOptions = Array.from({ length: 10 }, (_, i) => 6 * (i + 1));

const Overview = () => {
  const [timePeriod, setTimePeriod] = useState(6);
  const [searchParams] = useSearchParams();
  const division = searchParams.get("division");
  const { isLoading, data } = useQuery<TSupplierData | null, Error>(
    ["dashboard", "supplierData", division, timePeriod],
    () => getSupplierData({ division, timePeriod }),
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const OverviewCardData: TOverviewCardData[] = useMemo(() => {
    const {
      PRODUCTS_LISTED = 0,
      ORDERS_PLACED = 0,
      PRODUCTS_RETURN = 0,
      PAYMENTS_COLLECTED = 0,
      CURRENT_BALANCE = 0,
    } = data ?? {};
    return [
      {
        Icon: GiCardboardBoxClosed,
        title: PRODUCTS_LISTED ? formatNumber(PRODUCTS_LISTED) : "0",
        subTitle: "Products listed",
        endTitle: "",
        color: "yellow",
      },

      {
        Icon: FaClipboardList,
        title: ORDERS_PLACED ? formatNumber(ORDERS_PLACED) : "0",
        subTitle: "Orders placed",
        endTitle: "",
        color: "blue",
      },
      {
        Icon: TbTruckReturn,
        title: PRODUCTS_RETURN ? formatNumber(PRODUCTS_RETURN) : "0",
        subTitle: "Products return",
        endTitle: "",
        color: "cyan",
      },
      {
        Icon: FaCreditCard,
        title: PAYMENTS_COLLECTED ? formatNumber(PAYMENTS_COLLECTED) : "0",
        subTitle: "Payments collected",
        endTitle: "QAR",
        color: "pink",
      },
      {
        Icon: AiFillBell,
        title: "0",
        subTitle: "Notifications",
        endTitle: "",
        color: "violet",
      },
      {
        Icon: GiTakeMyMoney,
        title: CURRENT_BALANCE ? formatNumber(CURRENT_BALANCE) : "0",
        subTitle: "Current balance",
        endTitle: "QAR",
        color: "green",
      },
    ];
  }, [data]);

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <LoadingOverlay visible={isLoading} />
      <Group position="apart" my="xl">
        <Title order={1}>Overview</Title>
        <ActionMenu menuLabel={`Last ${timePeriod} months`}>
          {periodOptions.map((period) => (
            <Menu.Item key={period} onClick={() => setTimePeriod(period)}>
              Last {period} months
            </Menu.Item>
          ))}
        </ActionMenu>
      </Group>
      <Grid gutter="xl" mb="md" sx={{ maxWidth: "85rem" }}>
        {OverviewCardData.map((data) => (
          <Grid.Col key={data.subTitle} xs={12} md={6} xl={4}>
            <OverviewCard
              Icon={data.Icon}
              title={data.title}
              subTitle={data.subTitle}
              endTitle={data.endTitle}
              color={data.color}
            />
          </Grid.Col>
        ))}
      </Grid>
    </Box>
  );
};

export default Overview;
