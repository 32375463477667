import create from "zustand";
import { api } from "../api";
import { decodeToken } from "../helper/crypto";
import { TAuthStore, TUser } from "./types";

const useAuthStore = create<TAuthStore>((set) => ({
  isAuth: api.getAuthToken() ? true : false,
  user: api.getAuthToken() ? (decodeToken(api.getAuthToken()) as TUser) : null,
  loginUser(token) {
    api.setAuthToken(token);
    const user = decodeToken(token) as TUser;
    set((state) => ({
      ...state,
      isAuth: true,
      user,
    }));
  },
  logoutUser() {
    api.clearAuth();
    set((state) => ({
      ...state,
      isAuth: false,
      user: null,
    }));
  },
}));

export default useAuthStore;
