import FFCLogo from "../../../assets/images/logos/ffc-short-logo.png";

const BrandLogo = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "10px",
      }}
    >
      <div
        style={{
          backgroundColor: "#fff",
          width: "10rem",
          height: "10rem",
          borderRadius: "50%",
          overflow: "hidden",
          padding: "0.8rem",
        }}
      >
        <img
          style={{
            objectFit: "contain",
            width: "100%",
          }}
          src={FFCLogo}
          alt="FFC"
        />
      </div>
    </div>
  );
};

export default BrandLogo;
