// import BdaLayout from "../layout/Bda";
// import Orders from "../pages/Orders";
// import NewListing from "../pages/Products/NewListing";
// import ActiveProducts from "../pages/Products/ActiveProducts";
// import InactiveProducts from "../pages/Products/InactiveProducts";
// import HomeBda from "../pages/HomeBda";
// import Returns from "../pages/Returns";
// import Notifications from "../pages/Notifications";
// import CompanyInformation from "../pages/CompanyInformation";
// import OrderDetail from "../pages/Orders/OrderDetails";
// import ReturnDocItemView from "../pages/Returns/ReturnDocItemView";
// import BdaView from "../pages/Bda/Bda";
// import BdaCreate from "../pages/Bda/Bda/create";
import BdaLayout from "../layout/Bda";
import Bda from "../pages/Bda";
import BdaReview from "../pages/Bda/BdaReview";

const BdaRoutes = {
    path: "/",
    element: <BdaLayout/>,
    children: [
        {
            path: "bda",
            element: <Bda/>,
        },
        {
            path: "bda-review",
            element: <BdaReview/>,
        },

    ],
};

export default BdaRoutes;
