import { TNavMenu } from ".";
import { FaClipboardList } from "react-icons/fa";

const orders: TNavMenu = {
  id: "bda",
  title: "Bda",
  type: "item",
  url: "bda/view",
  icon: FaClipboardList,
  children: [],
};

export default orders;
