import { Group, Title } from "@mantine/core";
import RecentOrdersTable from "../Orders/RecentOrdersTable";

const RecentOrders = () => {
  return (
    <>
      <Group position="apart" my="xl">
        <Title order={1}>Recent orders</Title>
      </Group>
      <RecentOrdersTable />
    </>
  );
};

export default RecentOrders;
